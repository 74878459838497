import * as React from 'react';
import {
    DatasetSplitByEloCollection,
    DescriptiveStatsTablePromise,
    IconData,
    IconGroup,
    NewDataGroup,
    NewDataSet,
    NewDataTableGroupWithSummary,
    PlayerStatsViewQueryResponse,
    PlayerTableNames,
    SampleSummaryValue,
    TableBytesWrapper,
    TeamRMStatsDataViewSplitByEloCollection,
    TeamRMStatsViewQueryJobInfo,
    TeamRMStatsViewQueryResponse
} from "../Data/ModelGenerated";
import {useEffect, useMemo, useRef, useState} from "react";
import {END_POINTS_URL, IsDev} from "../Data/config";
import {ISidebarItem, ISideBarNavData, TSidebarItemCollection} from "../Components/Sidebar";
import {
    DataItemType,
    DataModelType, DatasetSplitByEloCollectionWithLabel,
    getDataModelType, getDatasetInfo, getGroupedTablesKeys,
    getLabelForDataItemType, getTableGroups,
    isNewDataGroup,
    NewDataGroupWithLabel,
    NewDataGroupWithLabelPagedView,
    NewDataSampleWithSummary,
    NewDataSetWithLabel, TEloBracket, TPatchVersionInfoCollection, TStatsTableNames,
    TTableGroup, TTeamEloBracket
} from "../Data/CheckType";
import {Box, Typography} from "@mui/material";
import {IPaginationRequest, ISortRequest} from "../Components/Table/DataTable";
import {DataProviderResponseMetaData, TableGroupView} from "../Data/Model";
import {
    DatasetSplitByEloCollectionWithLabelBaselineProviderFactory,
    TBaselineTableGroupSampleDelegate
} from "../Data/BaselineProviders";
import {TQueryError, TQueryMessage} from "../Dashboard";
import {getMetaDataGroupItems, InitMetaDataResolverCached} from "../Components/TeamStatsView/CachedMetaDataProvider";
// var md5 = require('./md5');

const sha1 = require('js-sha1');

export type TSearch = { values: undefined | { key: string, label: string }[], set: (values: { key: string, label: string }[] | undefined) => void }
export type TDataSourceContextDataView = { data: any, itemType: DataModelType, metaData?: DataProviderResponseMetaData, datasetKey: string }
export type TTableServersude = {
    select: (props: { table: "civ" | "map_type", key: string } | null) => void, value: {
        table: "civ" | "map_type",

        key: string
    } | null,
    search: TSearch
}

export interface IDataSourceContextStatus {
    status: TQueryError | TQueryMessage
}

export type TQueryStatus = TQueryError | TQueryMessage;

export interface IDataSourceContext {

    viewGroup: string | undefined
    isDataLoading: boolean | TQueryStatus
    // dataset: NewDataSet
    datasetKey: string
    getSelectedDataView: () => TDataSourceContextDataView | Promise<TDataSourceContextDataView>
    path: { value: string[], set: (path: string[]) => void }
    pagination?: IPaginationRequest
    serversideSort?: ISortRequest
    tableServerside?: TTableServersude

    // DatasetSplitByEloCollectionWithLabelBaselineProviderFactory
    getBaselineData?: TBaselineTableGroupSampleDelegate

    getDataViewUpdateKey: () => string

    loadedPatchSummary: { [k: string]: SampleSummaryValue }
    // currentPath: string[]
}

export interface IDataProviderProps {
    dataset: TStatsTableNames | undefined
    selectedPatchVersion: string | null,
    selectedEloBracket: string | null,

    disabledDatasetPicker: { value: boolean, set: (v: boolean) => void },
    disabledPatchVersionPicker: { value: boolean | undefined, set: (v: boolean) => void },
    disabledEloBracketPicker: { value: boolean, set: (v: boolean) => void },

    lazyLoad: boolean;
    children: JSX.Element | JSX.Element[]

    sidebarNavData: { value: ISideBarNavData, set: (v: ISideBarNavData) => void }

    path: { value: string[], set: (p: string[]) => void }
    onLoadedPatchesData: (availablePatchVersions: TPatchVersionInfoCollection) => void
    availablePatchesData: TPatchVersionInfoCollection

    onLoadedEloBracketData: (availableEloBrackets: string[] | null) => void

    setCurrentlyLoadedPatchVersionKey: (key: string) => void
}

export const resolveDataPromise = (async (viewGroup: string, promiseData: DescriptiveStatsTablePromise) => {

    // view_group
    let url = `${END_POINTS_URL}/static_view_resolve/${viewGroup}`

    // let bodyData = new FormData();
    // bodyData.append("json", JSON.stringify(promiseData));

    const promise = fetch(url, {
        method: 'POST',
        headers: {
            'Accept-Encoding': 'gzip, application/json, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(promiseData)
    }).then((response) => {

        let jsData = response.json()
        return jsData;
    }).catch((error) => {
        throw Error(`PR: Failed to fetch:\n url: ${url} \n Error: \n ${error}`)
        return {...error}
    });

    const data = await promise;//Promise.any(promise);

    return data as unknown as TableBytesWrapper
})

const fetchData = (async (tableName: string,
                          datasetName: PlayerTableNames,
                          lazy?: boolean,
                          profile_id?: string,
                          pagination?: { start: number, count: number },
                          sort?: { by: string | string[], asc?: boolean },
                          selectedPatchVersion?: string,
                          selectTable?: { table: "civ" | "map_type", key: string },
                          searchNames?: string[],
                          pathArgs?: string[],
                          isStatusCheck?: boolean
) => {

    let url: string | undefined;

    if (tableName === "Team_RM_Stats") {

        if (pathArgs === undefined || pathArgs.length < 2) {
            throw Error(`Call to static_view/team missing pathArgs: ${pathArgs}`)
        }
        let group: IconGroup = pathArgs[1] as IconGroup// "civilizations" // TODO add support for maps and specific items etc.

        if (group !== "civilizations" && group !== "maps") {
            group = "civilizations"
        }

        url = `${END_POINTS_URL}/static_view/team/${tableName}/${selectedPatchVersion ? selectedPatchVersion : "latest"}/${group}`


        if (pathArgs.length > 2) {
            url += `?item_id=${pathArgs[2]}`
        }
        if (isStatusCheck) {
            if (pathArgs.length > 2) {
                url += '&is_status_check=true'
            } else {
                url += `?is_status_check=true`
            }
        }

    } else if (lazy && profile_id) {
        url = `${END_POINTS_URL}/table_view/${datasetName}/${profile_id}`
    } else if (!lazy) {
        url = `${END_POINTS_URL}/static_view/${tableName}/${selectedPatchVersion ? selectedPatchVersion : "latest"}`
    } else if (searchNames && searchNames.length > 0) {

        // http://localhost:8000/table_view/stats_views__players_RM/search/?q=212721&q=208611&orderBy=n&asc=false
        const params = new URLSearchParams();
        for (let q of searchNames) {
            params.append("q", q)
        }

        if (pagination && sort) {
            params.append('orderBy', typeof sort.by === 'string' ? sort.by : sort.by.join("->"));
            params.append('asc', (!!sort.asc).toString());
        }

        url = `${END_POINTS_URL}/table_view/${datasetName}/search/?${params.toString()}`

    } else if (pagination && sort) {
        // if (sort === undefined) {
        //     url = `${END_POINTS_URL}/table_view/stats_views__players/${pagination.start}/${pagination.count}/?orderBy=n&asc=False`
        // } else {
        let orderBy = typeof sort.by === 'string' ? sort.by : sort.by.join("->")
        let asc = !!sort.asc;
        // alert(`$asc: ${asc}`)
        url = `${END_POINTS_URL}/table_view/${datasetName}/${pagination.start}/${pagination.count}?orderBy=${orderBy}&asc=${(asc)}`
        // }
    }

    if (selectTable) {
        url = `${url}&${selectTable.table}=${selectTable.key}`
    }

    let retValue: any;

    // const cache = await caches.open('dataset-cache');

    if (url) {
        const _url = url;
        // console.error(`\n\n url:\n ${url}\n\n`)

        // const promise = await cache.match(_url).then((resp) => {
        //     if (resp) {
        //         return resp.json()
        //     } else {

        const promise = fetch(_url, {
            // method: 'GET',
            // headers: {
            //     'Accept-Encoding': 'gzip, *',
            //     'Content-Type': 'application/json',
            // },
        }).then((response) => {
            if (response.ok) {

                try {
                    // TODO enabling causes 'Failed to execute 'json' on 'Response': body stream already read' look into
                    // cache.put(_url, response)
                    let r = response.json()
                    return r;
                } catch (ex) {
                    console.error(`Could not parse ${_url} :\n ${JSON.stringify(response)}`)
                    throw Error(`Failed to parse:\n url: ${_url} \n Error: \n ${ex}`)
                }

            } else {
                console.error(`Failed ${_url} :\n ${JSON.stringify(response)}`)
            }
        }).catch((error) => {
            throw Error(`Failed to fetch:\n url: ${_url} \n Error: \n ${error}`)
            return {...error}
        });
        //     }
        // });

        const data = await promise;//Promise.any(promise);


        retValue = data
        // return retData
    }

    // console.error(`${JSON.stringify(retValue)}`)

    if (retValue !== undefined && retValue !== null) {
        // (JSON.stringify(retValue))
        return retValue
    }


    throw Error(`URL: ${url} did not return anything`)

});


const fetchAvailablePatchVersions = (async (tableName: string) => {

    let url: string | undefined;
    url = `${END_POINTS_URL}/static_view_versions/${tableName}`
    let promise = fetch(url, {method: 'GET'}).then((response) => {
        if (response.ok) {
            return response.json()
        } else {
            throw Error(`${response.statusText} (${response.status}) - ${response.type}`)
        }
    });

    const data = await promise;//Promise.any(promise);
    return data

});

export const getLabelForPath = (path: string[]) => {

    let value: string = "";//props.path.join("->");

    if (path.length === 2) {

        if (path[0] === "grouped_tables" && path[1] === "map_type") {
            value = "Maps"
        } else if (path[0] === "grouped_tables" && path[1] === "civ") {
            value = "Civilizations"
        }
    }
    return <Typography> {value} </Typography>
}

const fetchDataStaticDataset = (async (tableName: TStatsTableNames, patch_version: string, lazy?: boolean, pathArgs?: string[], isStatusCheck?: boolean) => {

    let data: any | null = null

    let dsName: PlayerTableNames;

    if (tableName === "RM_Stats") {
        dsName = "stats_views__players_RM"
    } else {
        // else if (tableName === "EmpireWars_Stats"){
        dsName = "stats_views__players_EW"
    }

    let teamStats: boolean = false;

    data = await fetchData(tableName, dsName, lazy, undefined, undefined, undefined,
        patch_version,
        undefined,
        undefined,
        pathArgs,
        isStatusCheck)
    if (data === null)
        return null;

    if (data.hasOwnProperty("error")) {
        return data
    }


    // TODO - converts TeamRMStatsDataViewSplitByEloCollection to DatasetSplitByEloCollectionWithLabel
    // should not probably do this and handle TeamRMStatsDataViewSplitByEloCollection properly, but it's too much work
    // to rewrite everything now.

    let ds: NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel
    const isItemTeamRMStatsViewQuery = data.hasOwnProperty("TeamRMStatsViewQueryResponse")

    if (isItemTeamRMStatsViewQuery) {

        let tempData = data as TeamRMStatsViewQueryResponse;


        if (tempData.table) {
            if (tempData.last_finished_job?.status === -1) {
                throw `Could not parse invalid data. Please try again later.`
            }

            let tempDs = data.table as TeamRMStatsDataViewSplitByEloCollection;

            let eloData: {
                [k: string]: NewDataSetWithLabel;
            } = {};


            for (let key of Object.keys(tempDs.dataset)) {
                let item = tempDs.dataset[key];

                eloData[key] = {
                    date: tempDs.date,
                    grouped_tables: undefined,
                    data_view: item,
                    label: `Team RM Label ${item.elo_bracket}`,
                    name: `Team RM Name ${item.elo_bracket}`,
                    sample_count: 33,
                    summary_stats: {}

                }
            }


            let targetDs: DatasetSplitByEloCollectionWithLabel = {
                dataset: eloData,
                key: tempDs.key,
                label: "Team RM Stats",
                type: "TeamRMStatsDataViewSplitByEloCollection"
            }
            ds = targetDs
        } else if (tempData.scheduled_or_running_job) {
            return tempData.scheduled_or_running_job
        } else if (tempData.last_finished_job) {
            return tempData.last_finished_job
        } else {
            throw `Could not parse invalid TeamRMStatsViewQueryResponse`
        }

    } else {
        ds = data as NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel
    }
    // let ds = data as NewDataSetWithLabel;
    // ds.label = "Civ Stats"


    const parse_dataset = (_ds: NewDataSetWithLabel) => {
        if (_ds.grouped_tables) {
            let groupedTables = _ds.grouped_tables;
            Object.keys(groupedTables).forEach((k) => {
                let label = getLabelForDataItemType(groupedTables[k].name as DataItemType)
                groupedTables[k].label = label ? label : groupedTables[k].name;

                Object.keys(groupedTables[k].samples).forEach(kk => {
                    // @ts-ignore
                    _ds.grouped_tables[k].samples[kk].tableGroups.get = (key: string) => groupedTables[k].samples[kk].tableGroups[key];
                    // ds.grouped_tables[k].tableGroups.get = (key: string) => ds.grouped_tables[k].tableGroups[key];

                })
            })
        }
        return _ds
    }

    if (ds.hasOwnProperty('DatasetSplitByEloCollection')) {


        let dsCollection = ds as DatasetSplitByEloCollectionWithLabel

        for (let key of Object.keys(dsCollection.dataset)) {
            dsCollection.dataset[key] = parse_dataset(dsCollection.dataset[key])
        }

        return {ds: dsCollection}

        // TODO add Elo brakcet picker
        // let target: { [k: string]: NewDataSetWithLabel } = {}
        //
        // Object.keys(dsCollection.dataset).map((k, i) => {
        //     target[k] = parse_dataset(dsCollection.dataset[k] as NewDataSetWithLabel)
        // })
        //
        // return {
        //     ds: {
        //         ...dsCollection,
        //         dataset: target
        //     }
        // }

    } else {
        return {ds: parse_dataset(ds as NewDataSetWithLabel)}
    }
    return {ds: ds};
});

const fetchOrGetCachedDataStaticDataset = (async (tableName: TStatsTableNames, patch_version: string, lazy?: boolean, pathArgs?: string[], isStatusCheck?: boolean) => {
    return fetchDataStaticDataset(tableName, patch_version, lazy, pathArgs, isStatusCheck);
})


const fetchPlayersTableWrappedIntoNewDataGroup = (async (pagination?: { start: number, count: number },
                                                         sort?: { by: string | string[], asc?: boolean },
                                                         selectTable?: { table: "civ" | "map_type", key: string },
                                                         searchNames?: string[],
                                                         table?: TStatsTableNames
) => {

    const targetTable: PlayerTableNames = table === "RM_Stats" ? "stats_views__players_RM" : "stats_views__players_EW"

    // let dataArray: [string, NewDataTableGroupWithSummary][] = await fetchData("TODO", true, undefined, pagination, sort)
    // if (dataArray === null)
    //     return null;
    let responseData: TableGroupView = await fetchData("TODO", targetTable, true, undefined, pagination, sort, undefined, selectTable, searchNames)
    if (responseData === null)
        return null;


    // let data : {[key: string] : NewDataTableGroupWithSummary} = new Map<string, NewDataTableGroupWithSummary>()
    let data = new Map<string, NewDataTableGroupWithSummary>()

    responseData.table.forEach((i) => {
        data.set(i[0], i[1])
        // data[i[0]] = i[1]
    })

    let sample: NewDataSampleWithSummary = {name: "All", size: data.size, tableGroups: data}
    let samples = {"All": sample}

    let dg: NewDataGroupWithLabelPagedView = {
        label: "Players", name: "Players",
        size: Object.keys(data).length,
        samples: samples,
        // summary: {},
        viewData: responseData.viewData
    }
    return dg;

});


const fetchPlayerProfileData = (async (profile_id: string, datasetName: PlayerTableNames) => {

    let data = await fetchData("TODO", datasetName, true, profile_id)
    if (data === null)
        return null;

    let dtg = data as PlayerStatsViewQueryResponse;

    // throw Error(JSON.stringify(dtg))

    return dtg;

});

const TEMP_CACHE: any = {}

export const DataProviderContext = React.createContext<IDataSourceContext | IDataSourceContextStatus | null>(null);

/**
 * Downloads a specific dataset, then depending on it's type either store full dataset in memory and provides
 * requested views or lazily downloads them (this should be opaque to the requester, i.e. UI should not care if the
 * dataset is static or lazily loaded.
 * @constructor
 */

//TODO store this in db isntead
const fixedPlayerInfoSidebarData: () => TSidebarItemCollection = () => {
    let items: TSidebarItemCollection = {}
    return items
}


type TShowLoadingIndicatorState = boolean | TQueryError | TQueryMessage;

const transformPathForDynamicDSItem = (group: string, key: string | undefined, currentPath: string[]) => {

    let currentKey = parseInt(currentPath[currentPath.length - 1]);
    let temp = [...currentPath]

    if (key === undefined) {

        if (!isNaN(parseInt(temp[temp.length - 1]))) {
            temp.pop();
        }

        temp[temp.length - 1] = group
    } else if (isNaN(currentKey)) {
        temp[temp.length - 1] = group
        temp.push(key)

    } else {
        temp[temp.length - 1] = key
        temp[temp.length - 2] = group
    }

    return temp

}

const getSidebarDataForDS = (type: "static" | "dynamic", defaultDataset: NewDataSetWithLabel, getSideBarDatasetItemsForNewDataGroup: (groups: TTableGroup, parentKey: string) => TSidebarItemCollection, currentPath: { set: (v: string[]) => void, value: string[] }) => {


    let ret = Object.fromEntries(getGroupedTablesKeys(defaultDataset).map((k) => {

        let tableGroups = getTableGroups(defaultDataset, k);
        let onClick: () => void = () => {
        };

        let sidebarItems: TSidebarItemCollection | undefined | null;
        if (tableGroups) {
            sidebarItems = getSideBarDatasetItemsForNewDataGroup(tableGroups, k);
            onClick = () => {
                currentPath.set(["grouped_tables", k])
            }
        } else if (tableGroups === undefined && type === "dynamic") {
            // TODO HACK(ish)
            // if table provides no full list of parent items
            // e.g. child pages is loaded dynamically and full parent list is not available
            // just return a static list of civs
            // currently not available for maps because there is no way of knowing which maps have how many
            // samples without the parent dataset

            if (k !== "civ") {
                sidebarItems = null;
                onClick = () => {
                    currentPath.set(transformPathForDynamicDSItem("maps", undefined, currentPath.value))
                }

            } else {

                const civs = getMetaDataGroupItems("civilizations");

                if (civs !== undefined) {

                    sidebarItems = {}

                    for (let k of Object.keys(civs)) {
                        let name = civs[k]
                        let icon: IconData = {description: name, group: "civilizations", key: k}
                        let item: ISidebarItem = {

                            iconData: icon,
                            key: k,
                            label: name,
                            onClick(): void {
                                currentPath.set(transformPathForDynamicDSItem("civilizations", k, currentPath.value))
                                // alert(name)
                            }
                        }
                        sidebarItems[k] = item
                    }

                    onClick = () => {
                        currentPath.set(transformPathForDynamicDSItem("civilizations", undefined, currentPath.value))
                    }
                }
            }

        }

        if (sidebarItems === undefined) {
            sidebarItems = {
                "NO DATA": {
                    label: "Not Implemented",
                    key: "Not_Implemented",
                    onClick: () => {
                        currentPath.set(["grouped_tables", k])
                    },
                }
            }
        }

        if (sidebarItems == null)
            sidebarItems = undefined
        // return [k, null]

        return [k, {
            label: getLabelForDataItemType(k as DataItemType),
            key: k,
            onClick: onClick,
            children: sidebarItems
            // children: getSideBarDatasetItemsForNewDataGroup(data.grouped_tables[k].samples["All"].tableGroups, k)
        }]
    }))

    let nonEmpty = Object.entries(ret).filter(([key, value]) => value !== null)

    let retFiltered: TSidebarItemCollection = Object.fromEntries(nonEmpty) as TSidebarItemCollection
    return retFiltered
}


export function DataProvider(props: IDataProviderProps) {

    // const [loadedDataSource, setLoadedDataset] = useState<NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel | null>(null)

    const [loadedDataSource, setLoadedDataSource] = useState<NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel | null>(null)
    const [selectedDataset, setSelectedDataset] = useState<NewDataSetWithLabel | null>(null)
    const [valueSearchTableServerside, setSearchTableServerside] = useState<undefined | { key: string, label: string }[]>(undefined)

    // const [currentPath, setCurrentPath] = useState<string[]>([])

    const {disableEloBracketPicker, setDisableEloBracketPicker} = {
        disableEloBracketPicker: props.disabledEloBracketPicker.value,
        setDisableEloBracketPicker: props.disabledEloBracketPicker.set
    }

    const getEloBrackets = (sourceDs: NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel | null) => {

        if (sourceDs && sourceDs.hasOwnProperty('DatasetSplitByEloCollection')) {
            let ds = sourceDs as DatasetSplitByEloCollection;
            return Object.keys(ds.dataset)
        } else if (sourceDs && sourceDs.hasOwnProperty("type")) {
            //&& sourceDs.type === "TeamRMStatsDataViewSplitByEloCollection"
            let ds = sourceDs as DatasetSplitByEloCollectionWithLabel;
            // return null
            return Object.keys(ds.dataset)
        } else {
            return null
        }
    }

    // const DEFAULT_ELO_BRACKET = ">1600";//"All"
    const DEFAULT_ELO_BRACKET: TEloBracket = "All";//"All"
    const DEFAULT_TEAM_ELO_BRACKET: TTeamEloBracket = ">1400";//"All"

    const getDataset = (ds: NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel | null, key?: string) => {

        let dsIsDatasetSplitByEloCollection = ds && ds.hasOwnProperty('DatasetSplitByEloCollection');
        let dsIsConvertedTeamDs = ds && ds.hasOwnProperty("type") && (ds as DatasetSplitByEloCollectionWithLabel).type === "TeamRMStatsDataViewSplitByEloCollection"

        let defaultDataset: NewDataSetWithLabel | null = null
        if (dsIsDatasetSplitByEloCollection || dsIsConvertedTeamDs) {
            let dsCollection = ds as DatasetSplitByEloCollectionWithLabel;
            if (key) {
                defaultDataset = dsCollection.dataset[key] as NewDataSetWithLabel;
            } else {
                if (dsCollection.dataset.hasOwnProperty(DEFAULT_ELO_BRACKET)) {
                    defaultDataset = dsCollection.dataset[DEFAULT_ELO_BRACKET] as NewDataSetWithLabel;
                } else if (dsCollection.dataset.hasOwnProperty(DEFAULT_TEAM_ELO_BRACKET)) {
                    defaultDataset = dsCollection.dataset[DEFAULT_TEAM_ELO_BRACKET] as NewDataSetWithLabel;
                }
            }
        } else if (defaultDataset) {
            defaultDataset = ds as NewDataSetWithLabel;
        }
        return defaultDataset
    }

    const eloBrackets = getEloBrackets(loadedDataSource)


    const metadataDefinesLoaded = useRef<boolean | "failed">(false);
    // const [metadataDefinesLoaded, setMetadataDefinesLoaded] = useState<boolean | "failed">(false);

    // useEffect(() => {
    //     InitMetaDataResolverCached().then(() => setMetadataDefinesLoaded(true)).catch(() => setMetadataDefinesLoaded("failed"))
    // }, [])

    //Set dataset based on selected Elo bracket is available, otherwise loadedDataSource == selectedDataset
    useEffect(() => {
        setSelectedDataset(getDataset(loadedDataSource, props.selectedEloBracket ? props.selectedEloBracket : undefined))

    }, [eloBrackets, loadedDataSource, props.selectedEloBracket])

    // const setLoadedDataset = (ds: NewDataSetWithLabel | DatasetSplitByEloCollectionWithLabel | null) => {
    //     _setLoadedDataset(ds);
    // }

    //
    // const eloBrackets: string[] | null = getEloBrackets(loadedDataset)
    //
    // useEffect(() => {
    //     props.onLoadedEloBracketData(eloBrackets)
    //     setDisableEloBracketPicker(eloBrackets !== null)
    // }, [eloBrackets])

    const [tableName, setTableName] = useState(props.dataset)

    const [stateHash, setStateHash] = useState("")

    const tableNameRef = useRef(tableName);


    const [selectTable, setSelectTable] = useState<null | { table: "civ" | "map_type", key: string }>(null)

    const currentPath = [...props.path.value]

    const [argPath, setArgPath] = useState<string[]>(currentPath)


    const setCurrentPath = (path: string[]) => {

        if (props.dataset === undefined)
            return;

        // window.scrollTo(0, 0);
        //
        // alert("DO SCROLLL")
        // container.scrollTop = 0
        setTimeout(() => {
            let container = document.getElementsByTagName("main");
            // let container = document.getElementById("main-container");
            if (container.length > 0) {
                // alert("D")
                // container[0].scroll({top: 0, behavior: 'smooth'});
                // @ts-ignore
                container[0].scroll({top: 0, behavior: 'instant'});
            }
        }, 0)

        props.path.set([...path])
    }

    let [serverSideSortData, setServerSideSortData] = useState<{ by: string | string[], asc?: boolean }>({
        by: "meta_data->latest_elo",
        // by: "n",
        asc: false
    })

    let [paginationData, setPaginationData] = useState<{ page: number, rowsPerPage: number, totalItems?: number }>({
        page: 0,
        rowsPerPage: 20,
    })
// }>({
//         page: 0,
//         rowsPerPage: 0,
//         setPage(p: number): void {setPagination({...pagination, page: p})},
//         setRowsPerPage(i: number): void {}, totalItems: 0
//     })

    let viewType: "static" | "stats_views__players" = props.path.value[0] === "stats_views__players" ? "stats_views__players" : "static"

    // TODO remove, no longer needed now summary is included directly in NewDataTableGroupWithSummary
    // IIRC this was needed to not duplicate data when all patch datasets were loaded at the same time
    // however now the API is called for each patch, and a civ has only a single entry in the whole dataset
    const [loadedPatchSummary, setLoadedPatchSummary] = useState<{ [k: string]: SampleSummaryValue }>({});

    const {disablePatchPicker, setDisabledPatchPicker} = {
        disablePatchPicker: props.disabledPatchVersionPicker.value,
        setDisabledPatchPicker: props.disabledPatchVersionPicker.set
    }


    useEffect(() => {
        let showSummary = false
        if (currentPath.includes("civ")) {
            if (currentPath.includes("samples")) {
                if (currentPath.includes("All")) {
                    if (currentPath.includes("tableGroups")) {
                        let temp = currentPath;

                        showSummary = true

                        let dataPromise = getSelectedDataviewForDataset([temp[0], temp[1]])
                        Promise.resolve(dataPromise).then((value) => {

                            if (value.data && isNewDataGroup(value.data)) {
                                // @ts-ignore
                                let dg = value.data as NewDataGroup
                                let key = props.path.value[props.path.value.length - 1]

                                // setLoadedPatchSummary(dg.summary[key])
                            }
                        })

                    }
                }
            }
        } else {
        }
        if (!showSummary) {
            setLoadedPatchSummary({})
        }

    }, [`${currentPath}`, props.dataset,
        // loadedDataset?.date,
        // valueSearchTableServerside,
        JSON.stringify(selectTable)])


    useEffect(() => {
        // alert(`Mount! ${tableName}`)

        if (props.dataset === "Team_RM_Stats") {
            // if (currentPath.length > 2) {
            setArgPath(currentPath)
            setForceReloadDataSource({reload: true, isStatusCheck: false})
            // }
        }
        // else {
        //     setArgPath([])
        // }


    }, [`${currentPath}`])


    useEffect(() => {


        if (props.dataset !== tableName) {
            // alert(`${props.tableName} !== ${tableName}`)
            setTableName(props.dataset)
        }

    }, [props.dataset])

    useEffect(() => {
        if (props.dataset === undefined)
            return
        fetchAvailablePatchVersions(props.dataset).then((data) => {
            props.onLoadedPatchesData(data)
        })

    }, [tableName])

    const [showLoadingIndicator, setShowLoadingIndicator] = useState<TShowLoadingIndicatorState>(false)

    // let showLoadingIndicator: TShowLoadingIndicatorState = false
    // if (metadataDefinesLoaded.current === true) {
    //     showLoadingIndicator = _showLoadingIndicator
    // } else if (metadataDefinesLoaded.current === false) {
    //     showLoadingIndicator = true
    // } else if (metadataDefinesLoaded.current === "failed") {
    //     let err: TQueryError = {error: "Could not connect to server!", message: "Please try again later"}
    //     showLoadingIndicator = err
    // }

    // const setShowLoadingIndicator = (s: TShowLoadingIndicatorState) => {
    //     if (s) {
    //         setLoadedDataSource(null);
    //     }
    //     _setShowLoadingIndicator(s)
    // }

    // Used to force a reload a datasource after a given time (if the model is being generated in an async job)
    // this is probably a better approach than having separate logic to check for ''/__s_/job_status/{job_id}''
    // TODO if this works fine simplify player stats page to use '/__s_/job_status/{job_id}' instead
    // NOTE: one reasons why this approach is not better is that there is no way to cache responses in static cache
    // i.e. when '/__s_/job_status/{job_id}' is used the proper response can just be cached, if the main api
    // is used even for status checks it always has to returned from DB, or rather caching should be implemented in backend
    // that is the mainv2 function shoud check internally if response is cached before querying DB. (TODO)
    const [forceReloadDataSource, setForceReloadDataSource] = useState<{ reload: boolean, isStatusCheck: boolean }>({
        reload: false,
        isStatusCheck: false
    });

    const forceReloadDataSourceInterval = useRef<number | undefined>(undefined)

    // const [forceReloadDataSourceInterval, setForceReloadDataSourceInterval] = useState<number | undefined>()


    useEffect(() => {
        //Only do if dataset is actually changed, not on URL change
        // alert("fetch!")

        const queryData = async () => {
            const metaDataResolverStatus = await InitMetaDataResolverCached();


            if (metaDataResolverStatus !== true) {
                const queryError: TQueryError = {error: "Could not connect to API", message: "Please try again later"}
                setShowLoadingIndicator(queryError)
            } else if (props.selectedPatchVersion && props.dataset) {

                let pathArgs: string[] | undefined;
                if (props.dataset === "Team_RM_Stats") {
                    pathArgs = argPath
                }

                const dataset = props.dataset;

                if (!showLoadingIndicator || showLoadingIndicator.hasOwnProperty("error")) {
                    // Do not show loading indicator if job is in progress
                    // i.e. set state loading only when another DS is loaded or an error is shown
                    setShowLoadingIndicator(true);
                }

                //             InitMetaDataResolverCached().then(() => {
                //     metadataDefinesLoaded.current = true
                //     setSelectedDataset(
                //         getDataset(loadedDataSource, props.selectedEloBracket ? props.selectedEloBracket : undefined)
                //     )
                //
                // }).catch(() => {
                //     metadataDefinesLoaded.current = false
                // })


                fetchOrGetCachedDataStaticDataset(props.dataset, props.selectedPatchVersion, props.lazyLoad, pathArgs, forceReloadDataSource.isStatusCheck).then((_data) => {


                    //    Update sidebar
                    if (_data) {

                        const isResponseTeamRMRJobQueryInfo = _data.hasOwnProperty("TeamRMStatsViewQueryJobInfo");

                        if (isResponseTeamRMRJobQueryInfo) {

                            const jobInfo = _data as TeamRMStatsViewQueryJobInfo;

                            if (jobInfo.status === -1) {

                                if (forceReloadDataSourceInterval.current !== undefined) {
                                    clearInterval(forceReloadDataSourceInterval.current)
                                    forceReloadDataSourceInterval.current = undefined;
                                }

                                if (jobInfo.data.hasOwnProperty("error")) {
                                    if (IsDev()) {
                                        throw Error(`${jobInfo.data.error}`)
                                    }
                                    // else {
                                    //
                                    // }
                                }
                                throw Error(`Could no generate requested model:\n Please try refreshing the page or come back later.`)
                            } else {
                                if (jobInfo.status !== 10) {
                                    const status: TQueryMessage = {
                                        message: "Querying requested data. This might take a while...",
                                        subLabel: "Feel free to close the window and come back  in a few minutes",
                                        progress: jobInfo.status * 10 + 1,
                                        progressNext: (jobInfo.status < 10 ? (jobInfo.status + 4) : 10) * 10
                                    }
                                    setSelectedDataset(null);
                                    setShowLoadingIndicator(status);
                                } else {
                                    setShowLoadingIndicator(true);
                                }

                                if (forceReloadDataSourceInterval.current === undefined) {


                                    const interval = setInterval(() => {
                                        setForceReloadDataSource({reload: true, isStatusCheck: true})
                                    }, 4000)

                                    forceReloadDataSourceInterval.current = interval as unknown as number;
                                    // setForceReloadDataSourceInterval(interval as unknown as number)
                                }
                            }

                        } else {

                            if (forceReloadDataSourceInterval.current !== undefined) {
                                // setForceReloadDataSourceInterval(undefined)
                                clearInterval(forceReloadDataSourceInterval.current)
                                forceReloadDataSourceInterval.current = undefined
                            }

                            let defaultDatasetIfAvailable: NewDataSetWithLabel | null = getDataset(_data.ds)
                            if (defaultDatasetIfAvailable) {
                                const defaultDataset: NewDataSetWithLabel = defaultDatasetIfAvailable;

                                setLoadedDataSource(_data.ds)

                                let eloBrackets = getEloBrackets(_data.ds)
                                props.onLoadedEloBracketData(eloBrackets)
                                const _disabledEloPicker = !(eloBrackets !== null);
                                setDisableEloBracketPicker(_disabledEloPicker)


                                if (_data.hasOwnProperty("error")) {
                                    setShowLoadingIndicator(_data as TQueryError);
                                    return;
                                }

                                // @ts-ignore
                                const loadedVersionKey = _data.ds.key

                                if (loadedVersionKey !== undefined) {
                                    props.setCurrentlyLoadedPatchVersionKey(loadedVersionKey)
                                }

                                const getSideBarDatasetItemsForNewDataGroup = (groups: TTableGroup, parentKey: string) => {
                                    let c: TSidebarItemCollection = Object.fromEntries(Object.keys(groups).filter(k => k !== "get").map((k) => {

                                        let item = groups.get(k)
                                        let icon_data: IconData | undefined;
                                        if (item?.meta_data?.hasOwnProperty("icon_data")) {
                                            // @ts-ignore
                                            icon_data = item.meta_data.icon_data;
                                        }

                                        return [k, {
                                            label: `${item?.meta_data?.name}`,
                                            iconData: icon_data,
                                            key: k,
                                            onClick: () => {
                                                setCurrentPath(["grouped_tables", parentKey, "samples", "All", "tableGroups", k])
                                            },
                                            // children:
                                        }]
                                    }));
                                    return c
                                }

                                const getSideBarDatasetItemsForDynamicDS = (groups: TTableGroup, parentKey: string) => {

                                    let r: TSidebarItemCollection = {}

                                    // let civs =

                                    return r;
                                }

                                // const getSideBarDatasetItems = getSideBarDatasetItemsForDynamicDS;
                                const getSideBarDatasetItems = props.dataset === "Team_RM_Stats" ? getSideBarDatasetItemsForDynamicDS : getSideBarDatasetItemsForNewDataGroup;
                                let sidebarDatasetItems: TSidebarItemCollection = getSidebarDataForDS(
                                    props.dataset === "Team_RM_Stats" ? "dynamic" : "static",
                                    defaultDataset,
                                    getSideBarDatasetItems,
                                    {
                                        value: props.path.value, set: setCurrentPath
                                    }
                                );


                                let topLevelItems = {...props.sidebarNavData.value.topLevelItems}

                                topLevelItems["__DS"] = {
                                    key: dataset,
                                    label: "Summary",
                                    flatten: props.dataset === "Team_RM_Stats",
                                    // label: dataset,
                                    onClick(): void {
                                        setCurrentPath([])
                                    },
                                    children: sidebarDatasetItems
                                }

                                topLevelItems["stats_views__players"] = {
                                    key: "stats_views__players",
                                    label: "Player Leaderboard",
                                    disabled: "Temporarily unavailable while AOE2.net is down",
                                    // disabled: true,
                                    onClick(): void {
                                        setCurrentPath(["stats_views__players"])
                                    },
                                    children: fixedPlayerInfoSidebarData()
                                }

                                if (props.dataset !== "Team_RM_Stats")
                                    topLevelItems["__about_view"] = {
                                        key: "about",
                                        label: "About",
                                        onClick(): void {
                                            setCurrentPath(["about"])
                                        },
                                        // children: fixedPlayerInfoSidebarData()
                                    }

                                props.sidebarNavData.set({
                                    topLevelItems: topLevelItems,
                                    datasetInfo: getDatasetInfo(defaultDataset)
                                })
                            }
                            setShowLoadingIndicator(false);
                        }
                    } else {

                        setShowLoadingIndicator(false);
                    }

                    // setShowLoadingIndicator(false);
                }).catch((err: any) => {
                    const queryError: TQueryError = {error: err.toString(), message: err.toString()}
                    // if (IsDev()) {
                    //     throw err;
                    // }
                    setShowLoadingIndicator(queryError)
                })
            }
        }


        queryData().catch(() => {
            const queryError: TQueryError = {error: "Something went wrong...", message: "Please try again later"}
            setShowLoadingIndicator(queryError)
        })

    }, [forceReloadDataSource])//, props.lazyLoad])

    useEffect(() => {

    }, [props.selectedEloBracket])

    useEffect(() => {
        setForceReloadDataSource({reload: true, isStatusCheck: false})
    }, [tableName, props.selectedPatchVersion,])

    const getSelectedDataviewForStaticDataset = (path: string[]) => {

        // @ts-ignore
        if (selectedDataset && (selectedDataset.data_view || (selectedDataset.hasOwnProperty("type") && selectedDataset.type))) {
            // @ts-ignore
            let dsType: DataModelType | undefined = (selectedDataset.hasOwnProperty("type") && selectedDataset.type) ? selectedDataset.type : undefined;
            return {data: selectedDataset, itemType: dsType ? dsType : getDataModelType(selectedDataset)}
        } else if (path.length === 0) {
            let dsType: DataModelType = "NewDataSet"
            return {data: selectedDataset, itemType: dsType}
        }

        // @ts-ignore
        let current: any = selectedDataset ? selectedDataset[path[0]] : null
        let i = 1
        while (current !== null && current !== undefined && i < path.length) {
            current = current[path[i]]
            i += 1
        }

        let itemType: DataModelType;

        if (current) {
            itemType = getDataModelType(current)
        }


        return {data: current ? current : null, itemType: itemType, datasetKey: getDataViewUpdateKey(props)}
    }

    // TDataSourceContextDataView
    const getSelectedDataviewForDynamicDataset = (path: string[], _tableName: TStatsTableNames) => {

        // 'http://127.0.0.1:8000/table_view/stats_views__players/197751'
        if (path.length === 1 && path[0] === "stats_views__players") {
            return new Promise<TDataSourceContextDataView>((resolve, reject) => {
                fetchPlayersTableWrappedIntoNewDataGroup(
                    {
                        start: paginationData.page * paginationData.rowsPerPage,
                        count: paginationData.rowsPerPage,

                    },
                    serverSideSortData ? serverSideSortData : undefined, selectTable ? selectTable : undefined,

                    valueSearchTableServerside?.map(v => v.key),
                    _tableName).then((data) => {
                    resolve({data: data, itemType: "NewDataGroup", datasetKey: getDataViewUpdateKey(props)})
                }).catch((err) => reject(err))

            });
        } else if (path.length === 3 && path[0] === "stats_views__players" && path[1] === "tableGroups") {
            return new Promise<TDataSourceContextDataView>((resolve, reject) => {
                fetchPlayerProfileData(path[2], _tableName === "RM_Stats" ? "stats_views__players_RM" : "stats_views__players_EW").then((data) => {
                    resolve({
                        datasetKey: getDataViewUpdateKey(props),
                        data: data ? data.table : null,
                        metaData: data ? {
                            last_finished_job: data.last_finished_job,
                            scheduled_or_running_job: data.scheduled_or_running_job
                        } : undefined,
                        itemType: "NewDataTableGroupWithSummary"
                    });
                }).catch((err) => reject(err))
            });

        }
        return {data: null, itemType: undefined}
    }


    const getSelectedDataviewForDataset = (path: string[]) => {

        if (viewType === "stats_views__players") {

            // alert("getSelectedDataviewForDataset")
            setDisabledPatchPicker(true)
            setDisableEloBracketPicker(true)

            let _table = props.dataset as TStatsTableNames
            return getSelectedDataviewForDynamicDataset(path, _table) as any

            // let hash = props.dataset + "-" + (valueSearchTableServerside ? valueSearchTableServerside.toString() : "") + "-" + path.join() + paginationData.rowsPerPage + "-" + paginationData.page + "-" + selectTable + serverSideSortData.by + "" + serverSideSortData.asc + ""
            //
            // alert("GET DS: " + hash)

            // let _table = props.dataset
            // // return getSelectedDataviewForDynamicDataset(path)
            // if (!TEMP_CACHE.hasOwnProperty(hash) && _table) {
            //     TEMP_CACHE[hash] = getSelectedDataviewForDynamicDataset(path, _table)
            // }
            //
            // return TEMP_CACHE[hash]
        }
        setDisabledPatchPicker(false)
        setDisableEloBracketPicker(false)


        // if (viewType === "static")
        return getSelectedDataviewForStaticDataset(path)

    }


    // if (props.dataset !== "EmpireWars_Stats") {
    //     return <Box><Typography>Dataset: {props.dataset} not found</Typography></Box>
    // }

    const selectTableServerside = (props: { table: "civ" | "map_type", key: string } | null) => {
        setSelectTable(props)
    }

    // const setSearchTableServerside = (values: string[] | undefined) => {
    //     setSearchTableServerside(values)
    // }

    const searchTableServerside = {set: setSearchTableServerside, values: valueSearchTableServerside}

    let getBaselineData: TBaselineTableGroupSampleDelegate | undefined = undefined;

    if (loadedDataSource && loadedDataSource.hasOwnProperty('dataset')) {
        getBaselineData = DatasetSplitByEloCollectionWithLabelBaselineProviderFactory("Elo", loadedDataSource as DatasetSplitByEloCollectionWithLabel)
    }

    // const getDatasetKey = (path: string[]) => {
    //     return `[  PATH: ${path.join(" -> ")}]`
    // }

    const getDataViewUpdateKey = (props: IDataProviderProps) => {
        const hash = props.dataset
            + JSON.stringify(props.path.value)
            + JSON.stringify(props.selectedEloBracket) +
            +JSON.stringify(props.selectedPatchVersion) +
            +JSON.stringify(selectTable)
            + JSON.stringify(selectTableServerside)
            + (valueSearchTableServerside ? JSON.stringify(valueSearchTableServerside) : "")
            + JSON.stringify(paginationData)
            + JSON.stringify(serverSideSortData)
        // let hash = props.dataset + "-" + (valueSearchTableServerside ? valueSearchTableServerside.toString() : "") + "-" + path.join() + paginationData.rowsPerPage + "-" + paginationData.page + "-" + selectTable + serverSideSortData.by + "" + serverSideSortData.asc + ""

        return sha1(window.btoa(unescape(encodeURIComponent(hash)))) as string;
    }

    if (showLoadingIndicator) {
        return <DataProviderContext.Provider
            value={showLoadingIndicator.hasOwnProperty("message") ? {status: showLoadingIndicator as (TQueryMessage | TQueryError)} : null}>
            {/*<PrettyPathHeaderRenderer path={props.path.value}/>*/}
            {props.children}
        </DataProviderContext.Provider>

    } else {
        return <DataProviderContext.Provider
            value={{

                getDataViewUpdateKey: () => getDataViewUpdateKey(props),
                viewGroup: props.dataset,
                isDataLoading: showLoadingIndicator,
                datasetKey: getDataViewUpdateKey(props),
                // datasetKey: getDatasetKey(props.path.value, props.selectedEloBracket, props.selectedPatchVersion),
                getBaselineData: getBaselineData,
                getSelectedDataView: () => {
                    return getSelectedDataviewForDataset(currentPath)
                },
                path: {value: props.path.value, set: setCurrentPath},
                tableServerside: {
                    select: selectTableServerside,
                    search: searchTableServerside,

                    value: selectTable
                },
                loadedPatchSummary: loadedPatchSummary,
                serversideSort: {
                    sort: (by, asc) => {
                        // alert(`asc: ${asc}`)
                        setServerSideSortData({by: by, asc: asc})
                    },
                    sortBy: serverSideSortData.by,
                    asc: !!serverSideSortData.asc
                },
                pagination: {
                    page: paginationData.page,
                    rowsPerPage: paginationData.rowsPerPage,
                    setRowsPerPage: (p) => setPaginationData({...paginationData, rowsPerPage: p}),
                    setPage: (p) => setPaginationData({...paginationData, page: p}),
                }
            }}>
            {/*<PrettyPathHeaderRenderer path={props.path.value}/>*/}
            {props.children}
        </DataProviderContext.Provider>
    }
}

