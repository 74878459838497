import * as React from 'react';
import {createContext, useContext, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import styles from "./DataTable.module.scss"
import PersonIcon from '@mui/icons-material/Person';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import {
    Alert,
    Autocomplete,
    AutocompleteRenderInputParams,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    Stack,
    TableFooter,
    TablePagination,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {NewDataGroupWithLabel, NewDataGroupWithLabelPagedView} from "../../Data/CheckType";
import {
    GetDefaultMetaData,
    GetItemKeyForIconData,
    GetItemKeyForMetaData,
    GetObjOrMapKeys,
    propToPercent,
    roundToDec
} from "../../Utils";
import {DataRendererExtensions, IPathObjectExtension, IViewInfoExtensions} from "../DefaultDataRenderer";
import {
    GameDurationDescriptiveDataCollection,
    IconData,
    IconGroup, MetaData,
    NewDataRow,
    NewDataTable,
    NewDataTableGroupWithSummary,
    PlayerMetaData,
    PlayerTableNames,
    ViewRequestData
} from "../../Data/ModelGenerated";
import {ObjectIcon, ObjectIconWrapper} from "../../UI/ObjectIcon";
import JSONTree from "react-json-tree";
import {StatsValueElement} from "../../UI/StatsValueElement";
import {DEFINES} from "../../Data/Defines";
import {LoadingButton} from "@mui/lab";
import {FindProfileForString, IFindProfileForStringResults} from "../../Internal/LiveAOE2NetInterface";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {END_POINTS_URL} from "../../Data/config";
import {DataTableWrapper, TExpandedSetter, TExpandedStateOrSetter} from "./DataTableWrapper";
import {OnSelectTopLevelGridItem} from "../Grid/GridViewForTableGroup";
import {TSearch} from "../../Internal/DataProvider";
import {PlayerReportStatusRenderer} from "../ItemDetails/PlayerStatsQueryJobInfoRenderer";
import {Stats} from "fs";
import {AppSettingsContext, IAppSettingsContext} from "../../App";
import {ObjectTypeTags} from "../DerivedStatsComponents/ObjectTypeTags";
import {MedianGameDurationWidget} from "../ItemDetails/MedianGameDurationWidget";

function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

export interface IDataTableField {
    key: string | string[]
    label: string | JSX.Element
    type?: "int" | "prop" | "icon" | "time" | "time_range"
    description?: string
    filterView?: JSX.Element
    ci?: boolean
    disabled?: boolean

    narrow?: boolean
}

export interface IDataTableCellValue {
    value: string | number | undefined | GameDurationDescriptiveDataCollection
    icon?: IconData | IconData[] | [IconData[], IconData[]]
    link?: boolean
    hideValue?: boolean
    element?: JSX.Element
}

export interface IDataTableRow {

    id: string | number
    values: { [fieldKey: string]: IDataTableCellValue }

    noAction?: boolean
}

export interface IPaginationRequest {
    rowsPerPage: number
    page: number
    setPage: (p: number) => void;
    setRowsPerPage: (i: number) => void;
}

export interface ISortRequest {
    sort: (by: string | string[], asc?: boolean) => void
    sortBy: string | string[]
    asc: boolean
}


export interface IDataTableProps {
    data: {
        order?: string[],
        fields: { [key: string]: IDataTableField },
        rows: { [key: string]: IDataTableRow }
    }

    pagination?: IPaginationRequest
    serversideSort?: ISortRequest
    expanded?: TExpandedStateOrSetter
}

interface MatchListPaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;

    // count?: number;
    // page: number;
    // rowsPerPage: number;
    // onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function DataTablePaginationActions(props: MatchListPaginationActionsProps) {
    // const classes = useStyles1();
    // const theme = useTheme();
    // const {count, page, rowsPerPage, onPageChange} = props;


    const count = props.count;
    const page = props.page;
    const rowsPerPage = props.rowsPerPage;
    const onPageChange = props.onPageChange;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (count !== undefined)
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={styles.pagination}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon/>
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={count !== -1 && page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={count === -1 || page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon/>
            </IconButton>
        </div>
    );
}

//Used for registering new players to be tracked
// function TrackNewPlayerDialog(props: { open: boolean, handleClose: () => void }) {
//
//     const [trackPlayersCallback, setTrackPlayersCallback] = useState<undefined | true | any>(undefined);
//     const [currentInputValue, setCurrentInputValue] = useState("")
//     const [isLockedForSearchOp, setIsLockedForSearchOp] = useState(false)
//     const [selectedPlayersSet, setSelectPlayersSet] = useState<Set<string>>(new Set<string>())
//
//     const trackPlayers = (profile_ids: Set<string>) => {
//
//         let table: PlayerTableNames = "stats_views__players_EW"
//
//         let params = new URLSearchParams()
//         for (let p of Array.from(profile_ids)) {
//             params.append("profile_ids", p)
//         }
//         const URI = `${END_POINTS_URL}/trackPlayers/${table}/?${params.toString()}`
//         fetch(URI, {method: 'GET'}).then(response => response.json())
//             .then(jsonResponse => {
//                 setTrackPlayersCallback({uri: URI, jsonResponse})
//             }).catch((err) => {
//             setTrackPlayersCallback({uri: URI, error: err})
//         })
//     }
//
//
//     // const [canConfirm, setCanConfirm] = useState(false)
//
//     const canConfirm = selectedPlayersSet.size > 0 && trackPlayersCallback === undefined
//
//     const handleConfirm = () => {
//         setTrackPlayersCallback(true)
//         trackPlayers(selectedPlayersSet)
//     }
//
//
//     const addSelectedPlayer = (profileId: string, remove: boolean) => {
//         let set = new Set<string>(selectedPlayersSet)
//
//         if (remove) {
//             if (set.has(profileId)) {
//                 set.delete(profileId)
//             }
//         } else {
//             set.add(profileId)
//         }
//         setSelectPlayersSet(set)
//     }
//
//     const [searchForProfileResults, setSearchForProfileResults] = useState<IFindProfileForStringResults | undefined>(undefined)
//
//     const searchForProfiles = (inputString: string) => {
//         setIsLockedForSearchOp(true)
//
//         FindProfileForString(inputString).then((res) => {
//             setSearchForProfileResults({possibleOptions: res})
//             setIsLockedForSearchOp(false)
//         })
//     }
//
//     const {open, handleClose} = props
//
//     let dialogContent: JSX.Element
//     if (trackPlayersCallback === true) {
//         dialogContent = <CircularProgress/>
//     } else if (trackPlayersCallback !== undefined) {
//
//         if (trackPlayersCallback.jsonResponse.hasOwnProperty("building reports for")) {
//             dialogContent = <div>
//                 Building reports for: {trackPlayersCallback.jsonResponse["building reports for"].map((p: any) =>
//                 <div>{p}</div>)}
//             </div>
//         } else {
//             dialogContent = <div>
//                 <div>Error:</div>
//                 <JSONTree data={trackPlayersCallback}/></div>
//         }
//     } else {
//         dialogContent = <React.Fragment>
//             <DialogContentText>
//                 Enter a player Profile ID or Steam ID for whom you want to be included in the table.
//                 You can also input a space separated list to add multiple players.
//
//                 (The Profile ID can be found by going to aoe2.net and selecting a profile and copying the value from the
//                 URL,
//                 e.g. in https://aoe2.net/#profile-196240 "196240" is the id)
//             </DialogContentText>
//             <TextField
//                 value={currentInputValue}
//                 onChange={(v) => setCurrentInputValue(v.currentTarget.value)}
//                 autoFocus
//                 margin="dense"
//                 id="profile-id-list"
//                 label="Profile IDs"
//                 // type="email"
//                 fullWidth
//                 variant="standard"
//
//                 InputProps={{
//                     endAdornment: currentInputValue.length > 0 ?
//                         <InputAdornment position="end">
//                             <LoadingButton
//                                 loading={isLockedForSearchOp}
//                                 onClick={() => {
//                                     searchForProfiles(currentInputValue)
//                                 }}
//                             >
//                                 <SearchIcon/>
//                             </LoadingButton>
//                         </InputAdornment> : undefined
//                 }}
//             />
//
//             {searchForProfileResults && searchForProfileResults.possibleOptions.find((res) => res.error == undefined) &&
//                 <span>Check to select</span>}
//             {searchForProfileResults && searchForProfileResults.possibleOptions && searchForProfileResults.possibleOptions.map((res) => {
//
//                 if (res.error) {
//                     return <Box sx={{marginTop: "5px"}}>
//                         <Chip
//                             icon={<PriorityHighIcon/>}
//                             label={`Not Found (${res.key})`}
//                             // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
//                         />
//                     </Box>
//
//                 } else if (res.player) {
//                     let isSelected = selectedPlayersSet.has(res.key)
//                     return <Box sx={{marginTop: "5px"}}>
//                         <Badge color="secondary" badgeContent={res.player?.elo}>
//                             <Chip
//                                 icon={<PersonIcon/>}
//                                 color={isSelected ? "primary" : undefined}
//                                 label={`${res.player?.name}(${res.key})`}
//                                 onClick={() => {
//                                     // alert(JSON.stringify(res))
//                                 }}
//                                 onDelete={() => {
//                                     addSelectedPlayer(res.key, isSelected)
//
//                                     // alert(JSON.stringify(res))
//                                 }}
//                                 deleteIcon={isSelected ? <ClearIcon/> : <CheckIcon/>}
//
//                                 // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
//                             />
//                         </Badge>
//                     </Box>
//                 }
//             })}
//
//         </React.Fragment>
//
//     }
//
//     return <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Track Profile Stats</DialogTitle>
//         <DialogContent>
//             {dialogContent}
//         </DialogContent>
//         <DialogActions>
//             {trackPlayersCallback !== undefined &&
//                 <Button disabled={false} onClick={handleClose}>Close</Button>
//             }
//             {(trackPlayersCallback === undefined || trackPlayersCallback === true) &&
//                 <React.Fragment>
//                     <Button onClick={handleClose}>Cancel</Button>
//                     <Button disabled={!canConfirm} onClick={handleConfirm}>Add Players</Button>
//                 </React.Fragment>
//             }
//         </DialogActions>
//     </Dialog>
//
// }
interface IDataTableLayoutContext {

    compact: boolean
}

export const DataTableLayoutContext = createContext<IDataTableLayoutContext>({
    compact: false
});


// mainViewItem -> hide header and disable collapsing
export default function DataTable(props: { title?: string, mainViewItem?: boolean, useGlobalSelect: boolean } & IDataTableProps & DataRendererExtensions & IViewInfoExtensions & IPathObjectExtension) {

    const useDesktopLayout = useMediaQuery('(min-width:600px)');
    const [_orderBy, _setOrderBy] = useState<string | undefined>('n')
    const [_order, _setOrder] = useState<'asc' | 'desc'>('desc')
    const settings = useContext(AppSettingsContext);

    const controlledExpanded = typeof props.expanded === "boolean" || props.expanded === undefined;

    const [__expanded, __setExpanded] = useState<boolean>(controlledExpanded ? props.expanded as boolean : (props.expanded as TExpandedSetter).value);

    let _expanded: boolean;
    let _setExpanded: (v: boolean) => void;

    if (controlledExpanded) {
        _expanded = __expanded;
        _setExpanded = __setExpanded;
    } else {
        const e = props.expanded as TExpandedSetter;
        _expanded = e.value;
        _setExpanded = e.set;
    }

    let order: 'asc' | 'desc';
    let orderBy: string | undefined;
    if (props.serversideSort !== undefined) {

        // alert(JSON.stringify(props.serversideSort.sortBy))
        if (typeof props.serversideSort.sortBy === 'string') {
            orderBy = props.serversideSort.sortBy
        } else {
            // let primaryItem = props.serversideSort.sortBy.find(k => k === "Latest Elo");
            // if (primaryItem !== undefined) {
            //     orderBy = primaryItem;
            // } else {
            orderBy = props.serversideSort.sortBy[0];
            // }
        }
        // orderBy = typeof props.serversideSort.sortBy === 'string' ? props.serversideSort.sortBy : props.serversideSort.sortBy[0]

        order = props.serversideSort.asc ? 'asc' : 'desc'
        // alert(order)
    } else {
        orderBy = _orderBy
        order = _order
    }

    let [_compactView, setCompactView] = useState(false)

    // const compactView = props.compactView === undefined ? _compactView : props.compactView;
    const compactView = _compactView;


    useEffect(() => {
        // alert("Mount table")
    }, [])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {


        if (props.serversideSort !== undefined) {
            let asc = orderBy === property && !props.serversideSort.asc
            // alert(`[${orderBy}] == [${property}] = ${orderBy === property}`)

            props.serversideSort.sort(property, asc)
        } else {
            const isAsc = orderBy === property && order === 'asc';

            _setOrder(isAsc ? 'desc' : 'asc');
            _setOrderBy(property);
        }
    };

    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    let sortedRowsKeys: string[] = [];//props.data.rows

    let keyPresent = false;

    if (orderBy) {
        let items = Object.values(props.data.rows);
        if (items.length > 0) {
            keyPresent = items[0].values.hasOwnProperty(orderBy);
        }
    }

    if (orderBy === undefined || keyPresent === false) {
        sortedRowsKeys = Object.keys(props.data.rows).filter(k => !props.data.rows[k].noAction)
    } else {
        sortedRowsKeys = Object.keys(props.data.rows).filter(k => !props.data.rows[k].noAction).sort((a, b) => {

            const orderByKey = orderBy as string;
            let valA = props.data.rows[a].values[orderByKey].value;
            let valB = props.data.rows[b].values[orderByKey].value;

            // @ts-ignore
            if (valA && valB && valA.win_data !== undefined && valB.win_data !== undefined) {
                // @ts-ignore
                const a = (valA as GameDurationDescriptiveDataCollection).win_data.m as number;
                // @ts-ignore
                const b = (valB as GameDurationDescriptiveDataCollection).win_data.m as number;

                return order === "desc" ? b - a : a - b;
            } else if (typeof valA === "string" && typeof valB === "string") {
                return order === "desc" ? valB.localeCompare(valA) : valA.localeCompare(valB);
            } else if (Number.isFinite(valA) && Number.isFinite(valB)) {
                // @ts-ignore
                return order === "desc" ? valB - valA : valA - valB;
            } else {
                return 0;
            }
        })
    }

    let sortedRowsKeysDisplay: string[] = sortedRowsKeys;
    if (settings.getShowDetailedCollapsed() && !_expanded) {
        sortedRowsKeysDisplay = sortedRowsKeys.slice(0, 5)
    } else {
        const summaryRowKeys = Object.keys(props.data.rows).filter(k => props.data.rows[k].noAction)
        summaryRowKeys.forEach((k) => sortedRowsKeysDisplay.push(k))
    }


    let orderedFields = Object.values(props.data.fields).filter(v => v !== undefined);

    const durationsMax = Object.values(props.data.rows).map(r => {
            if (r.values["duration"] !== undefined && r.values["duration"].value !== undefined) {
                const v = r.values["duration"].value as GameDurationDescriptiveDataCollection;

                return v.win_data?.q8;
            }
            return undefined
        }).filter((v) => v !== undefined) as number[]
    
    const maxDuration = Math.max(...durationsMax)


    let table = <Table size={compactView ? 'small' : 'medium'} aria-label="custom pagination a table">
        <TableHead>
            <TableRow>
                {/*<TableCell>Date</TableCell>*/}
                {/*<TableCell>Name</TableCell>*/}
                {/*<TableCell>Ship To</TableCell>*/}
                {/*<TableCell>Payment Method</TableCell>*/}
                {/*<TableCell align="right">Sale Amount</TableCell>*/}
                {orderedFields.map((f) => {

                    let label: JSX.Element;
                    const labelString = (f.key === "n" && !useDesktopLayout) ? "N" : f.label;

                    if (f.filterView) {
                        label = f.filterView;
                    } else {
                        label = <span>{labelString}</span>;
                    }

                    if (f.description !== undefined) {
                        label = <Tooltip title={f.description}>{label}</Tooltip>
                    }

                    let key = typeof f.key === "string" ? f.key : f.key.join("->")
                    return <TableCell sx={{
                        maxWidth: f.narrow ? "35px" : useDesktopLayout ? undefined : "105px",
                        padding: useDesktopLayout ? undefined : "8px"
                    }}>

                        {f.disabled ? label : <TableSortLabel
                            title={"dassaddas"}
                            active={orderBy === key}
                            direction={orderBy === key ? order : 'asc'}
                            // onClick={createSortHandler(f.key)}
                            onClick={createSortHandler(key)}
                        >
                            {label}

                        </TableSortLabel>}
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {sortedRowsKeysDisplay.map((key) => props.data.rows[key]).map((row) =>
                <TableRow key={row.id}>
                    {Object.keys(row.values).map((key) => {

                        const field = props.data.fields[key]
                        let skipItem = field === undefined;
                        if (skipItem)
                            return;

                        let value: string | undefined | JSX.Element;
                        let isLink = row.values[key].link
                        let onClick: (() => void) | undefined;
                        if (isLink) {
                            onClick = () => {
                                if (props.useGlobalSelect) {
                                    let group: 'civ' | 'map_type' | undefined = undefined;
                                    let targetKey = row.id;
                                    if (row.values) {
                                        if (row.values.name) {
                                            if (row.values.name.icon) {

                                                if (Array.isArray(row.values.name.icon)) {

                                                    targetKey = GetItemKeyForIconData(row.values.name.icon)
                                                } else {
                                                    if (row.values.name.icon.group === "civilizations")
                                                        group = 'civ'
                                                    else if (row.values.name.icon.group === "maps")
                                                        group = "map_type"

                                                    targetKey = row.values.name.icon.key;
                                                }
                                            }
                                        }
                                    }

                                    OnSelectTopLevelGridItem(`${targetKey}`, props.path.value, props.path.set, group)
                                } else {
                                    props.path.set([...props.path.value, "tableGroups", `${row.id}`])
                                }
                            }
                        }

                        if (!compactView && props.data.fields[key].type === "icon") {
                            let icon = row.values[key].icon;
                            if (icon) {
                                // if (props.compactView) {
                                //     // @ts-ignore
                                //     value = icon.description ? icon.description : row.values[key].value.toString();
                                // }
                                // else {
                                value = <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: isLink ? "pointer" : "undefined"
                                }} onClick={onClick}>
                                    {useDesktopLayout && <ObjectIconWrapper data={icon} borderless/>}
                                    <Typography sx={{
                                        marginLeft: useDesktopLayout ? "1rem" : "2px",
                                        minWidth: "9rem",
                                        color: isLink ? "primary.dark" : undefined
                                    }}>{row.values[key].value}</Typography>


                                    {!Array.isArray(icon) && props.viewInfo.showItemTags &&
                                        <ObjectTypeTags data={icon as IconData}/>}



                                </Box>
                                // }
                            }
                        } else if (compactView && props.data.fields[key].type === "icon") {
                            let icon = row.values[key].icon;

                            const labelStrs: string[] = [];
                            if (Array.isArray(icon)) {
                                let isGrouped = false;
                                for (const ic of icon) {
                                    if (Array.isArray(ic)) {
                                        isGrouped = true;
                                        for (const ic2 of ic) {

                                            const selectedIcon = ic2 as IconData
                                            labelStrs.push(selectedIcon.description ? selectedIcon.description : selectedIcon.key.toString())

                                            if (ic.length > 1 && (ic[ic.length - 1] as IconData).key !== selectedIcon.key) {
                                                labelStrs.push("+")
                                            }
                                        }
                                    } else {
                                        const selectedIcon2 = ic as IconData
                                        labelStrs.push(selectedIcon2.description ? selectedIcon2.description : selectedIcon2.key.toString())
                                    }

                                    const vsLabel = " vs. "

                                    if (!labelStrs.includes(vsLabel)) {
                                        labelStrs.push(vsLabel)
                                    }
                                }
                            } else if (icon) {
                                labelStrs.push(icon.description ? icon.description : icon.key)
                            }

                            const label = labelStrs.join(" ")

                            value = <Typography sx={{
                                marginLeft: useDesktopLayout ? "1rem" : "2px",
                                color: isLink ? "primary.dark" : undefined
                            }}>{label}</Typography>
                        }

                        if (value === undefined && isLink) {
                            value = <Typography onClick={onClick}
                                                sx={{
                                                    cursor: isLink ? "pointer" : undefined,
                                                    marginLeft: "1rem",
                                                    color: isLink ? "primary.dark" : undefined
                                                }}> {row.values[key].value} </Typography>
                        }

                        if (row.values[key].element !== undefined) {
                            value = <Stack direction={"row"} alignItems={"center"}
                                           sx={{}}>{row.values[key].element}
                                {row.values[key].hideValue ? undefined : value}
                            </Stack>
                        }
                        if (props.data.fields[key].type === "time_range" && row.values[key].value) {

                            // @ts-ignore
                            value = <Box sx={{
                                maxWidth: 165,
                                maxHeight: 45,
                                display: "flex",
                                justifyContent: "space-around"
                            }}>
                                {/*{AVG_TIME}*/}
                                {/*// @ts-ignore*/}
                                {/*{Object.values(row.values).filter(w => w.value !== undefined).filter(ww => ww.value.win_data !== undefined).map((v) => (v.value as GameDurationDescriptiveDataCollection).win_data.m).reduce((r, r1) => r + r1)}*/}
                                {/*// @ts-ignore*/}
                                {/*// @ts-ignore*/}
                                <MedianGameDurationWidget
                                    maxDuration={!Number.isNaN(maxDuration) && maxDuration > 60 ? maxDuration : undefined}
                                    type={"table"}
                                    data={row.values[key].value as GameDurationDescriptiveDataCollection}/>
                            </Box>
                        }
                        if (value === undefined) {
                            if (Number.isFinite(row.values[key].value)) {
                                let numVal = Number(row.values[key].value)
                                if (props.data.fields[key].type === "prop")
                                    value = `${propToPercent(numVal)}%`
                                else if (props.data.fields[key].type === "int")
                                    value = Math.round(numVal).toString()
                                else
                                    value = roundToDec(numVal).toString()
                            } else {
                                // @ts-ignore
                                value = row.values[key].value
                            }
                        }

                        // if (field.ci && row.values[key].ci) {
                        //     value = <Stack direction={"row"}>
                        //         <Box>
                        //             {value}
                        //         </Box>
                        //         <Box>
                        //             ±{row.values[key].ci}
                        //         </Box>
                        //     </Stack>
                        // }
                        return <TableCell sx={{
                            maxWidth: useDesktopLayout ? undefined : "105px",
                            padding: useDesktopLayout ? undefined : "8px"
                        }}>{value}</TableCell>
                    })}
                </TableRow>
            )}
            {(_expanded === false && settings.getShowDetailedCollapsed()) && <TableRow key={"__expand_table_row"}>

                <TableCell align="center" colSpan={4}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                        alignItems: "center",
                        cursor: "pointer"
                    }} onClick={() => {
                        _setExpanded(true);
                    }}>
                        <Typography sx={{
                            color: "primary.dark"
                        }}>Show All...</Typography>
                    </Box>
                </TableCell>
            </TableRow>}
            {/*{!expanded &&*/}
            {/*<TableRow key={"expanded"}>*/}
            {/*    <Button>Click to Show All Items</Button>*/}
            {/*</TableRow>}*/}

        </TableBody>
        {props.pagination && <TableFooter>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    colSpan={3}
                    count={props.viewRequestData ? props.viewRequestData.totalItems : -1}
                    // count={props.pagination.totalItems === undefined ? -1 : props.pagination.totalItems}
                    rowsPerPage={props.pagination.rowsPerPage}
                    page={props.pagination.page}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                    }}
                    onPageChange={(ev, page) => {
                        props.pagination?.setPage(page)
                    }}
                    onRowsPerPageChange={(ev) => {
                        props.pagination?.setRowsPerPage(Number(ev.target.value))
                    }}
                    ActionsComponent={DataTablePaginationActions}
                />
            </TableRow>
        </TableFooter>}

    </Table>
    let filterComponents = <Box
        sx={{display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "flex-end", marginTop: "5px"}}>
        <IconButton disabled={false} sx={{marginLeft: "5px"}} onClick={() => {
            setCompactView(!compactView)
        }}>
            {compactView ? <ZoomOutIcon/> : <ZoomInIcon/>}
        </IconButton>
        {props.filterComponents}
    </Box>

    // if (props.independent){
    //     return table
    // }
    // else {

    return <DataTableLayoutContext.Provider value={{compact: compactView}}>
        <DataTableWrapper title={props.title ? props.title : props.viewInfo.title}
                          disableCollapse={props.disableCollapse}
                          filterComponents={filterComponents}
                          mainViewItem={props.mainViewItem}
                          expanded={{value: _expanded, set: _setExpanded}}>
            {table}
        </DataTableWrapper>
    </DataTableLayoutContext.Provider>
    // }
}

export interface IDataTableForDataGroupsProps {
    data: (NewDataGroupWithLabel | NewDataGroupWithLabelPagedView) & { key: string }
}


export function DataTableForDataGroups(props: IDataTableForDataGroupsProps & DataRendererExtensions & IViewInfoExtensions & IPathObjectExtension) {

    const settingsCtx = useContext<IAppSettingsContext>(AppSettingsContext);
    const useCi = props.data.name === "civ" && settingsCtx.getShowCIfAvailable()
    const fields: { [key: string]: IDataTableField } = {
        "name": {
            key: "name",
            label: props.data.label,
            type: "icon"

        },
        "n": {
            key: "n",
            label: "Total Games",
            type: "int"
        },
        "prop": {
            key: "prop",
            label: "Play Rate",
            type: "prop"
        }
    }
    const showWinRate = props.data.name === "civ"

    if (showWinRate) {
        fields["win_rate"] = {
            key: "win_rate",
            label: "Win Rate",
            type: "prop",
            ci: useCi
        }
    }


    if (props.data.samples) {
        for (const s of Object.values(props.data.samples)) {
            if (s.duration !== undefined) {
                fields["duration"] = {
                    key: "duration",
                    label: "Avg. Win Time",
                    type: "time_range",
                    description: " 2nd decile, Median & 8th decile"
                    // narrow: true

                }
                // fields["duration_loss"] = {
                //     key: "duration_loss",
                //     label: "Loss",
                //     type: "time",
                //     narrow: true
                // }

                break
            }
        }
    }


    // @ts-ignore
    let rows: { [key: string]: IDataTableRow } = {}
    let itemNSum: number = 0;
    GetObjOrMapKeys(props.data.samples["All"].tableGroups).forEach((key) => {

        if (key === "get")
            return;

        let item = props.data.samples["All"].tableGroups.get(key) as unknown as NewDataTableGroupWithSummary;

        itemNSum += isFinite(item.n) ? item.n : 0;
        rows[`kk_${key}`] = {
            id: key,
            values: {
                "name": {
                    value: item.meta_data?.name,
                    link: true,
                    // @ts-ignore
                    icon: (item.meta_data && item.meta_data.hasOwnProperty("icon_data")) ? item.meta_data.icon_data : undefined
                },
                "n": {value: item.n},
                "prop": {value: item.prop},
                "win_rate": {
                    value: item.win_rate,

                    element: useCi ? <StatsValueElement
                        hideBaseline={true}
                        dontShowProgress
                        label={"Win Rate"}
                        baseline={0.5}
                        ci={useCi ? {n: item.n} : undefined}
                        value={item.win_rate}
                        baselineMagnitude={0.25}
                        unit={"%"}/> : undefined
                },
                "duration": {
                    value: item.duration
                },
            }
        }
    })


    if (itemNSum > 0) {
        const rowId = "_zzz_sum_row"
        rows[rowId] = {
            noAction: true,
            id: rowId,
            values: {
                "name": {
                    value: "Total Games"
                },
                "n": {value: itemNSum},
                "prop": {value: ""},
                "win_rate": {value: ""},
            }
        }
    }

    let data: IDataTableProps = {
        serversideSort: props.serversideSort,
        data: {
            fields: fields,
            rows: rows
        }
    }

    let viewData: ViewRequestData | undefined;
    if (props.data.hasOwnProperty("viewData")) {
        viewData = (props.data as NewDataGroupWithLabelPagedView).viewData
    }

    return <DataTable {...data}
                      resolveDataPromise={props.resolveDataPromise}


                      useGlobalSelect={true}
                      dataKey={props.dataKey}

                      mainViewItem

                      path={props.path}
        // viewInfo={props.viewInfo}
                      viewInfo={props.viewInfo}
                      viewRequestData={viewData}
                      compactView={false}
                      pagination={undefined}/>
}

export interface IFilterMenuProps {
    disabled?: boolean
    value: null | string
    nullLabel: string
    options: { label: string, key: string }[]
    iconGroup: IconGroup
    onChange: (value: string | null) => void
}

const IconWithInputField = (props: IFilterMenuProps & { params: AutocompleteRenderInputParams }) => {
    return <Box sx={{display: "flex"}}>
        {/*{props.value && <ObjectIcon data={{group: props.iconGroup, key: props.value}} borderless/>}*/}
        <TextField {...props.params} size={"medium"} label={props.nullLabel}/>
    </Box>
}

export function FilterMenu(props: IFilterMenuProps) {

    let defaultItem = props.options.find((o) => o.label === props.value)
    const [value, setValue] = useState(defaultItem)
    // const [inputValue, setInputValue] = useState(defaultItem ? defaultItem.label : undefined)

    return <div className={`${styles.filterButtonContainer}`}>
        <Autocomplete

            disabled={props.disabled}
            // inputValue={""}
            // inputValue={inputValue ? inputValue : ""}
            // value={[]}
            value={value ? [value] : []}

            // onInputChange={(ev, val) => {
            //     setInputValue(val)
            // }}
            onChange={(ev, val) => {

                if (val.length > 0) {
                    setValue(val[val.length - 1])
                    props.onChange(val[val.length - 1].label)
                } else {
                    setValue(undefined)
                    props.onChange(null)
                }

                // alert(JSON.stringify(val))
            }}
            multiple
            size={"small"}
            disablePortal
            // limitTags={1}
            // getOptionLabel={(option) => option.label}

            id={`filter-box${props.nullLabel}`}
            options={props.options}
            sx={{minWidth: 300}}
            renderInput={(params) => <IconWithInputField {...props} params={params}/>}
            renderTags={options => {
                return (
                    options.map((option: { label: string, key: string } | null) => {
                            return <React.Fragment>
                                {option && <ObjectIcon data={{group: props.iconGroup, key: option.key}} borderless/>}
                                {option && option.label}
                            </React.Fragment>
                        }
                    ))

            }}

            renderOption={(renderProps, option: { label: string, key: string } | null) => {
                // return <React.Fragment>
                return <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...renderProps}>
                    {option && <ObjectIcon data={{group: props.iconGroup, key: option.key}} borderless/>}
                    <Box sx={{width: "0.65rem"}}/>
                    {option && option.label}
                </Box>
                {/*</React.Fragment>*/
                }
            }}

        />

    </div>
    //     <Button
    //     className={`${props.value ? styles.on : styles.empty}`}
    //     color={props.value ? "primary" : "info"}
    //     size={"small"}
    //     aria-controls="12"
    //     aria-haspopup="true"
    //     aria-expanded={open ? 'true' : undefined}
    //     variant="outlined"
    //     disableElevation
    //     onClick={() => {
    //         setOpen(!open)
    //     }}
    //     endIcon={<KeyboardArrowDownIcon/>}
    // >{props.nullLabel}</Button>
    // </div>
}

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export function PlayerNameSearchFieldWrapper(props: { onClose: () => void, search: TSearch }) {
    const QUERY_DELAY = 5000;

    const [lastQuery, setLastQuery] = useState(0)

    const [open, setOpen] = React.useState(false);
    const [searchInputValue, setSearchInputValue] = React.useState("");

    const [loading, setIsLoading] = React.useState(false);


    const [availableOptions, setAvailableOptions] = useState<{ label: string, key: string }[]>([])

    React.useEffect(() => {
        let active = true;

        if (searchInputValue.length === 0) {
            return undefined;
        }
        setIsLoading(true);


        (async () => {
            let timeDiff = new Date().getTime() - lastQuery;

            if (timeDiff < QUERY_DELAY) {
                await sleep(QUERY_DELAY - timeDiff)
            }

            const searchInput = searchInputValue
            const url = `https://aoe2.net/api/leaderboard?game=aoe2de&leaderboard_id=3&search=${searchInput}`
            fetch(url).then(r => r.json()).then(r => {


                if (active) {
                    let options: { label: string, key: string }[] = r.leaderboard.map((p: any) => {
                        return {key: p.profile_id, label: p.name}
                    })
                    setAvailableOptions(options);
                    setLastQuery(new Date().getTime())
                    setIsLoading(false)
                }

            })

        })();


        return () => {
            active = false;
        };
    }, [searchInputValue]);

    React.useEffect(() => {
        if (!open) {
            setAvailableOptions([]);
        }
    }, [open]);

    //TODO fix duplication with 'FilterMenu', merge this all into FilterMenu after improving it to handle tags etc.
    return <div className={`${styles.filterButtonContainer}`}>
        <Autocomplete multiple
            // filterOptions={(x) => x}
                      value={props.search.values}
                      sx={{minWidth: 300}}
                      open={open}
                      onOpen={() => {
                          setOpen(true);
                      }}
                      onClose={() => {
                          setOpen(false);
                      }}
                      loading={loading}
                      options={availableOptions}
                      renderInput={(params) => {
                          return <Box sx={{display: "flex"}} minWidth={"250px"} flexGrow={2}>
                              <TextField {...params} size={"medium"} label={"Find Player"}> </TextField>
                          </Box>
                      }}

                      onChange={(ev, value: { key: string, label: string }[]) => {
                          props.search.set(value);
                          // props.search.set(value.map((v) => v.key))
                      }}


                      onInputChange={(ev, v) => {
                          setSearchInputValue(v)
                      }}

            // filterOptions={(x) => []}


                      size={"small"}
                      disablePortal

            // renderTags={options => {
            //     return (
            //         options.map((option: { label: string, key: string } | null) => {
            //                 return <React.Fragment>
            //                     {option && option.label}
            //                 </React.Fragment>
            //             }
            //         ))
            //
            // }}
                      renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                              <Chip variant="outlined" label={option.label} {...getTagProps({index})} />
                          ))
                      }

                      renderOption={(renderProps, option: { label: string, key: string } | null) => {
                          // return <React.Fragment>
                          return <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...renderProps}>
                              <Box sx={{width: "0.65rem"}}/>
                              {option && option.label}
                          </Box>
                      }
                      }

        />
    </div>
}

export function FilterPlayerByNameControl(props: {
    label: string,
    searchMode: { value: boolean, set: (v: boolean) => void }
}) {


    // const [searchMode, setSearchMode] = useState(false);

    let searchButton = <Box marginLeft={"5px"}>
        <IconButton
            size={"small"}
            onClick={() => {
                props.searchMode.set(true)
            }}>
            <SearchIcon/>
        </IconButton>
    </Box>
    //
    // if (searchMode) {
    //     return <Stack direction={"row"} alignItems={"center"}>
    //         <PlayerNameSearchFieldWrapper onClose={() => setSearchMode(false)}/>
    //     </Stack>
    //
    // } else {

    return <Stack direction={"row"} alignItems={"center"}>
        {props.label}
        {searchButton}
    </Stack>
    // }
}

export function DataTableForPlayersList(props: IDataTableForDataGroupsProps & DataRendererExtensions & IViewInfoExtensions & IPathObjectExtension) {


    const [showTrackNewPlayerDialog, setShowTrackNewPlayerDialog] = useState(false)
    // const [searchByNameMode, setSearchByNameMode] = useState(!!(props.tableServerside?.search?.values?.length));
    const [searchByNameMode, setSearchByNameMode] = useState(true);


    const filterCiv = props.tableServerside?.value?.table === "civ" ? props.tableServerside?.value.key : null;//useState<string | null>(null)
    const filterMap = props.tableServerside?.value?.table === "map_type" ? props.tableServerside?.value.key : null

    const setFilterCiv = (value: string | null) => {
        if (props.tableServerside)
            props.tableServerside.select(value ? {table: "civ", key: value} : null)
    }

    const setFilterMap = (value: string | null) => {
        if (props.tableServerside)
            props.tableServerside.select(value ? {table: "map_type", key: value} : null)
    }


    // @ts-ignore
    let rows: { [key: string]: IDataTableRow } = {}
    GetObjOrMapKeys(props.data.samples["All"].tableGroups).forEach((key, id) => {

        let item = props.data.samples["All"].tableGroups.get(key) as unknown as NewDataTableGroupWithSummary
        let meta = item.meta_data ? item.meta_data as PlayerMetaData : undefined;

        rows[`kk_${key}`] = {
            id: key,
            values: {
                "rank": {
                    value: meta?.rank
                },
                "name": {
                    value: item.meta_data?.name,
                    link: true,
                    // element: <JSONTree data={item.meta_data}/>
                    // @ts-ignore
                    element: meta.country ? <Box height={"16px"}>
                        {/*// @ts-ignore*/}
                        <img src={`https://flagcdn.com/h40/${meta.country.toLowerCase()}.jpg`} height={"16px"}/>
                        {/*<ReactCountryFlag countryCode={meta.country}/>*/}
                    </Box> : undefined


                },
                "n": {value: item.n},
                // "prop": {value: item.prop},
                // @ts-ignore
                'meta_data->latest_elo': {value: item.meta_data.latest_elo},
                // @ts-ignore
                'meta_data->highest_elo': {value: item.meta_data.highest_elo},

                // "win_rate": {value: item.win_rate},
                "win_rate": {
                    value: item.win_rate,
                },

                "stats_view": {
                    value: item.last_updated ? Date.parse(item.last_updated) : item.last_updated,
                    element: <PlayerReportStatusRenderer table={"stats_views__players_RM"} item={item}/>
                    // value: item.
                    // value: item.last_updated
                }

            }
        }

        if (filterMap || filterCiv) {
            rows[`kk_${key}`].values["adjusted_elo"] = {
                // @ts-ignore
                value: item.n > 10 ? estimatePlayerMapElo(item.meta_data.latest_elo, item.win_rate, item.n) : 0,
                hideValue: true,
                element: item.n > 10 ? <StatsValueElement label={"Est. Map Elo"} unit={undefined}
                                                          dontShowProgress={true}
                        // @ts-ignore
                                                          value={Math.round(estimatePlayerMapElo(item.meta_data.latest_elo, item.win_rate, item.n))}
                                                          baseline={undefined}/> //TODO compare to player elo?
                    : <div/>


            }

            // rows[`kk_${key}`].values["adjusted_elo"] = {value: estimatePlayerMapElo(item.meta_data.latest_elo, item.win_rate)}
        }
    })

    let data: IDataTableProps = {
        serversideSort: props.serversideSort,
        data: {
            fields: {
                "rank": {
                    key: "rank",
                    label: "Rank",
                    type: "int"

                },
                "name": {
                    key: "name",
                    label: props.data.label,
                    type: "icon",
                    // filterView: <FilterPlayerByNameControl label={props.data.label}/>

                },
                "n": {
                    key: "n",
                    label: "Total Games",
                    type: "int"
                },
                'meta_data->latest_elo': {
                    key: ['meta_data', 'latest_elo'],
                    label: 'Latest Elo',
                    type: 'int'
                },
                'meta_data->highest_elo': {
                    key: ['meta_data', 'highest_elo'],
                    label: 'Highest Elo',
                    type: 'int'
                },
                // "prop": {
                //     key: "prop",
                //     label: "Play Rate",
                //     type: "prop"
                // },
                "win_rate": {
                    key: "win_rate",
                    label: "Win Rate",
                    type: "prop"
                },
                "stats_view": {
                    key: "stats_view",
                    label: "Report",
                    type: "icon",
                    disabled: true
                }

            },
            rows: rows
        }
    }

    let tt = Object.values(rows).find(row => row.values.rank.value)
    let dropRank = tt === undefined

    if (dropRank) {
        delete data.data.fields["rank"]
    }


    if (filterCiv || filterMap) {
        let type_label = filterCiv !== null ? "Civ" : "Map";
        data.data.fields["adjusted_elo"] = {
            key: "win_rate",
            // label: "Adj. Elo",
            type: "int",
            description: `Estimate of what player Elo should be to have an expected average 50% win rate for a given ${type_label.toLowerCase()}. \n (Assuming the player played all the matches their current ELO and calculated using the FIDE Elo formula, so a very rough estimate) `,
            label: `Est. ${type_label} Elo`,
            disabled: true
        }
    }

    let viewData: ViewRequestData | undefined;
    if (props.data.hasOwnProperty("viewData")) {
        viewData = (props.data as NewDataGroupWithLabelPagedView).viewData
    }

    const buildOptions = () => {

    }

    let optionsCivs = Object.keys(DEFINES.civilizations).map(k => {
        // @ts-ignore
        return {label: DEFINES.civilizations[k]["name"], key: k}
    })
    let optionsMaps = Object.keys(DEFINES.maps).map(k => {
        // @ts-ignore
        return {label: DEFINES.maps[k], key: k}
    })


    let filterComponents = [
        <FilterMenu disabled={filterCiv !== null}
                    onChange={setFilterMap} iconGroup={"maps"} options={optionsMaps}
                    value={filterMap} nullLabel={"Filter by Map"}/>,
        <FilterMenu disabled={filterMap !== null} onChange={setFilterCiv} iconGroup={"civilizations"}
                    options={optionsCivs} value={filterCiv} nullLabel={"Filter by Civ"}/>
    ]

    if (searchByNameMode && props.tableServerside) {
        filterComponents = [<PlayerNameSearchFieldWrapper
            search={props.tableServerside.search}
            onClose={() => setSearchByNameMode(false)}/>, ...filterComponents]
    }

    // return <JSONTree data={optionsCivs}/>
    return <Box>
        {/*<Alert severity="warning">*/}
        {/*    <p> Please not that due capacity constraints stats are not precalculcated for all players automatically.</p>*/}
        {/*    If you can't find a player <Button onClick={() => {*/}
        {/*    setShowTrackNewPlayerDialog(true)*/}
        {/*}} color="secondary">Click Here</Button> to add them to the tracked player list.*/}
        {/*</Alert>*/}
        {/*<TrackNewPlayerDialog*/}
        {/*    key={new Date().getTime()}*/}
        {/*    open={showTrackNewPlayerDialog} handleClose={() => {*/}
        {/*    setShowTrackNewPlayerDialog(false)*/}
        {/*}}/>*/}
        <Typography variant={"h3"} fontSize={"1.25em"} marginBottom={"0.75rem"} marginTop={".75rem"}>Player
            Leaderboard</Typography>
        <DataTable {...data}
                   disableCollapse={true}
                   resolveDataPromise={props.resolveDataPromise}

                   dataKey={props.dataKey}
                   useGlobalSelect={false}
                   path={props.path}
                   viewInfo={{
                       // @ts-ignore
                       title: props.viewInfo.title as string
                       // title: <FilterPlayerByNameControl
                       //     searchMode={{value: searchByNameMode, set: setSearchByNameMode}}
                       //     label={props.viewInfo.title as string}/>
                   }}
                   viewRequestData={viewData}
                   compactView={props.compactView} pagination={props.pagination}
                   filterComponents={filterComponents}
        />
    </Box>
}


export interface IDataTableForNewNewDataTable {
    data: NewDataTable
    expanded?: TExpandedStateOrSetter
}


export function DefaultDataTableForNewDataTable(props: { title?: string } & IDataTableForNewNewDataTable & DataRendererExtensions & IViewInfoExtensions & IPathObjectExtension) {

    const settingsCtx = useContext<IAppSettingsContext>(AppSettingsContext);
    const useCI = settingsCtx.getShowCIfAvailable();


    let itemNSum = Object.values(props.data.data).map(v => v.n).reduce((cumm, add) => cumm += add, 0);
    // @ts-ignore
    let rows: { [key: string]: IDataTableRow } = {}
    Object.keys(props.data.data).forEach((key) => {

        let item: NewDataRow = props.data.data[key]
        // itemNSum += item.n;

        let icon: IconData | IconData[] | [IconData[], IconData[]] | undefined;
        let nameLabel: string = ""
        if (item.meta_data) {
            if (Array.isArray(item.meta_data)) {

                if (Array.isArray(item.meta_data[0])) {
                    let matchUpMd = item.meta_data as [MetaData[], MetaData[]]
                    let teamAIcon = matchUpMd[0].map(i => i.icon_data).filter(i => i !== undefined) as IconData[]
                    let teamBIcon = matchUpMd[1].map(i => i.icon_data).filter(i => i !== undefined) as IconData[]
                    icon = [teamAIcon, teamBIcon]

                } else {
                    let teamMd = item.meta_data as MetaData[]
                    icon = teamMd.map(i => i.icon_data).filter(i => i !== undefined) as IconData[]
                }
            } else {
                if (item.meta_data.hasOwnProperty("icon_data")) {
                    icon = item.meta_data.icon_data
                    nameLabel = GetDefaultMetaData(item.meta_data).name
                }
            }
        }


        let values: any = {
            "name": {
                value: nameLabel,
                // value: item.meta_data?.name,
                link: Array.isArray(item.meta_data) ? false : true,
                icon: icon
            },
            "n": {value: item.n},

            "prop": {
                value: item.prop,
                hideValue: true,
                element: <StatsValueElement label={"Play Rate"} unit={"%"} dontShowProgress={true}
                                            value={item.prop}
                                            baseline={item.baseline?.prop}/>

            },
        }

        if (!props.viewInfo.noWinRate) {
            values["win_rate"] = {
                value: item.win_rate,
                hideValue: true,
                element: <StatsValueElement label={"Win Rate"}
                                            ci={useCI ? {n: item.n} : undefined}
                                            unit={"%"} dontShowProgress={true}
                                            value={item.win_rate}
                                            baseline={item.baseline?.win_rate}/>

            }
        }

        if (item.duration) {
            values["duration"] = {
                value: item.duration,
                hideValue: true,
            }
        }

        if (props.showProp0) {

            values = {
                name: values["name"],
                n: values["n"],
                "prop0": {value: (1 / itemNSum) * item.n},
                prop: values["prop"],
                win_rate: values["win_rate"],
                estimated_elo: values["estimated_elo"],

            }
            // values["prop0"] = {value: propToPercent((1 / itemNSum) * item.n)}
        }
        //"prop_0" : {value: propToPercent((1 / itemNSum) * item.n)},

        if (props.nMin === undefined || item.n > props.nMin) {

            rows[key] = {
                id: key,
                values: values
            }
        }
    })

    if (itemNSum > 0) {
        const rowId = "_zzz_sum_row";
        let values: any;


        if (props.showProp0) {
            values = {
                "name": {
                    value: "Total Games"
                },
                "n": {value: itemNSum},
                "prop0": {value: ""},
                "prop": {value: ""},
                "win_rate": {value: ""},
            }
        } else {
            values = {
                "name": {
                    value: "Total Games"
                },
                "n": {value: itemNSum},
                "prop": {value: ""},
                "win_rate": {value: ""},
            }
        }

        if (props.expanded) {
            rows[rowId] = {
                noAction: true,
                id: rowId,
                values: values
            }
        }
    }


    let data: IDataTableProps = {
        serversideSort: props.serversideSort,
        data: {
            fields: {
                "name": {
                    key: "name",
                    label: "Name",
                    // label: props.data.type,
                    type: "icon"
                },
                "n": {
                    key: "n",
                    label: "Total Games",
                    type: "int"
                },
                "prop": {
                    key: "prop",
                    label: "Play Rate",
                    type: "prop"
                },
                "win_rate": {
                    key: "win_rate",
                    label: "Win Rate",
                    type: "prop"
                }

            },
            rows: rows
        }
    }

    if (props.viewInfo.noWinRate) {
        delete data.data.fields["win_rate"]
    }

    if (props.data.data) {
        for (const row of Object.values(props.data.data)) {
            if (row.duration !== undefined) {
                data.data.fields["duration"] = {
                    key: "duration",
                    label: "Avg. Win Time",
                    type: "time_range",
                    description: " 2nd decile, Median & 8th decile"
                    // narrow: true

                }

                break
            }
        }
    }


    if (props.showProp0) {
        let prop0: IDataTableField = {
            key: "prop0",
            label: "%",
            type: "prop"
        }

        //         "name":
        // "n":
        // "prop":
        // "win_rate":
        // "estimated_elo":

        data.data.fields = {
            name: data.data.fields["name"],
            n: data.data.fields["n"],
            "prop0": prop0,
            prop: data.data.fields["prop"],
            win_rate: data.data.fields["win_rate"],
            estimated_elo: data.data.fields["estimated_elo"],
        }
    }


    return <DataTable
        resolveDataPromise={props.resolveDataPromise}
        showPreview={props.showPreview}
        dataKey={props.dataKey}
        useGlobalSelect={true}
        // independent={props.independent}
        expanded={props.expanded}
        path={props.path}
        title={props.title}

        viewInfo={props.viewInfo}
        {...data}
        filterComponents={props.filterComponents}
        compactView={props.compactView}/>
}


//Basically by how much your Elo needs to increase/decrease to have a 50% win rate on the map
//y = x - (400 log(1/z - 1))/log(10) and x>0 and 1/(10^(x/400) + 1)<z<1/2
const estimatePlayerMapElo = (real_elo: number, win_rate: number, n: number) => {


    let nMod = Math.min((1 / 50) * n, 1)

    let z = Math.max(0.01, Math.min(win_rate, 0.99));
    let x = real_elo;
    // let y = 0.43429 * (-2513.3 * i * n + 2.3026 * x - 400 * Math.log(1 / z - 1))

    let y = x - (173.718 * Math.log((1 / z) - 1)) * nMod

    if (!isFinite(y)) {
        console.log(1)
    }

    return y
    // return Math.round(real_elo * (1 + (win_rate - 0.5)))
}

export function MapDataTableForPlayer(props: IDataTableForNewNewDataTable & DataRendererExtensions & IViewInfoExtensions & IPathObjectExtension & { player_elo: number }) {
    let type_label = props.data.type === "civs_table" ? "Civ" : "Map"

    // @ts-ignore
    let rows: { [key: string]: IDataTableRow } = {}
    Object.keys(props.data.data).forEach((key) => {

        let item: NewDataRow = props.data.data[key]

        let baseline = item.baseline;

        if (!baseline) {
            if (props.getBaselineData && props.baselineEloBracket) {
                const table_key = props.data.type === "civs_table" ? "civ" : props.data.type === "maps_table" ? "map_type" : undefined

                if (table_key) {

                    let itemKey = GetItemKeyForMetaData(item.meta_data);
                    if (itemKey) {
                        //TODO this is retarded, figure out why is float instead of int is saved in IconData
                        let itemKeyParsed = Math.floor(Number(itemKey)).toString()
                        let baselineData = props.getBaselineData(itemKeyParsed, table_key)

                        let eloBracketData = baselineData.find((b) => {
                            return b.label === props.baselineEloBracket
                        });

                        if (!eloBracketData) {
                            eloBracketData = baselineData.find((b) => b.label === "All");
                        }

                        if (eloBracketData && eloBracketData.value) {
                            baseline = {
                                n: eloBracketData.value.n,
                                prop: eloBracketData.value.prop,
                                win_rate: eloBracketData.value.win_rate,
                                // @ts-ignore
                                label: `*(all players in  ${eloBracketData.label} Elo Bracket)`
                            }

                            // For player stats:
                            //Special case, want win rate against civ not with, be carefully about changing label
                            //since atm there is no other way to tell which table is player civs which is against civs
                            if (props.data.label === "Civs Played Against") {
                                // @ts-ignore
                                baseline.win_rate = 1 - baseline.win_rate;
                            }

                            if (props.data.type === "maps_table") {
                                baseline.win_rate = -1
                            }


                        }
                    }
                }
            }
        }

        rows[key] = {
            id: key,
            values: {
                "name": {
                    value: GetDefaultMetaData(item.meta_data).name,
                    // @ts-ignore
                    icon: (item.meta_data && item.meta_data.hasOwnProperty("icon_data")) ? item.meta_data.icon_data : undefined

                },
                "n": {value: item.n},
                "prop": {
                    value: item.prop,
                    hideValue: true,
                    element: <StatsValueElement label={"Play Rate"} unit={"%"} dontShowProgress={true}
                                                value={item.prop}
                                                baseline={baseline?.prop}
                                                baselineLabel={baseline?.label}
                    />

                },
                "win_rate": {
                    value: item.win_rate,
                    hideValue: true,
                    element: <StatsValueElement label={"Win Rate"} unit={"%"} dontShowProgress={true}
                                                value={item.win_rate}
                                                baseline={baseline?.win_rate}
                                                baselineLabel={baseline?.label}
                    />

                },
                "estimated_elo": {
                    value: item.n > 10 ? estimatePlayerMapElo(props.player_elo, item.win_rate, item.n) : 0,
                    hideValue: true,
                    element: item.n > 10 ? <StatsValueElement label={"Est. Map Elo"} unit={undefined}
                                                              dontShowProgress={true}
                                                              value={Math.round(estimatePlayerMapElo(props.player_elo, item.win_rate, item.n))}
                                                              baseline={undefined}/> //TODO compare to player elo?
                        : <div/>


                },
            }
        }
    })
    let data: IDataTableProps = {
        serversideSort: props.serversideSort,
        data: {
            fields: {
                "name": {
                    key: "name",
                    label: props.data.type,
                    type: "icon"
                },
                "n": {
                    key: "n",
                    label: "Total Games",
                    type: "int"
                },
                "prop": {
                    key: "prop",
                    label: "Play Rate",
                    type: "prop"
                },
                "win_rate": {
                    key: "win_rate",
                    label: "Win Rate",
                    type: "prop"
                },
                "estimated_elo": {
                    key: "estimated_elo",
                    description: `Estimate of what your Elo should be so for you to have an expected average 50% win rate for a given ${type_label.toLowerCase()}. \n (Assuming you played all the matches with your current ELO and calculated using the FIDE Elo formula, so a very rough estimate) `,
                    label: `Est. ${type_label} Elo`,
                    // {/*label: <React.Fragment><span>Est. Map Elo  </span>*/}
                    // {/*    <Tooltip title="Estimate of what your Elo should be to have an average 50% for a given map/civ.*/}
                    // {/*    \n (Calculated using the FIDE Elo formula) ">*/}
                    // {/*        <React.Fragment>*/}
                    // {/*            <InfoOutlinedIcon/>*/}player_elo
                    // {/*        </React.Fragment>*/}
                    // {/*    </Tooltip>*/}

                    // </React.Fragment>,
                    type: "int"
                }

            },
            rows: rows
        }
    }

    if (Object.keys(data.data.rows).length < 1) {
        return <Box/>
    }

    return <DataTable
        resolveDataPromise={props.resolveDataPromise}

        useGlobalSelect={true}
        dataKey={props.dataKey}
        expanded={props.expanded}
        path={props.path}

        viewInfo={props.viewInfo}
        {...data}
        compactView={props.compactView}/>
}