// TODO retrieve from server based on 100.json
import {IconData, IconGroup, MetaData, MetadataDataset} from "../../Data/ModelGenerated";
import {Exception} from "sass";
import {END_POINTS_URL} from "../../Data/config";

var __cached_defines: MetadataDataset | undefined;

export function InitMetaDataResolverCached() {
    const prom = new Promise((resolve, reject) => {
        if (__cached_defines !== undefined) {
            resolve(true)
        } else {

            let url = `${END_POINTS_URL}/utils/get_metadata`
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    __cached_defines = data;
                    resolve(true)
                }).catch(() => reject())
        }
    })

    return prom
}

function _MetaDataResolver(group: IconGroup) {
    if (__cached_defines === undefined) {
        throw new Error(`Could not connect to API`)
    }
    const defines = __cached_defines as MetadataDataset
    if (group === "maps") {
        return defines.maps;
    } else if (group === "civilizations") {
        return defines.civs;
    }

    return undefined

}

export function MetaDataResolver(group: IconGroup, key: string | number) {

    const defines = _MetaDataResolver(group)

    if (defines !== undefined) {
        return defines[key];
    }

    throw new Error(`${group} IconGroup invalid for MetaDataResolver, only 'civilizations' and 'maps' supported`)
}

export function getMetaDataGroupItems(group: IconGroup) {
    return _MetaDataResolver(group)
}

export function getMetaDataForObject(group: IconGroup, key: string | number) {

    let name = MetaDataResolver(group, key)

    // @ts-ignore
    const iconData: IconData = {group: group, key: key, description: name}
    const metaData: MetaData = {icon_data: iconData, name: name}

    return metaData;

}