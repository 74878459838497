import {
    DescriptiveStatsTable, DescriptiveStatsTablePromise, MetaData,
    NewDataRow, NewDataTable,

    NewDataTableGroupWithSummary,
    PlayerMetaData, PlayerQuickStatsTable,
    SampleSummaryValue, TableBytesWrapper
} from "../../Data/ModelGenerated";
import {GridItem, GridItemCardContainer, GridItemContainer} from "../Grid/GridItem";
import * as React from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    Grid,
    IconButton,
    Popover,
    Skeleton,
    Slider, Stack,
    ToggleButton,
    Typography, useMediaQuery
} from "@mui/material";
import styles from "../Grid/GridItem.module.scss";
import {DefaultDataTableForNewDataTable, MapDataTableForPlayer} from "../Table/DataTable";
import {EGridOrDataTableOrChartRendererType, IPathObjectExtension} from "../DefaultDataRenderer";
import JSONTree from "react-json-tree";
import {useContext, useEffect, useState} from "react";
import {DataProviderContext} from "../../Internal/DataProvider";
import {Chart, Bar, Line} from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TableRowsIcon from '@mui/icons-material/TableRows';
import {LegendItem, Tooltip} from "chart.js";
import {DataProviderResponseMetaData, PlayerStatsViewQueryResponseMetaData} from "../../Data/Model";
import {PlayerStatsQueryJobInfoRenderer} from "./PlayerStatsQueryJobInfoRenderer";
import {DescriptiveStatsTableRenderer} from "../DescriptiveStats/DescriptiveStatsTableRenderer";
import {
    TableOrGridOrScatterPlotRenderer
} from "../Table/TableOrGridOrScatterPlotRenderer";
import {TBaselineTableGroupSampleDelegate} from "../../Data/BaselineProviders";
import {ScatterPlotBaselineProvider} from "../ObjectScatterPlot";
import {getEloBracketForElo} from "../../Data/CheckType";
import {PlayerSummaryStatsRenderer} from "../PlayerSummary/PlayerSummaryStatsRenderer";
import {useTheme} from "@mui/material/styles";
import {LoadingIndicator} from "../LoadingIndicator";
import {AppSettingsContext} from "../../App";
import {propToPercent} from "../../Utils";
import {IItemDetailsViewContainerProps, ItemDetailsViewContainer, ItemTableWrapper} from "./ItemDetailsViewContainer";
import {MedianGameDurationWidget} from "./MedianGameDurationWidget";

const pako = require('pako');

// @ts-ignore
Chart.register(annotationPlugin);

//register custome positioner
// @ts-ignore
Tooltip.positioners.side = function (items) {
    // @ts-ignore
    const pos = Tooltip.positioners.average(items);

    // Happens when nothing is found
    if (pos === false) {
        return false;
    }

    // @ts-ignore
    const chart = this._chart;

    let x = items[0].element.x;

    return {
        x: pos.x + 25,
        y: chart.chartArea.bottom - 50,
    };
}


export interface IItemDetailsViewsProps {
    data: NewDataTableGroupWithSummary
    metaData?: DataProviderResponseMetaData
    getBaselineData?: TBaselineTableGroupSampleDelegate

}

export interface IPatchHistoryChartProps {
    summary: { [k: string]: SampleSummaryValue }
    hideWinRate?: boolean
}

export function PatchHistoryChart(props: IPatchHistoryChartProps) {

    //TODO HACK simply do not generate reports for these
    const excludePatches = ['35584', '36202', '37650', '45185']

    const includeKeys = Object.keys(props.summary).filter(v => !excludePatches.includes(v));

    const data: { [p: string]: SampleSummaryValue } = {}

    for (const k of includeKeys) {
        data[k] = props.summary[k]
    }

    let themeCtx = useTheme() as any;


    let xVals = Object.keys(data).map(k => k)
    let yVal = Object.values(data).map((v) => v.win_rate);

    let noWinRate = props.hideWinRate ? true : yVal.find(v => v) === undefined;


    let yValPlayRate = Object.values(data).map((v) => v.play_rate)

    // let yValFormatted = yVal.map((v) => `${Math.round((v * 10000) / 100)}%`)

    const tickPercentCallback = (label: any, index: any, labels: any) => {
        return `${Math.round(Number(label) * 10000) / 100}%`
    }


    let datasets = [
        // @ts-ignore
        {
            type: 'line',
            borderColor: themeCtx.palette.values.plotLine,
            label: "Play Rate",
            data: yValPlayRate,
            yAxisID: 'y1',
        }]

    let y1Max = 0.12
    let annotations: any = {
        line1: {
            type: 'line',
            yMin: 0.5,
            yMax: 0.5,
            borderColor: 'grey',
            borderWidth: 2,
        }
    }

    if (!noWinRate) {
        // @ts-ignore
        datasets.push({
            type: 'bar',
            // @ts-ignore
            backgroundColor: "#ff0000",
            label: "Win Rate",
            data: yVal as number[],
            yAxisID: 'y',
        })
    } else {
        let maxVal = Math.max(...yValPlayRate);

        if (maxVal > 0.4) {
            y1Max = 1
        } else if (maxVal > 0.2) {
            y1Max = 0.5
        } else if (maxVal > 0.08) {
            y1Max = 0.25
        }

        delete annotations.line1
    }


    // @ts-ignore
    return <Bar data={{
        labels: xVals,
        // @ts-ignore
        datasets: datasets
        // datasets: [
        //     // @ts-ignore
        //     {type: 'line', borderColor: "#0038ff", label: "Play Rate", data: yValPlayRate, yAxisID: 'y1',},
        //     {type: 'bar', backgroundColor: "#ff0000", label: "Win Rate", data: yVal, yAxisID: 'y',},
        // ]
    }}
                options={{
                    interaction: {
                        mode: 'x'
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: "right",
                            align: 'start',
                            maxWidth: 55, maxHeight: 100,
                            display: false,
                            labels: {
                                generateLabels: function (chart) {
                                    let labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                                    let newLabels: LegendItem[] = []
                                    labels.forEach((l, i) => {

                                        newLabels.push(l)
                                        let n: LegendItem = {...l}
                                        newLabels.push(n)
                                        l.text = "";
                                        // labels[key].fillStyle = "rgba(133, 4, 12, 0.7)";
                                        // labels[key].strokeStyle = "rgba(33, 44, 22, 0.7)";
                                    });
                                    return newLabels;
                                }
                            }
                        },
                        // @ts-ignore
                        autocolors: false,
                        annotation: {
                            annotations: annotations
                        },
                        tooltip: {
                            // intersect: false,
                            mode: 'index',
                            callbacks: {
                                label: (tooltipItem) => {
                                    return `${tooltipItem.dataset.label} ${Math.round((Number(tooltipItem.formattedValue) * 100000)) / 1000}%`
                                    // return yValFormatted[tooltipItem.dataIndex]
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            display: !noWinRate,

                            ticks: {
                                callback: tickPercentCallback
                            },

                            min: 0, max: 1
                        },
                        y1: {
                            ticks: {
                                callback: tickPercentCallback
                            },

                            min: 0,
                            max: y1Max,
                            type: 'linear',
                            display: true,
                            position: 'right',

                            // grid line settings
                            grid: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                        x: {
                            ticks: {
                                autoSkip: false
                            }
                        }

                    }
                }}/>

    return <JSONTree data={{x: xVals, y: yVal}}/>
}

interface ICivPlayRateChartProps {
    defaultMark?: number
    label: string
    data: { [key: string]: NewDataTable }
}

const COLOR_BY_CIV = [
    "#808000",
    "#00FF00",
    "#008000",
    "#008080",
    "#FF00FF",
    "#800080",
    "red",
    "green",
    "blue",
    "cyan",
    "yellow",
    "brown",
    "olive",
    "purple",
    "Salmon",
    "#40E0D0",
    "#CCCCFF",
    "#DFFF00",
    "#E9967A",
    "#CD5C5C",
    "#F08080",
    "#884EA0",
    "#BA4A00",
    "#F8C471",
    "#7FB3D5",
    "#73C6B6",
    "#138D75",
    "#138D75",
    "#EC7063",
    "#85C1E9",
    "#85C1E9",
]

const getColorForCiv = (str: string) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;

}

function CivPlayRateChart(props: ICivPlayRateChartProps) {
    const settings = useContext(AppSettingsContext)

    //TODO make each civ have unique color
    const COLORS = ["red",
        "green",
        "blue",
        "cyan",
        "yellow",
        "brown",
        "olive",
        "purple",
        "Salmon",
        "#40E0D0",
        "#CCCCFF",
        "#DFFF00",
        "#E9967A",
        "#CD5C5C",
    ]


    const OTHER_COLOR = settings.getColorMode() === "dark" ? "rgba(243,243,243,0.38)" : "rgba(218,218,218,0.72)"

    const availableCountMarks = [6, 8, 12]

    const DATA_COUNT = 7;
    const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

    const [normalized, setNormalized] = useState(false)
    const [marksCount, setMarksCount] = useState(props.defaultMark ? props.defaultMark : 12)
    // const [showMarksSlider, setShowMarksSlider] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleSliderClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSliderClose = () => {
        setAnchorEl(null);
    };

    const showMarksSlider = Boolean(anchorEl);
    const anchorKey = showMarksSlider ? `simple-popover-${props.label}` : undefined;


    let totalCounterForEachGroup: { [group: string]: number } = {}
    let topCivsForEachMonth: (NewDataRow & { key: string })[] = []
    for (let period of Object.keys(props.data)) {

        let periodData = props.data[period]
        let data = periodData.data;

        let topItemForMonth = Object.keys(data).map(k => {
            return {...data[k], key: k}
        }).reduce((prev, current) => (prev.n > current.n) ? prev : current)
        topCivsForEachMonth.push(topItemForMonth)
        for (let rowKey of Object.keys(data)) {
            let row = data[rowKey]

            if (!totalCounterForEachGroup.hasOwnProperty(rowKey)) {
                totalCounterForEachGroup[rowKey] = 0
            }
            totalCounterForEachGroup[rowKey] += row.n
        }
    }

    topCivsForEachMonth.sort((a, b) => b.n - a.n)

    let groupsToInclude = Object.keys(totalCounterForEachGroup).sort((a, b) => totalCounterForEachGroup[b] - totalCounterForEachGroup[a])

    // let tempTest = groupsToInclude.map(key => [key, totalCounterForEachGroup[key]])
    groupsToInclude = groupsToInclude.slice(0, groupsToInclude.length > (marksCount - 3) ? (marksCount - 3) : -1)

    for (let maxCivForMonth of topCivsForEachMonth) {

        if (!groupsToInclude.includes(maxCivForMonth.key))
            groupsToInclude.push(maxCivForMonth.key)

        if (groupsToInclude.length >= marksCount)
            break;
    }

    let datasets: { [groupKey: string]: any } = {}

    let index = 0;

    const BORDER_COLOR_HIDE = "rgba(1,1,1,0)";

    [...groupsToInclude, "Other"].forEach((groupKey, groupIndex) => {

        let color: string;
        if (groupKey === "Other") {
            color = OTHER_COLOR
        } else {
            let colorIndex = groupIndex
            if (props.label.includes("Civs")) {
                color = getColorForCiv(groupKey)
            } else {
                color = COLORS[colorIndex]
            }
        }
        datasets[groupKey] = {
            tension: 0.55,
            // cubicInterpolationMode: 'monotone',
            label: groupKey,
            data: Array(Object.keys(props.data).length).fill(0),
            borderColor: BORDER_COLOR_HIDE,
            // borderColor: color,

            backgroundColor: color,
            fill: "origin"
        }
    })

    let x_vals = Object.keys(props.data).sort((a, b) => {


        let aSum = a.split("-").reverse().map((val, i) => parseInt(val) * ((i + 1) * Math.pow(10, i))).reduce((sum, a) => sum + a, 0);
        let bSum = b.split("-").reverse().map((val, i) => parseInt(val) * ((i + 1) * Math.pow(10, i))).reduce((sum, a) => sum + a, 0);

        return aSum - bSum;
    })


    for (let period of x_vals) {
        let periodData = props.data[period]
        let data = periodData.data;

        for (let rowKey of Object.keys(data)) {
            let row = data[rowKey]

            let groupIndex = groupsToInclude.indexOf(rowKey);
            // let color = groupIndex > -1 ? COLORS[groupIndex] : OTHER_COLOR

            let groupKey = groupIndex > -1 ? rowKey : "Other"//groupsToInclude.includes(rowKey) ? rowKey : "Other"
            datasets[groupKey].data[index] += row.n

            if (datasets[groupKey].data[index] === 0) {
                datasets[groupKey].data[index] = 7;
            }

        }
        index++;
    }

    if (normalized) {
        Object.keys(props.data).forEach((period, index) => {
            let periodSum = Object.values(datasets).map(ds => ds.data[index]).reduce((a, b) => a + b, 0);

            for (let dsKet of Object.keys(datasets)) {
                datasets[dsKet].data[index] = datasets[dsKet].data[index] / periodSum
            }

        })
    }

    //Prune empty

    // return <JSONTree data={datasets}/>
    // return <JSONTree data={props.data}/>
    // const labels = [1, 2, 3];
    const data = {
        labels: x_vals,//Object.keys(props.data),
        // datasets: x_vals.map((k) => datasets[k])//Object.values(datasets)
        datasets: Object.values(datasets)
    };
    const config = {
        type: 'line',
        data: data,
        options: {
            interaction: {
                intersect: false,
                mode: 'index',
            },
            elements: {
                point: {
                    radius: 1.75
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },

                title: {
                    display: false,
                },
                tooltip: {
                    position: "average",
                    // position: "side",
                    mode: 'index',
                    callbacks: {
                        label: (tooltipItem: any) => {

                            if (tooltipItem.formattedValue === "0") {
                                return undefined;
                            }

                            if (normalized) {
                                return `${tooltipItem.dataset.label} ${Math.round((Number(tooltipItem.formattedValue) * 100000)) / 1000}%`
                            } else {
                                return ` ${tooltipItem.dataset.label} ${tooltipItem.formattedValue}`
                            }
                        }
                    }


                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Month'
                    }
                },
                y: {
                    min: 0, max: normalized ? 1 : undefined,
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Play Count'
                    },
                    ticks: {
                        callback: function (label: any, index: any, labels: any) {
                            return normalized ? `${Math.round(Number(label) * 100)}%` : `  ${label}  `
                        }
                    }
                }
            }
        }
    };

    let controlsButtonSx = {
        paddingLeft: "11px",
        paddingRight: "11px",
        paddingTop: "11px",
        paddingBottom: "11px",
        height: "20px",
        width: "25px",
        maxWidth: "20px",
        minWidth: "20px"
    }
    // @ts-ignore
    let chart = <Line {...config}/>
    return <React.Fragment>
        <GridItemContainer size={{xs: 12, sm: 12, md: 12, lg: 12, xl: 6}}
                           onSelectItem={undefined}
                           sx={{maxHeight: "280px", height: "280px"}}
                           content={<React.Fragment>
                               <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                   <Typography variant={"subtitle2"}>{props.label}</Typography>

                                   <Box sx={{display: "flex", justifyContent: "space-between", width: "60px"}}>
                                       <ToggleButton
                                           // variant="outlined"
                                           aria-describedby={anchorKey}
                                           // variant="contained"
                                           color={"secondary"}
                                           sx={controlsButtonSx}
                                           // value="check"
                                           // selected={normalized}
                                           onClick={(ev, value) => handleSliderClick(ev)}
                                           value='check'
                                           selected={showMarksSlider}
                                       >
                                           <TableRowsIcon sx={{fontSize: "16px"}}/>
                                       </ToggleButton>
                                       <Popover
                                           id={anchorKey}
                                           open={showMarksSlider}
                                           anchorEl={anchorEl}
                                           onClose={handleSliderClose}
                                           anchorOrigin={{
                                               vertical: 'bottom',
                                               horizontal: 'left',
                                           }}
                                       >
                                           <Box sx={{width: "220px", margin: "10px"}}>
                                               Number of Groups
                                               <Slider
                                                   size={"small"}
                                                   min={5}
                                                   max={18}
                                                   onChange={(ev, d) => {
                                                       // @ts-ignore
                                                       setMarksCount(d)
                                                   }}
                                                   aria-label="Restricted values"
                                                   defaultValue={marksCount}
                                                   // valueLabelFormat={valueLabelFormat}
                                                   // getAriaValueText={valuetext}
                                                   step={1}
                                                   valueLabelDisplay="auto"
                                                   marks={availableCountMarks.map((v) => {
                                                       return {value: v, label: v}
                                                   })}
                                               />
                                           </Box>

                                       </Popover>

                                       <ToggleButton
                                           sx={controlsButtonSx}
                                           value="check"
                                           selected={normalized}
                                           onChange={() => {
                                               setNormalized(!normalized);
                                           }}
                                       >
                                           <EqualizerIcon sx={{fontSize: "16px"}}/>
                                       </ToggleButton>
                                   </Box>

                               </Box>

                               <Box sx={{
                                   maxHeight: "280px",
                                   height: "280px"
                               }}>
                                   {chart}
                               </Box>

                           </React.Fragment>}/>

        {/*<JSONTree data={props}/>*/}
    </React.Fragment>

    // return <JSONTree data={props.data}/>

}

export interface ItemDetailsViewBaselineProvider {
    getBaselineData: TBaselineTableGroupSampleDelegate
    key: string
    label: string

}

// Used by the KDE time dist table
export function DescriptiveStatsTableWrapper(props: { t: DescriptiveStatsTable | TableBytesWrapper }) {

    const {t} = {...props};
    let table: JSX.Element | undefined = <Box/>;
    if (t.hasOwnProperty('bytes') && t.hasOwnProperty('type') && t["type"] === "DescriptiveStatsTable") {

        let compressedTable = t as TableBytesWrapper


        let bytes = atob(compressedTable.bytes)
        // let bts = atob(bytes)
        let bts = bytes;//bytes.slice(2, bytes.length - 1)

        let unintBytes = Uint8Array.from(bts, c => c.charCodeAt(0))

        // let blob = new Blob([bytes])

        let rawStr = pako.inflate(unintBytes, {to: 'string'});
        let uncompressedTable = JSON.parse(rawStr) as DescriptiveStatsTable;

        table = <div>
            <DescriptiveStatsTableRenderer table={uncompressedTable}/>
        </div>
    } else if (t.hasOwnProperty('data_views')) {
        table = <div>
            <DescriptiveStatsTableRenderer table={t as DescriptiveStatsTable}/>
        </div>
    }

    return table
}

export  type TTablePromiseState = { key: string, data: undefined | TableBytesWrapper }

export function ItemDetailsView(props: IItemDetailsViewsProps & IPathObjectExtension) {
    const useDesktopLayout = useMediaQuery('(min-width:600px)');


    // let baselineDataProviders: ItemDetailsViewBaselineProvider[] | undefined = props.getBaselineData ? [{
    //     label: "TEST",
    //     key: "elo",
    //     getBaselineData: props.getBaselineData
    // }] : []


    const getTablePromises: () => TTablePromiseState[] = () => {

        if (props.data.tables) {
            const tables = props.data.tables;
            let items = Object.keys(props.data.tables).map(k => {
                let table = tables[k];

                if (table.type === "DescriptiveStatsTablePromise") {
                    return {
                        key: k,
                        data: undefined
                    }
                }
            }).filter(i => i !== undefined) as TTablePromiseState[];

            return items;
        }
        return []
    }

    const [dataPromiseStates, _setDataPromiseStats] = useState(getTablePromises())

    useEffect(() => {


        for (let promiseState of dataPromiseStates) {
            if (promiseState.data === undefined && props.data.tables && props.resolveDataPromise) {
                let promiseData = props.data.tables[promiseState.key] as DescriptiveStatsTablePromise;
                props.resolveDataPromise(promiseData).then(r => {
                    if (r) {
                        let items = [...dataPromiseStates]
                        let index = items.findIndex(item => item.key === promiseState.key);
                        if (index !== -1) {
                            // @ts-ignore
                            items[index].data = r
                            _setDataPromiseStats(items)
                        }
                    }
                }).catch((ex) => {
                    throw ex;
                })
                // resolveDataPromise()

            }
        }


    }, [])


    const summary = props.data.summary
    // summary = summary && Object.keys(summary).length > 0 ? summary : undefined;

    let summaryCard: JSX.Element | undefined;


    if (summary) {
        const summaryTitle = Object.values(summary).find(v => v.win_rate !== null) ? "Win Rate by Patch Version" : "Play Rate by Patch Version"
        // const summaryTitle = summary. "Win Rate by Game Version" : "Popularity Rate by Game Version"
        summaryCard = <GridItemContainer size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 7}}
                                         onSelectItem={undefined}

                                         content={<React.Fragment><Typography
                                             variant={"subtitle2"}>{summaryTitle}</Typography>
                                             <Box sx={{maxHeight: "165px", height: "165px"}}>
                                                 <PatchHistoryChart summary={summary}/>
                                             </Box>
                                         </React.Fragment>}/>
    } else {
        summaryCard = undefined;
        // <Grid item {...{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
        //     <Card><Container><Stack><Typography>BRING BACK SUMMARY CARD</Typography></Stack></Container></Card>
        // </Grid>
    }

    let additionalSummaryCards: JSX.Element[] = [];

    let metaDataView: JSX.Element | undefined;
    let viewBeingGenerated = false;
    let tall = false;


    let item_key: string = props.path.value.join("-")
    const item_render_key = item_key
    // const item_render_key = window.btoa(unescape(encodeURIComponent(`${props.dataKey}-${item_key}`)));


    //Is Meta data for player stats view query info?
    if (props.metaData?.hasOwnProperty('scheduled_or_running_job')) {
        let data = props.metaData as PlayerStatsViewQueryResponseMetaData


        viewBeingGenerated = !(data.last_finished_job && data.last_finished_job.status === 10)

        metaDataView =
            <PlayerStatsQueryJobInfoRenderer
                key={item_render_key}
                inline={false}
                data={data} meta_data={props.data.meta_data as PlayerMetaData} table={"TODO"}/>
    } else {

        metaDataView = <Box height={"15x"}/>

    }

    let largerNameLabel = true;

    if (props.path.value.includes("stats_views__players")) {
        tall = true;
        largerNameLabel = false;


        if (props.data.tables && props.data.tables.hasOwnProperty("player_stats_quick_summary")) {
            let playerMeta = props.data.meta_data as PlayerMetaData

            let playerSummaryCard = <GridItemContainer tall={tall} size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}
                                                       key={item_render_key}
                                                       content={<PlayerSummaryStatsRenderer
                                                           key={item_render_key}
                                                           data={props.data.tables["player_stats_quick_summary"] as PlayerQuickStatsTable}/>}/>

            additionalSummaryCards.push(playerSummaryCard)
        }
    }
    // PlayerSummaryStatsRenderer


    if (props.data.tables) {
        for (let tableKey of Object.keys(props.data.tables)) {

            let tableOrStatsView = props.data.tables[tableKey];

            if (tableOrStatsView.hasOwnProperty('additional_tables')) {

                let table = tableOrStatsView as NewDataTable;

                let label = tableKey === "civ" ? "Most Played Civilizations, by Month" : "Most Played Maps, by Month"
                if (table.additional_tables) {
                    for (let additionalTableCollectionKey of Object.keys(table.additional_tables)) {
                        let additionalTableCollectionOrMissingValue = table.additional_tables[additionalTableCollectionKey];

                        // TODO for now entire chart will be hidden if any civ/map is MissingValue, FIX
                        // if (Object.values(additionalTableCollectionOrMissingValue).find(t => t.hasOwnProperty('reason') === undefined)) {
                        let additionalTableCollection = additionalTableCollectionOrMissingValue as { [key: string]: NewDataTable };
                        // for (let addTableKey of Object.keys(additionalTableCollection)) {
                        //     let additionalTable = additionalTableCollection[addTableKey]


                        additionalSummaryCards.push(
                            <CivPlayRateChart
                                key={item_render_key}
                                defaultMark={tableKey === "civ" ? 10 : 6}
                                label={label}
                                data={additionalTableCollection}/>
                        )
                        // }
                        // }

                    }
                }
            }
        }
    }
    // if (props.data.tables)


    // @ts-ignore

    const tableViews = Object.values(props.data.tables).map((t, ii) => {
        // MapDataTableForPlayer

        if (t.type === "DescriptiveStatsTablePromise") {
            return <Box/>;
        } else if (props.data.tables !== undefined) {

            let dataTables = props.data.tables;
            let table: JSX.Element | undefined;

            if (t.hasOwnProperty('data')) {
                let dataTable = t as NewDataTable

                if (props.path.value.includes("stats_views__players")) {
                    let playerMeta = props.data.meta_data as PlayerMetaData

                    if (t.type === "player_stats_quick_summary") {
                        return undefined;
                    } else if (t.type === "maps_table") {

                        table = <MapDataTableForPlayer
                            key={item_render_key}
                            dataKey={props.dataKey}

                            baselineEloBracket={getEloBracketForElo(playerMeta.latest_elo)}
                            player_elo={playerMeta.latest_elo}
                            getBaselineData={props.getBaselineData}
                            expanded={ii === Object.values(dataTables).length - 1}
                            path={props.path}
                            resolveDataPromise={props.resolveDataPromise}
                            viewInfo={{title: dataTable.label}} data={dataTable}/>


                    } else {
                        table = <MapDataTableForPlayer
                            key={item_render_key}
                            baselineEloBracket={getEloBracketForElo(playerMeta.latest_elo)}
                            dataKey={props.dataKey}

                            getBaselineData={props.getBaselineData}
                            player_elo={playerMeta.latest_elo}
                            expanded={ii === Object.values(dataTables).length - 1}
                            path={props.path}
                            resolveDataPromise={props.resolveDataPromise}

                            viewInfo={{title: dataTable.label}} data={dataTable}/>

                    }
                } else {

                    let culledDataTable: NewDataTable = {...dataTable}

                    let n = 0
                    let rows = Object.keys(culledDataTable.data)
                    let culledRows: { [p: string]: NewDataRow } = {}
                    for (let key of rows) {
                        let row = culledDataTable.data[key]
                        if (row.n >= 0) {
                            culledRows[key] = row;
                            n += row.n
                        }
                    }

                    culledDataTable.n = n
                    culledDataTable.data = culledRows;

                    table = <TableOrGridOrScatterPlotRenderer
                        supportedTypes={EGridOrDataTableOrChartRendererType.Table}
                        // | EGridOrDataTableOrChartRendererType.Scatter}
                        key={item_render_key}
                        resolveDataPromise={props.resolveDataPromise}
                        dataKey={props.dataKey}
                        expanded={ii === Object.values(dataTables).length - 1}
                        path={props.path}
                        viewInfo={{title: dataTable.type, nMin: 100}}
                        data={culledDataTable}
                        showProp0={true}
                    />
                    // table = <Box>WOPWOP</Box>
                }
            } else {
                // @ts-ignore
                table = <DescriptiveStatsTableWrapper key={item_render_key} t={t}/>
            }
            return <ItemTableWrapper table={table} item_render_key={item_render_key}/>
        }

        return undefined;
        // return <Box>
        //     123
        //     <JSONTree data={t}/>
        //     456
        // </Box>
    })


    if (props.data.duration) {
        // tableViews.unshift(<Box>
        //     <MedianGameDurationWidget data={props.data.duration}/>
        // </Box>)
    }

    let containerProps: IItemDetailsViewContainerProps = {
        additionalSummaryCards: additionalSummaryCards,
        data: props.data,
        dataPromiseStates: dataPromiseStates,
        item_render_key: item_render_key,
        largerNameLabel: largerNameLabel,
        metaDataView: metaDataView,
        summaryCard: summaryCard,
        tableViews: tableViews,
        tall: tall,
        useDesktopLayout: useDesktopLayout,
        viewBeingGenerated: viewBeingGenerated
    }

    return <ItemDetailsViewContainer {...containerProps}/>
}