import * as React from 'react';

import {
    GameDurationDescriptiveDataCollection,
    IconData,
    MetaData,
    NewDataRow,
    NewDataTable,
    NewDataTableGroupWithSummary,
    PlayerMetaData
} from "../../Data/ModelGenerated";
// import {StatsValueElement} from "../../../../WebApp/src/Components/Other/StatsValueElement";
import {
    Box,
    Card, CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    GridSize,
    makeStyles,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {GetDefaultMetaData, propToPercent} from "../../Utils";
import {ObjectIcon} from "../../UI/ObjectIcon";
import {DataEntry, MatchSample} from "../../../../WebApp/src/Data/StatsViewTypes";
import {ISummaryStat, StatsValueElement} from "../../UI/StatsValueElement";
import {DataItemType} from "../../Data/CheckType";
import styles from "./GridItem.module.scss"
import {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {DataProviderContext} from "../../Internal/DataProvider";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {DerivedCivOverviewTeam} from "../DerivedStatsComponents/DerivedCivOverviewTeam";
import {MedianGameDurationWidget} from "../ItemDetails/MedianGameDurationWidget";

export interface IGridItemProps {

    data: NewDataTableGroupWithSummary
    size?: TGridItemSize
    onSelectItem?: () => void
    independent?: boolean
    vertical?: boolean
    headerItems?: JSX.Element;
    overrideType?: DataItemType
    tall?: boolean
    altBgColor?: boolean
    largerNameLabel?: boolean
    derivedStats?: JSX.Element
    concise?: boolean

    maxWidth?: number
}

export type TGridItemSize = { xs: GridSize, sm: GridSize, md: GridSize, lg: GridSize, xl: GridSize }

const getValueFieldForEntry = (target: NewDataRow, key?: "prop" | "win_rate" | "n") => {
    // let val: number = 0.4242;
    let _key = key === undefined ? "win_rate" : key
    return target[_key]
}


const buildChildDataItems = (data: NewDataTableGroupWithSummary) => {
    let childDataItems: [string, NewDataRow][] = [];
    if (!data.hasOwnProperty("lazy")) {
        if (data.tables) {
            let mainTable = data.tables["strategies_table"] as NewDataTable;
            if (mainTable === undefined)
                mainTable = data.tables["civs_table"] as NewDataTable;

            if (mainTable === undefined)
                mainTable = data.tables[Object.keys(data.tables)[0]] as NewDataTable


            if (mainTable !== undefined) {
                childDataItems = Object.keys(mainTable.data).map(function (key) {
                    return [key, mainTable.data[key]];
                });
            }
        }
    }
    childDataItems.sort((a: any, b: any) => getValueFieldForEntry(b[1]) - getValueFieldForEntry(a[1]))
    return childDataItems
}
const generateSummaryStatsRows = (type: DataItemType, item: NewDataTableGroupWithSummary, baseline?: number) => {
    let summaryStats: ISummaryStat[] = []


    if (type === "civ" || type === "map_type_nested") {
        summaryStats.push({
            label: "Win Rate",
            value: item.win_rate,
            unit: "%",
            baseline: 0.5,
            baselineMagnitude: 0.1
        })
        summaryStats.unshift({
            label: "Play Rate",
            value: item.prop, // propToPercent(item.prop),
            unit: "%",
            // baseline: 1 / Object.keys(data.tableGroups).length, TODO
            baselineMagnitude: 0.5
        })


    } else if (type === "map_type") {
        summaryStats.push({
            label: "Play Rate",
            value: item.prop,// propToPercent(item.prop),
            unit: "%",
            // baseline: 1 / Object.keys(data.tableGroups).length,
            baselineMagnitude: 0.2
        })

    } else if (type === "profile_id") {
        let meta = item.meta_data as PlayerMetaData
        summaryStats.push({
            label: "Latest Elo",
            value: meta.latest_elo,
            unit: undefined,
            total: [0, 3000]
        })

        summaryStats.push({
            label: "Win Rate",
            value: item.win_rate,
            unit: "%",
            baseline: 0.5
        })


    } else if (type === "rating_bin") {

    }

    summaryStats.push({
        label: "Total Games",
        value: item.n,
        unit: ""
    })
    return summaryStats
}


export function GridItemCardContainer(props: {
    altBgColor?: boolean
    size?: TGridItemSize
    onSelectItem?: any,
    content: JSX.Element
    sx?: any
    vertical?: boolean
    tall?: boolean
}) {
    let cardContent = <Card


        // sx={{backgroundColor: props.altBgColor ? "blue" : undefined}}
        raised={props.altBgColor}
        className={`
            ${styles.card} 
            ${props.tall ? styles.tall : ""} 
            ${props.vertical ? styles.vertical : styles.horizontal} 
            ${"props.extended" ? "styles.wide" : ""}`}
    >


        {props.content}

    </Card>
    return cardContent
}

interface IGridItemInternalWrapperProps {

    size?: TGridItemSize
    content: JSX.Element
    cardActionContent?: JSX.Element
    tall?: boolean

    isGrid?: boolean

    onSelectItem?: () => any

    maxWidth?: number
}

const GridItemInternalWrapper = (props: IGridItemInternalWrapperProps) => {

    const minWidth = 440;
    const maxWidth = 440;
    let height = 195;
    let minHeight = 195;
    if (props.tall) {
        height = 195;
    } else if (props.cardActionContent === undefined) {
        height = 155;
        minHeight = 155;
    }
    return <Grid item {...props.size}>
        <Card sx={{
            minHeight: minHeight,
            height: height,
            minWidth: props.isGrid ? minWidth : undefined,
            maxWidth: props.maxWidth ? props.maxWidth : props.isGrid ? maxWidth : undefined,
            cursor: props.onSelectItem ? "pointer" : undefined
        }}
              onClick={props.onSelectItem ? () => {
                  props.onSelectItem && props.onSelectItem()
              } : undefined}
        >
            <CardContent
                sx={{padding: "8px", paddingBottom: "8px", height: props.cardActionContent ? "75.5%" : "100%"}}>

                {props.content}

                {/*<Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>*/}
                {/*    Word of the Day*/}
                {/*</Typography>*/}
                {/*<Typography variant="h5" component="div">*/}
                {/*    Hello World*/}
                {/*</Typography>*/}
                {/*<Typography sx={{mb: 1.5}} color="text.secondary">*/}
                {/*    adjective*/}
                {/*</Typography>*/}
                {/*<Typography variant="body2">*/}
                {/*    {JSON.stringify(props.size)}*/}
                {/*</Typography>*/}
            </CardContent>
            {props.cardActionContent && <CardActions>
                {props.cardActionContent}
            </CardActions>}
        </Card>

    </Grid>

}

export function GridItemContainer(props: {
    altBgColor?: boolean
    size?: TGridItemSize
    onSelectItem?: any,
    content: JSX.Element
    sx?: any
    tall?: boolean
}) {

    let cardContent = props.content;
    // {...size}

    return <GridItemInternalWrapper
        size={props.size}
        content={cardContent}
        tall={props.tall}
        cardActionContent={<Box/>}
    />
    // return <Grid item
    //              xs={4}
    //              sx={props.sx}
    //              className={`${styles.container} ${props.tall ? styles.tall : ""} ${props.onSelectItem ? styles.clickable : ""}`}
    //
    //              onClick={props.onSelectItem}>
    //     <Box></Box>
    //     {cardContent}
    // </Grid>

}


export function GridItem(props: IGridItemProps) {

    const extendedStatsViewRef = useRef<HTMLHeadingElement>(null);

    const getItemCountForContainerWidth = (w: number) => {
        // 740 - 10
        // 465 - 6
        // 320 - 4
        if (props.vertical) {
            // 305 - 7
            // 205 - 5
            return Math.floor(0.02 * w + 0.9)
        } else {
            return Math.floor(0.013793103448276 * w - 0.41379310344828)
        }
    }

    const [bestItemsCount, setBestItemsCount] = useState(5);


    // const clientWidth = (extendedStatsViewRef && extendedStatsViewRef.current) ? extendedStatsViewRef.current.clientWidth : 0

    useEffect(() => {
        const handleResize = () => {
            const w = extendedStatsViewRef?.current?.clientWidth
            if (w !== undefined && w !== null) {
                setBestItemsCount(getItemCountForContainerWidth(w))
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [props.vertical]); // Empty array ensures that effect is only run on mount


    // useLayoutEffect(() => {
    //     const w = extendedStatsViewRef?.current?.clientWidth
    //     if (w !== undefined)
    //         setBestItemsCount(getItemCountForContainerWidth(w))
    //
    // }, [clientWidth])

    let itemNameLabel: string = props.data.meta_data ? props.data.meta_data.name : "Name Not Found!";
    let itemLabelSubtext = undefined;//"a.k.a placeholder";
    if (props.data.meta_data && props.data.meta_data.hasOwnProperty("profile_id")) {
        let playerMeta = props.data.meta_data as PlayerMetaData;

        let availableNames = playerMeta.names.filter(n => n !== playerMeta.name)
        if (availableNames.length > 0) {
            itemLabelSubtext = `a.k.a ${availableNames.join(", ")}`
        }
    }


    let summaryStats: ISummaryStat[] = [];

    if (props.data.meta_data) {
        // if (props.data.meta_data.hasOwnProperty("desc")) {
        let type: DataItemType

        if (props.overrideType) {
            type = props.overrideType;
        } else {
            if (props.data.meta_data.hasOwnProperty("desc")) {
                let metaData = props.data.meta_data as MetaData
                if (metaData.desc)
                    type = metaData.desc.group_name as DataItemType;
            } else {
                let playerMetaData = props.data.meta_data as PlayerMetaData
                type = "profile_id"
            }
        }

        // @ts-ignore
        if (type === "civilizations") {
            type = "civ"
        }

        summaryStats = generateSummaryStatsRows(type, props.data)
        // }
    }

    let itemLabel = <React.Fragment>
        <Tooltip title={itemNameLabel} className={styles.infoIcon}>
            <InfoOutlinedIcon fontSize="small"/>
        </Tooltip>

        <div className={styles.headerContainer}>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "95%"}}>
                <Typography
                    fontSize={props.largerNameLabel ? "1.25rem" : undefined}
                    className={styles.header}>{itemNameLabel}</Typography>
                {itemLabelSubtext ? <Typography fontSize={"12px"}>{itemLabelSubtext}</Typography> :
                    <Box height={18}/>}
            </Stack>
        </div>
    </React.Fragment>


    const buildObjectIcon = (key: string, data: NewDataRow, size?: number) => {
        let tooltip = `${key}, Win Rate : ${propToPercent(getValueFieldForEntry(data))}%, Games: ${getValueFieldForEntry(data, "n")}`

        const md = GetDefaultMetaData(data.meta_data)
        if (md.icon_data) {
            return <ObjectIcon borderless
                               tooltip={{show: true, value: tooltip}}
                               data={md.icon_data}
                               size={size ? size : 18}/>
        } else return <div><span>{md.name}</span></div>
    }


    let extendedStatsView: JSX.Element | undefined;
    if (true) {
        let staticSample = props.data;


        let childDataItems = buildChildDataItems(staticSample)

        if (childDataItems.length > 0) {
            let childDataItemsFiltered = childDataItems.filter((d) => d[1].n > 100)
            let mostPopularCivs = childDataItemsFiltered.slice(0, Math.min(childDataItemsFiltered.length - 1, bestItemsCount)).map((d) => buildObjectIcon(d[0], d[1], props.vertical ? 32 : 22))
            let leastPopularCivs = childDataItemsFiltered.slice(Math.max(childDataItemsFiltered.length - bestItemsCount, 0)).map((d: [string, any]) => buildObjectIcon(d[0], d[1], props.vertical ? 32 : 22))

            // @ts-ignore
            let type_label = ""

            let item_desc = ""
            if (props.data.meta_data && props.data.tables) {
                type_label = props.data.tables["civ"] !== undefined ? "Civs" : "Maps"

                if (props.data.meta_data.hasOwnProperty("highest_elo")) {
                    item_desc = ` played by ${props.data.meta_data.name} (in at least 5 games)`
                } else {
                    let meta = props.data.meta_data as MetaData

                    if (type_label === "Civs") {
                        item_desc = ` on ${meta.name}`
                    } else {
                        item_desc = ` for ${meta.name}`
                    }
                }
            }

            let showBestWorstCivs = mostPopularCivs.length > 0 || leastPopularCivs.length > 0;
            // if (!showBestWorstCivs){
            //     extendedStatsView = <Box/>
            // }
            if (props.vertical) {

                // extendedStatsView = <Stack>
                //     <Stack ref={extendedStatsViewRef} sx={{visibility: showBestWorstCivs ? "visible" : "collapse"}}>
                //         <Stack marginTop={"10px"} spacing={0}>
                //             <Stack direction={"row"} justifyContent={"space-between"}>
                //
                //                 <Typography>
                //                     Best {type_label}
                //                 </Typography>
                //                 <KeyboardDoubleArrowUpIcon style={{color: "forestgreen", transform: "scale(-1, 1)"}}
                //                                            fontSize={"small"}/>
                //
                //             </Stack>
                //             <Stack direction={"row"} justifyContent={"start"} spacing={1}>
                //                 {mostPopularCivs}
                //             </Stack>
                //         </Stack>
                //
                //         <Stack marginTop={"10px"} spacing={0}>
                //             <Stack direction={"row"} justifyContent={"space-between"}>
                //                 <Typography>
                //                     Worst {type_label}
                //                 </Typography>
                //                 <KeyboardDoubleArrowDownIcon style={{color: "red", transform: "scale(-1, 1)"}}
                //                                              fontSize={"small"}/>
                //             </Stack>
                //             <Stack direction={"row"} justifyContent={"start"} spacing={1}>
                //                 {leastPopularCivs}
                //             </Stack>
                //         </Stack>
                //     </Stack>
                // </Stack>
            } else {

                // extendedStatsView = props.derivedStats

                // extendedStatsView = <div className={styles.civsListContainer} ref={extendedStatsViewRef}
                //                          style={{"visibility": showBestWorstCivs ? "visible" : "collapse"}}>
                //     <div className={styles.civsList} style={{"justifyContent": "flex-end"}}>
                //         {mostPopularCivs}
                //     </div>
                //
                //     <div className={styles.civsListContainerLabelContainer}>
                //         {/*<div>Most</div>*/}
                //         {/*<div>*/}
                //         <DoubleArrowRoundedIcon style={{color: "forestgreen", transform: "scale(-1, 1)"}}
                //                                 fontSize={"small"}/>
                //         {/*</div>*/}
                //         <div className={styles.civListTable}>
                //             {/*<div>*/}
                //
                //             <Tooltip title={`${type_label} with the highest/lowest win rate ${item_desc}`}>
                //                 {/*<React.Fragment>*/}
                //                 <div className={styles.tooltip}>
                //                     <div>Best</div>
                //                     {/*</div>*/}
                //                     {/*<div>*/}
                //                     <div>{type_label}</div>
                //                 </div>
                //                 {/*</React.Fragment>*/}
                //             </Tooltip>
                //             {/*</div>*/}
                //             {/*<div>{"TODO"}</div>*/}
                //         </div>
                //
                //         {/*<div>*/}
                //         <DoubleArrowRoundedIcon style={{color: "red"}} fontSize={"small"}/>
                //         {/*</div>*/}
                //         {/*<div>Least</div>*/}
                //     </div>
                //     <div className={styles.civsList} style={{"justifyContent": "flex-start"}}>
                //         {leastPopularCivs}
                //
                //     </div>
                // </div>
            }
            // TODO: Disable for now and replace with Derived Stats Tags
            // extendedStatsView = <div className={styles.civsListContainer}>
            //     <div className={styles.civsList}>
            //         {mostPopularCivs}
            //     </div>
            //
            //     <div className={styles.civsListContainerLabelContainer}>
            //         {/*<div>Most</div>*/}
            //         {/*<div>*/}
            //         <DoubleArrowRoundedIcon style={{color: "forestgreen", transform: "scale(-1, 1)"}}
            //                                 fontSize={"small"}/>
            //         {/*</div>*/}
            //         <div className={styles.civListTable}>
            //             {/*<div>*/}
            //
            //             <Tooltip title={`${type_label} with the highest win rate ${item_desc}`}>
            //                 {/*<React.Fragment>*/}
            //                 <div className={styles.tooltip}>
            //                     <div>Best</div>
            //                     {/*</div>*/}
            //                     {/*<div>*/}
            //                     <div>{type_label}</div>
            //                 </div>
            //                 {/*</React.Fragment>*/}
            //             </Tooltip>
            //             {/*</div>*/}
            //             {/*<div>{"TODO"}</div>*/}
            //         </div>
            //
            //         {/*<div>*/}
            //         <DoubleArrowRoundedIcon style={{color: "red"}} fontSize={"small"}/>
            //         {/*</div>*/}
            //         {/*<div>Least</div>*/}
            //     </div>
            //     <div className={styles.civsList}>
            //         {leastPopularCivs}
            //
            //     </div>
            // </div>
        }
    }
    let iconData: IconData | { country: string } | undefined;

    if (props.data.meta_data && props.data.meta_data.hasOwnProperty("icon_data")) {
        // @ts-ignore
        iconData = props.data.meta_data.icon_data
    } else {
        let meta = props.data.meta_data as PlayerMetaData
        // if (meta.country)
        //     iconData = {country: meta.country}
    }

    let cardContent: JSX.Element;
    let cardActionContent: JSX.Element | undefined;

    const summaryStatsRows = summaryStats.map((stat) => {
        return <tr>
            <td>{stat.label}</td>
            <td><StatsValueElement {...stat}/></td>
        </tr>

    })

    if (props.data.duration && props.data.duration.win_data) {
        const s: ISummaryStat = {
            label: "",
            unit: "time",
            value: props.data.duration.win_data.m,
            baseline: 37.597,
        }

        summaryStatsRows.splice(summaryStatsRows.length - 1, 0, <tr>
            <td>{"Avg. Duration"}</td>
            <td>
                {/*<Box sx={{*/}
                {/*    maxWidth: 165,*/}
                {/*    maxHeight: 45,*/}
                {/*    display: "flex",*/}
                {/*    justifyContent: "space-around"*/}
                {/*}}>*/}
                <StatsValueElement {...s}/>
                {/*<MedianGameDurationWidget data={props.data.duration} type={"inline"}/>*/}
                {/*</Box>*/}
            </td>
        </tr>)
    }

    if (props.vertical) {
        let dataContent = <React.Fragment>

            <Stack direction={"column"}>
                {summaryStats.map((stat) => {
                    return <Box display={"flex"} alignItems={"center"} sx={{flexFlow: "row wrap"}}>
                        <Typography minWidth={"90px"}
                                    variant={"subtitle2"}>{stat.label}</Typography>
                        <Box minWidth={"80px"}><StatsValueElement {...stat} /></Box>
                    </Box>

                })}
            </Stack>
        </React.Fragment>

        cardContent = <Stack justifyContent={"space-between"}>
            <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                <Typography variant={"h5"}>{itemNameLabel}</Typography>

            </Stack>
            <CardContent sx={{padding: "4px"}}>

                <Stack direction={"row"} spacing={2}>
                    <Stack justifyContent={"flex-start"}>
                        <CardMedia className={styles.cardMedia}>

                            <div style={{"width": 100, height: 100}}>
                                {iconData && <ObjectIcon size={100}
                                                         data={iconData}/>}
                                {!iconData && <HelpOutlineIcon sx={{fontSize: 100}}/>}
                            </div>

                        </CardMedia>
                    </Stack>
                    {dataContent}
                </Stack>
                <Stack>
                    {extendedStatsView}
                </Stack>
            </CardContent>

            {props.headerItems && <CardActions>
                {/*<Stack direction={"row"}>*/}
                {props.headerItems}
                {/*</Stack>*/}
            </CardActions>}

        </Stack>
    } else {
        let dataContent = <React.Fragment>
            <div>
                <Stack direction={"column"}>

                    <table className={styles.detailsTable}>
                        {summaryStatsRows}
                    </table>

                </Stack>
            </div>
        </React.Fragment>

        cardContent = <React.Fragment>
            <div className={styles.cardMain}>
                <div className={styles.iconContainer}>
                    <CardMedia className={styles.cardMedia}>

                        <Box sx={{width: 120, height: 120}}>
                            {iconData && <ObjectIcon size={120}
                                                     data={iconData}/>}
                            {!iconData && <HelpOutlineIcon sx={{fontSize: 100}}/>}
                        </Box>


                    </CardMedia>
                </div>
                <div className={styles.cardContent}>{itemLabel}{dataContent}</div>
            </div>


        </React.Fragment>
        const hasCardContent = extendedStatsView || props.derivedStats || props.headerItems;
        if (hasCardContent) {
            cardActionContent = <React.Fragment>
                {extendedStatsView}
                {props.vertical ? undefined : props.derivedStats}
                {props.headerItems && <CardActions>
                    {/*<Stack direction={"row"}>*/}
                    {props.headerItems}
                    {/*</Stack>*/}
                </CardActions>}

            </React.Fragment>
        }
    }


    return <GridItemInternalWrapper
        tall={props.vertical}
        size={props.size}
        content={cardContent}
        cardActionContent={cardActionContent}
        isGrid={props.independent === false}
        onSelectItem={props.onSelectItem}
        maxWidth={props.maxWidth}
    />

    // if (props.independent) {
    //     return <GridItemCardContainer size={props.size}
    //                                   tall={props.tall}
    //                                   altBgColor={props.altBgColor}
    //                                   vertical={props.vertical}
    //                                   onSelectItem={props.onSelectItem}
    //                                   content={innerContent}/>
    // } else {
    //     return <GridItemContainer size={props.size}
    //                               altBgColor={props.altBgColor}
    //                               tall={props.tall}
    //                               onSelectItem={props.onSelectItem}
    //                               content={innerContent}/>
    // }

}