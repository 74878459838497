import * as React from 'react';
import {useEffect, useState} from 'react';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Box, Collapse, Icon, ListItemButton, Stack, Tooltip} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import {IconData} from "../Data/ModelGenerated";
import {ObjectIcon} from "../UI/ObjectIcon";
import JSONTree from "react-json-tree";


export type TSidebarItemCollection = { [key: string]: ISidebarItem }

export interface ISidebarItem {
    label: string;// | JSX.Element;
    key: string
    onClick: () => void,
    iconData?: IconData

    flatten?: boolean
    disabled?: boolean | string

    children?: TSidebarItemCollection
}

export interface ISideBarNavData {
    topLevelItems: TSidebarItemCollection

    datasetInfo: {
        totalGames: number,
        lastUpdated: string
    }
}

export interface ISidebarProps {

    //TODO don't pass full dataset, just top level names + urls instead
    path: string[]
    data: ISideBarNavData
    as: any
    open: { value: boolean, toggle: () => void }
    mobile?: boolean

    children?: JSX.Element | JSX.Element[]
}

export const mainListItems = (
    <div>
        <ListSubheader inset>Aggregated Stats</ListSubheader>

        <ListItem button>
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="By Civ"/>
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <ShoppingCartIcon/>
            </ListItemIcon>
            <ListItemText primary="By Map"/>
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="By Elo Range"/>
        </ListItem>
    </div>
);


export function Sidebar(props: ISidebarProps) {


    // let [selectedItem, setSelectedItem] = useState<string | undefined>()


    const getSelectedItem = ((path: string[]) => {

            if (path.includes("grouped_tables")) {
                return path.join(".").replace("grouped_tables", "__DS").replace("samples.All.tableGroups.", "")
            } else {
                let temp = [...path]
                temp[0] = '__DS'
                return temp.join(".").replace("civilizations", "civ")
            }
        }
    )

    const selectedItem: string = getSelectedItem(props.path)

    const setSelectedItem = (v: string) => {

    }

    const getCollapseMap = (path: string) => {
        const items: { [keyPath: string]: boolean } = {}
        let container = path.split(".");
        if (container.length > 2) {
            let key = container.slice(0, container.length - 1).join(".")
            items[key] = true
        }
        return items
    }

    useEffect(() => {
        setChildrenCollapseMap(getCollapseMap(selectedItem))
    }, [selectedItem])

    let [childrenCollapseMap, setChildrenCollapseMap] = useState<{ [keyPath: string]: boolean }>(getCollapseMap(selectedItem))

    const buildCollapsePath = (path: string[]) => {
        return path.join(".")
    }
    const setCollapse = (path: string, value: boolean) => {
        let cp = {...childrenCollapseMap}
        cp[path] = value
        setChildrenCollapseMap(cp)
    }

    const getChildrenCollapseMap = (path: string, level: number) => {

        let value = childrenCollapseMap[path]

        // if (value === undefined && level == 0) {
        //     return true
        // }
        return value
    }

    const getStyleForList = (len: number) => {
        if (len > 7) {
            return {
                overflowY: "scroll",
                overflowX: "hidden",
                maxHeight: "65vh"
            }
        } else {
            return {}
        }
    }
    let openSet = false;

    const renderListItem = (item: ISidebarItem, path: string[], level: number) => {
        let strPath = buildCollapsePath(path)

        let onClick = () => {
            setSelectedItem(strPath)
            item.onClick()
            // setSelectedItem(strPath)
        }
        let toggleCollapse = (ev: any) => {
            ev.stopPropagation();
            if (item.children) {
                let _open = getChildrenCollapseMap(buildCollapsePath(path), level)
                setCollapse(strPath, !_open)
            }
        }
        let pl = props.open.value ? 3 * level : 3;

        let content: JSX.Element;

        if (item.children && Object.keys(item.children).length > 0) {
            const collapsePath = buildCollapsePath(path);
            let open = getChildrenCollapseMap(collapsePath, level)

            if (!openSet && childrenCollapseMap[collapsePath] === undefined) {
                openSet = true;
                open = true;
            }


            const selfLevel = item.flatten ? level : level + 1
            const children = <List component="div" disablePadding
                // @ts-ignore
                                   sx={getStyleForList(Object.keys(item.children).length)}>
                {/*// @ts-ignore */}
                {Object.keys(item.children).map(key => renderListItem(item.children[key], [...path, key], selfLevel))}
            </List>

            if (item.flatten) {
                content = children
            } else {
                let expandButton = <IconButton>{open ? <ExpandLess onClick={toggleCollapse}/> :
                    <ExpandMore onClick={toggleCollapse}/>}</IconButton>
                content = <React.Fragment>
                    <ListItemButton onClick={onClick} sx={{pl: pl}}
                                    color={selectedItem === strPath ? "red" : undefined}>

                        <ListItemIcon>
                            {item.iconData ? <ObjectIcon data={item.iconData} borderless/> : open ? <FolderOpenIcon/> :
                                <FolderIcon/>}
                        </ListItemIcon>

                        {props.open.value &&
                            <ListItemText primary={item.label} color={selectedItem === strPath ? "red" : undefined}/>}

                        {props.open.value ? (expandButton) : undefined}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {children}
                    </Collapse>
                </React.Fragment>
            }

        } else {
            content = <ListItemButton onClick={onClick}
                                      sx={{pl: pl, paddingRight: "4px !important;"}}
                                      selected={selectedItem === strPath}
                                      disabled={item.disabled !== undefined && item.disabled !== false}


            >
                <ListItemIcon>
                    {item.iconData ? <ObjectIcon data={item.iconData} borderless/> : <DashboardIcon/>}
                </ListItemIcon>
                {props.open.value && <ListItemText primary={`${item.label}`}
                                                   primaryTypographyProps={{
                                                       sx: {
                                                           overflow: "hidden",
                                                           whiteSpace: "nowrap",
                                                           display: "inline-block",
                                                           textOverflow: "ellipsis !important;",
                                                           maxWidth: 100
                                                       }
                                                   }}
                                                   sx={{
                                                       // textOverflow: "ellipsis !important;",
                                                       // backgroundColor: "blue"
                                                   }}/>}
            </ListItemButton>
        }

        if (item.disabled !== undefined && (typeof item.disabled === 'string')) {
            content = <Tooltip title={item.disabled}>
                <span>
                {content}
                </span>
            </Tooltip>

        }

        return content
    }

    let mainListItems = Object.keys(props.data.topLevelItems).map((key) => renderListItem(props.data.topLevelItems[key], [key], 0))

    let Drawer = props.as;

    // return <Box sx={{paddingTop: props.mobile ? "56px" : undefined}}>
    //     km2lm
    //     kfds.fd
    //     ksdfmklsfd;
    //     <br/>
    //     <br/>
    //     <br/>
    //     <br/>
    //     sfdklslmkdf
    //     WTF???
    //     mobile : {JSON.stringify(props.mobile)}
    // </Box>

    if (props.mobile) {
        return <Box
            sx={{
                display: props.open.value ? "flex" : "none",
                flexDirection: "column",
                marginTop: "56px",
                width: "100%",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "100%",
                    boxSizing: 'border-box',
                },
            }}
            // variant="persistent"
            // anchor="top"
            // open={props.open.value}
        >
            <Stack>
                {props.children}
            </Stack>

            <Box marginLeft={"10px"}

                // sx={{
                //     paddingTop: props.mobile ? "56px" : undefined
                // }}
            >
                {/*/!*{JSON.stringify(props.path)}*!/*/}
                {/*/!*<br/>*!/*/}
                {/*/!*{selectedItem}*!/*/}
                {/*<br/>*/}
                {/*/!*{selectedItemT}*!/*/}
                <List>
                    {/*<Box>*/}
                    {mainListItems}
                    {/*</Box>*/}
                </List>
            </Box>

        </Box>
    }


    return <Drawer
        // variant={"permanent"}
        // className={`mobile-${props.mobile} open-${props.open.value}`}
        variant={"permanent"}
        // anchor={props.mobile ? "left" : undefined}
        onClose={() => props.open.toggle()}
        // anchor={props.mobile ? "top" : undefined}
        // style={{
        //     display: (props.mobile && !props.open.value) ? "none" : 'flex',
        //     visibility: 'visible',
        // }}
        // sx={{display: (props.mobile && !props.open) ? "none !important;" : 'flex'}}
        open={props.open.value}>

        <Toolbar

            sx={{
                // display: (props.mobile && !props.open) ? "none !important;" : 'flex',
                // display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // px:  [1],
            }}
        >


            <IconButton onClick={props.open.toggle}>
                <ChevronLeftIcon/>
            </IconButton>
        </Toolbar>
        {/*<Divider/>*/}
        {/*<div>selectedItem : {selectedItem}</div>*/}

        <Box marginLeft={"10px"}

            // sx={{
            //     paddingTop: props.mobile ? "56px" : undefined
            // }}
        >

            {/*selected: {selectedItem}*/}
            {/*<br/>*/}
            {/*strPath: {buildCollapsePath(props.path)}*/}

            {/*/!*{JSON.stringify(props.path)}*!/*/}
            {/*/!*<br/>*!/*/}
            {/*/!*{selectedItem}*!/*/}
            {/*<br/>*/}
            {/*/!*{selectedItemT}*!/*/}
            <List>
                {/*<Box>*/}
                {mainListItems}
                {/*</Box>*/}
            </List>
        </Box>


        <Divider/>
        {/*<JSONTree data={childrenCollapseMap}/>*/}
        {/*<List><SecondaryListItems/></List>*/}
    </Drawer>

}