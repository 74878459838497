import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import {useTheme, ThemeProvider, createTheme} from '@mui/material/styles';
import Dashboard from "./Dashboard";
import {Theme} from "@mui/material";
import {useEffect, useState} from "react";
import {BuildDefaultTheme} from "./Theme";

export type TColorMode = "" | "light" | "dark"
export type TDefaultDataView = "scatter" | "table" | "grid"

export interface IAppSettingsContext {
    setColorMode: (mode: TColorMode) => void;
    getColorMode: () => TColorMode;
    toggleColorMode: () => void;
    setDataViewMode: (mode: TDefaultDataView) => void;
    getDataViewMode: () => TDefaultDataView;

    getDataViewSessionMode: () => TDefaultDataView;
    setDataViewSessionMode: (mode: TDefaultDataView) => void;

    setShowCIfAvailable: (v: boolean) => void;
    getShowCIfAvailable: () => boolean;

    setShowDetailedCollapsed: (v: boolean) => void;
    getShowDetailedCollapsed: () => boolean;


}

export const SHOW_CI_SETTINGS_KEY = "doSetShowCIfAvailable"
export const SHOW_DETAILED_COLLAPSED_SETTINGS_KEY = "showDetailedCollapsed"

export const AppSettingsContext = React.createContext<IAppSettingsContext>({
    setColorMode: (mode: TColorMode) => {
    },
    getColorMode: () => "light",
    toggleColorMode: () => {
    },

    setDataViewMode: (mode: TDefaultDataView) => {
    },
    getDataViewMode: () => "scatter",

    setShowCIfAvailable: (v: boolean) => undefined,
    getShowCIfAvailable: () => true,

    getDataViewSessionMode: () => "scatter",
    setDataViewSessionMode: (mode: TDefaultDataView) => {

    },
    setShowDetailedCollapsed: (v: boolean) => undefined,
    getShowDetailedCollapsed: () => true,
});

const SETTINGS_PREFIX = "app_settings_field__"

const getSystemColorMode: () => "light" | "dark" = () => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return "dark"
    }
    return "light"

}

const getDefaultView = () => {

    const array: TDefaultDataView[] = ["table", "grid"]

    if (window.screen.width >= 600)
        array.push("scatter")

    const randomElement = array[Math.floor(Math.random() * array.length)];

    return randomElement
}

export default function ToggleColorMode() {


    const saveSettingsVal = (key: string, val: string) => {
        return localStorage.setItem(`${SETTINGS_PREFIX}${key}`, val)
    }
    const getSettingsVal = (key: string) => {
        return localStorage.getItem(`${SETTINGS_PREFIX}${key}`)
    }

    const getDefaultColorMode = () => {
        let v = getSettingsVal("mode")
        return (v ? v : "") as TColorMode
    }

    const getDefaultDataView = () => {
        let v = getSettingsVal("defaultDataView")
        return (v ? v : getDefaultView()) as TDefaultDataView
    }

    const getDefaultShowCiIfAvailable = () => {
        return getSettingsVal(SHOW_CI_SETTINGS_KEY) === "YES" || !getSettingsVal(SHOW_CI_SETTINGS_KEY);
    }

    const getDefaultShowDetailedCollapsed = () => {
        return getSettingsVal(SHOW_DETAILED_COLLAPSED_SETTINGS_KEY) === "YES" || !getSettingsVal(SHOW_CI_SETTINGS_KEY);
    }


    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            const newColorScheme = event.matches ? "dark" : "light";
            setMode(newColorScheme)
        });

    }, [])


    const [mode, _setMode] = React.useState<TColorMode>(getDefaultColorMode());


    const [defaultDataView, setDefaultDataView] = React.useState<TDefaultDataView>(getDefaultDataView());

    const [sessionDataView, setSessionDataView] = React.useState<TDefaultDataView>(getDefaultDataView());


    const [showCIfAvailable, setShowCIfAvailable] = useState<boolean>(getDefaultShowCiIfAvailable())
    const [showDetailedCollapsed, setShowDetailedCollapsed] = useState<boolean>(getDefaultShowDetailedCollapsed())
    let modeSet: "light" | "dark" = mode === "" ? getSystemColorMode() : mode


    useEffect(() => {
        if (getSettingsVal("defaultDataView") === null) {
            let mode = getDefaultView()
            saveSettingsVal("defaultDataView", mode)
            setDefaultDataView(mode)
        }
    }, [])

    const setMode = (c: TColorMode) => {
        saveSettingsVal("mode", c)
        _setMode(c)
    }

    const colorMode: IAppSettingsContext =
        // React.useMemo(
        // () => (
        {
            setColorMode: (mode: TColorMode) => {
                setMode(mode);
            },
            getColorMode: () => {
                return mode
            },
            toggleColorMode: () => {
                if (modeSet === "dark") {
                    setMode("light")
                } else {
                    setMode("dark")
                }
            },
            setDataViewMode: (mode: TDefaultDataView) => {
                saveSettingsVal("defaultDataView", mode)
                setDefaultDataView(mode)
            },
            getDataViewMode: () => defaultDataView,
            setShowCIfAvailable: (v: boolean) => {
                saveSettingsVal(SHOW_CI_SETTINGS_KEY, v ? "YES" : "NO")
                setShowCIfAvailable(v);
            },
            getShowCIfAvailable: () => showCIfAvailable,

            getDataViewSessionMode: () => sessionDataView,
            setDataViewSessionMode: (mode) => setSessionDataView(mode),

            setShowDetailedCollapsed: (v: boolean) => {
                saveSettingsVal(SHOW_DETAILED_COLLAPSED_SETTINGS_KEY, v ? "YES" : "NO")
                setShowDetailedCollapsed(v);
            },
            getShowDetailedCollapsed: () => false,

        }
    // ),
    // [],
    // );


    const theme: Theme = React.useMemo(
        () =>
            createTheme(BuildDefaultTheme(modeSet)),
        [modeSet],
    );
    useEffect(() => {
        let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
        if (path.length === 0) {
            // window.location.href = "/EmpireWars_Stats"
            // window.location.href = "/RM_Stats"
        }
    });

    return (
        <AppSettingsContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Dashboard/>
            </ThemeProvider>
        </AppSettingsContext.Provider>
    );
}
