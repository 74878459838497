import * as React from 'react';
import {useContext, useEffect, useState} from 'react';

import {DefaultDataRenderer} from "./DefaultDataRenderer";
import {
    DataProviderContext,
    IDataSourceContext, IDataSourceContextStatus,
    resolveDataPromise,
    TDataSourceContextDataView
} from "../Internal/DataProvider";
import Typography from "@mui/material/Typography";
import {Box, CircularProgress, Container} from "@mui/material";
import {DescriptiveStatsTablePromise, SampleSummaryValue, TableBytesWrapper} from "../Data/ModelGenerated";
import JSONTree from "react-json-tree";
import {LoadingIndicator} from "./LoadingIndicator";
import {IsDev} from "../Data/config";
import {AbootViewRenderer} from "./AbootViewRenderer";
import {InitMetaDataResolverCached} from "./TeamStatsView/CachedMetaDataProvider";
import {TQueryError, TQueryMessage} from "../Dashboard";

const sha1 = require('js-sha1');

export function MainContentView() {

    let dataContextOrError = useContext(DataProviderContext)
    let dataContext: IDataSourceContext | null = null;

    // let dataContextStatus: IDataSourceContextStatus | undefined;

    let dataContextError: TQueryError | undefined;
    let dataContextMessage: TQueryMessage | undefined;

    if (dataContextOrError && dataContextOrError.hasOwnProperty("status")) {
        let dataContextStatus = dataContextOrError as IDataSourceContextStatus;
        if (dataContextStatus.status.hasOwnProperty("error")) {
            dataContextError = dataContextStatus.status as TQueryError;
        } else {
            dataContextMessage = dataContextStatus.status as TQueryMessage;
        }
        // dataContextError = dataContextOrError as IDataSourceContextStatus
    } else {
        dataContext = dataContextOrError as IDataSourceContext | null
    }

    const [loadedDataView, setLoadedDataView] = useState<TDataSourceContextDataView | undefined>()

    const [respErrorMsg, setErrorMsg] = useState<string | undefined>()

    const [needsUpdateKey, setNeedsUpdateKey] = useState<string | undefined>(undefined)
    const [dataIsLoading, setDataIsLoading] = useState(false)


    useEffect(() => {
        setErrorMsg(undefined);
        if (dataContext === null)
            return
        let dataViewPotentialPromise = dataContext.getSelectedDataView()

        if (dataContext && dataContext.path.value.length > 0 && dataContext.path.value[0] === "about"){
            return;
        }
        else if (dataViewPotentialPromise instanceof Promise) {
            let dataViewPromise = dataViewPotentialPromise as Promise<TDataSourceContextDataView>
            setLoadedDataView(undefined)
            dataViewPromise.then(((data) => {
                let hasData = data.data !== null
                // let hasData = data.data !== null && data.data.name !== undefined;
                let hasJobInfo = !(!data.metaData?.scheduled_or_running_job)
                if (hasData) {
                    setLoadedDataView(data)
                } else {
                    throw Error(`Unrecgonized data: ${JSON.stringify(data)}`)
                }
            })).catch((error) => {
                setErrorMsg(error.message)
            }).finally(() => {
                setDataIsLoading(false)
            })
        } else {
            setLoadedDataView(dataViewPotentialPromise as TDataSourceContextDataView)
            // setDataIsLoading(false)
        }
    }, [dataContext, dataContext?.getDataViewUpdateKey(), dataContext?.datasetKey])

    // useEffect(() => {
    //         setDataIsLoading(true)
    //         setNeedsUpdateKey(dataContext?.getDataViewUpdateKey())
    //     },
    //     [dataContext?.getDataViewUpdateKey(), dataContext?.datasetKey])
    // }, [dataContext?.getSelectedDataView])

    //TODO HACK, instead fix router, ideally don't use hashrouter anymore and move site to somewhere else than github pages
    const abootView = dataContext && dataContext.path.value.length > 0 && dataContext.path.value[0] === "about"
    if (abootView) {
        return <AbootViewRenderer/>
    }

    if (respErrorMsg || dataContextError || dataContextMessage || (dataContext?.isDataLoading && dataContext.isDataLoading.hasOwnProperty('error'))) {
        let errorMsg: string;

        if (respErrorMsg) {
            errorMsg = respErrorMsg
        } else if (dataContextError !== undefined) {
            errorMsg = dataContextError.error.toString()
        } else if (dataContextMessage !== undefined) {
            return <LoadingIndicator value={dataContextMessage.progress}
                                     valueNext={dataContextMessage.progressNext}
                                     error={false}
                                     label={dataContextMessage.message}
                                     sublabel={dataContextMessage.subLabel}/>
        } else {
            // @ts-ignore
            errorMsg = dataContext.isDataLoading.error.error.toString()
        }
        return <LoadingIndicator value={99} error={true} label={errorMsg} key={99}/>
        // } else if (dataContext?.isDataLoading && dataContext.isDataLoading.hasOwnProperty('error')) {
        //     return <Box>ERROROR <JSONTree data={dataContext.isDataLoading}/></Box>
    } else if (dataContext === null || dataContext.isDataLoading || dataIsLoading) {
        return <LoadingIndicator/>
    }



    // Promise.resolve(dataViewPotentialPromise).then(function (value) {
    //     setLoadedDataView(value)
    // })
    //
    let _loadedDataView = loadedDataView;

    // if (metadataDefinesLoaded === false) {
    //     return <LoadingIndicator/>
    // } else if (metadataDefinesLoaded === "failed") {
    //     return <LoadingIndicator error label={"Could not connect to API"}/>
    // } else
    if (!_loadedDataView) {
        return <LoadingIndicator/>
    } else if (!_loadedDataView.data) {
        return <LoadingIndicator/>
    } else if (_loadedDataView.itemType === "NewDataSetWithLabel" || _loadedDataView.itemType === "TeamRMStatsDataView" || _loadedDataView.itemType === "TeamRMStatsDataViewSplitByEloCollection") {
        ;
        // TODO IMPORTANT! Fix datasetKey when using TeamRMStatsDataView currently it not set, which will likely
        // cause all kind of issues when changing data
    } else if (_loadedDataView.datasetKey !== dataContext.datasetKey && _loadedDataView.itemType !== "NewDataSet") {
        return <LoadingIndicator/>
    }

    let disableGrid = false;
    if (_loadedDataView.data.name === "Players") {
        disableGrid = true
    }

    const _resolveDataPromise = dataContext.viewGroup ? (async (promiseData: DescriptiveStatsTablePromise) => {

        // @ts-ignore
        return await resolveDataPromise(dataContext.viewGroup, promiseData) as TableBytesWrapper;
    }) as unknown as (promiseData: DescriptiveStatsTablePromise) => Promise<TableBytesWrapper> : undefined;

    const dataHash = sha1(window.btoa(unescape(encodeURIComponent(JSON.stringify(_loadedDataView.data))))) as string

    const content = <DefaultDataRenderer
        resolveDataPromise={_resolveDataPromise}

        dataKey={dataContext.datasetKey + dataContext?.getDataViewUpdateKey() + dataHash}
        getBaselineData={dataContext.getBaselineData}
        serversideSort={dataContext.serversideSort}
        pagination={dataContext.pagination}
        data={_loadedDataView.data}
        itemType={_loadedDataView.itemType}
        metaData={_loadedDataView.metaData}
        path={dataContext.path}
        tableServerside={dataContext.tableServerside}
        disableGrid={disableGrid}/>

    return <Box sx={{minHeight: "75vh"}}>
        {IsDev() && dataContext?.getDataViewUpdateKey()}
        {content}

        {/*<Box>*/}
        {/*    <Box>KEY</Box>*/}
        {/*    /!*{dataContext?.getDataViewUpdateKey()}*!/*/}
        {/*</Box>*/}
    </Box>
}