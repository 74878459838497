import * as React from "react";
import {useContext, useState} from "react";
import {Box, Card, Grid, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {AppSettingsContext} from "../../App";

export interface IDataTableContainerHeaderProps {
    expanded: boolean
    showControls: boolean
    mainViewItem?: boolean
    toggleExpanded: () => void
    filterComponents?: any
    disableCollapse?: boolean
    title: string
}

export const DataTableContainerHeader = (props: IDataTableContainerHeaderProps) => {
    return <Box sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: props.mainViewItem ? "0px" : "5px",
        marginBottom: props.mainViewItem ? "0px" : "5px",
        minHeight: props.mainViewItem ? 0 : 45
    }}>
        {props.mainViewItem ? undefined : <React.Fragment>
            <Box sx={{display: "flex", alignItems: "center"}}>
                {props.disableCollapse ? <Box width={"32px"}/> : <IconButton onClick={props.toggleExpanded}>
                    {props.expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </IconButton>}

                <Typography>{props.title}</Typography>

            </Box>
            {props.showControls && props.filterComponents}
        </React.Fragment>}
    </Box>
}

export type TExpandedSetter = {
        value: boolean,
        set: (v: boolean) => void
    }
export type TExpandedStateOrSetter = boolean | TExpandedSetter

export function DataTableWrapper(props: {
    hideCard?: boolean, mainViewItem?: boolean, title: string, children: JSX.Element | JSX.Element[], disableCollapse?: boolean, filterComponents?: any, expanded?: TExpandedSetter
}) {
    const settings = useContext(AppSettingsContext);

    const [_expanded, _setExpanded] = useState<boolean>(true);

    const expandedProp = props.expanded ? props.expanded : {value: _expanded, set: _setExpanded}

    const expanded = expandedProp.value;
    const setExpanded = expandedProp.set;

    const toggleExpanded = () => {
        setExpanded(!expanded)
    }

    const showControls = !expanded || settings.getShowDetailedCollapsed()

    return <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card sx={{marginBottom: "1rem"}}>
            {/*expanded: {JSON.stringify(expanded)}*/}
            {/* /*/}
            {/*showControls: {JSON.stringify(showControls)}*/}
            <DataTableContainerHeader
                {...props}
                showControls={showControls}
                expanded={expanded}
                toggleExpanded={toggleExpanded}

            />
            {/*<Typography>{props.viewInfo.title}</Typography>*/}
            {showControls && props.children}
        </Card>
    </Grid>
}