import JSONTree from "react-json-tree";
import * as React from "react";
import {IconData, MetaData, NewDataRow, NewDataTable, TeamRMRawStatsTable} from "../../Data/ModelGenerated";
import {Box, Stack} from "@mui/material";
import {TeamStatsSummaryViewContainer} from "./TeamStatsSummaryViewContainer";
import {TableOrGridOrScatterPlotRenderer} from "../Table/TableOrGridOrScatterPlotRenderer";
import {IPathObjectExtension} from "../DefaultDataRenderer";
import {getMetaDataForObject} from "./CachedMetaDataProvider";
import {DefaultDataTableForNewDataTable} from "../Table/DataTable";


export interface ITeamRMRawStatsTableRendererProps {
    table: TeamRMRawStatsTable
    summary?: boolean
}

export const TeamRMCivTeamsTableRenderer = (props: ITeamRMRawStatsTableRendererProps & IPathObjectExtension) => {


    if (props.summary && false) {
        return <TeamStatsSummaryViewContainer/>
    }


    const rows: { [key: string]: NewDataRow } = {}

    const nSum = Object.values(props.table.data).map(r => (r as any).n).reduce((acc, v) => acc + v)

    for (let key of Object.keys(props.table.data)) {
        let sourceRow = props.table.data[key] as any;

        let teamCivs = sourceRow["team_civs"] as number[]

        // const mdRenderer = <Stack direction="row" spacing={2}>
        //     {teamCivs.map(key => getMetaDataForObject("civilizations", key))}
        // </Stack>

        const meta_data = teamCivs.map(key => getMetaDataForObject("civilizations", key))

        const row: NewDataRow = {
            meta_data: meta_data,
            n: sourceRow.n,
            prop: sourceRow.prop,
            win_rate: sourceRow.win_rate
        }

        rows[key] = row
    }

    const data: NewDataTable = {
        data: rows,
        label: "Civ Win Rates",
        n: 0,
        prop: 0,
        type: 'civs_table',
        win_rate: 0
    }
    return <Box>

        <DefaultDataTableForNewDataTable
            data={data}
            dataKey={props.dataKey}
            path={props.path}
            viewInfo={{title: "Top 30 Most Popular Teams"}}
        />
    </Box>
}