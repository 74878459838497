import React, {useContext} from 'react';
import styles from "./StatsValueElement.module.scss"
import {propToPercent, roundToDec} from "../Utils";
import {Box, LinearProgress, Stack, Tooltip, Typography} from "@mui/material";
import {DataTableLayoutContext} from "../Components/Table/DataTable";

const calcCIForSample = (n: number, ratio: number) => {

    const positiveScore = Math.round(n * ratio);

    if (n < 25 || positiveScore < 10) {
        return 1
    }

    const total = n;
    // phat is the proportion of successes
    // in a Bernoulli trial process
    const phat = positiveScore / total;

    // z is 1-alpha/2 percentile of a standard
    // normal distribution for error alpha=5%
    const z = 1.96;

    // implement the algorithm
    // (http://goo.gl/kgmV3g)
    const a = phat + z * z / (2 * total);
    const b = z * Math.sqrt((phat * (1 - phat) + z * z / (4 * total)) / total);
    const c = 1 + z * z / total;

    return ((ratio - ((a - b) / c)) + (ratio - (((a + b) / c))) / 2)
    // return roundToDec((((a - b) / c) + ((a + b) / c)) / 2, 4)
    // return {
    //     left: (a - b) / c,
    //     right: (a + b) / c
    // };
}

export interface ISummaryStat {

    label: string
    value: number
    unit: "%" | string | undefined | "time"

    total?: number | [number, number]
    baseline?: number
    baselineLabel?: string
    baselineMagnitude?: 0.1 | 0.2 | 0.5 | 1 | number

    dontShowProgress?: boolean

    hideBaseline?: boolean
    ci?: { n: number }


}

export function StatsValueElement(props: ISummaryStat) {


    const layoutCtx = useContext(DataTableLayoutContext);

    let val = props.value;
    let unitStr = props.unit ? props.unit : "";
    // if (props.total) {
    //     val = 100 / props.max * props.value
    // }
    let valEl: JSX.Element;
    if (props.unit === "range") {

    } else if (props.unit === "%") {
        val = propToPercent(val as number)
    } else if (props.unit === "") {
        val = Math.round(val as number)
        // valEl = <div className={styles.label}>{props.value.toFixed(0)}</div>
    }
    // else {
    //     valEl = <div className={styles.label}>{val}
    //         <span>{props.unit}</span></div>
    // }
    //
    let progresColor: undefined | "veryPositive" | "positive" | "neutral" | "negative" | "veryNegative";
    let diff = 0


    if (props.baseline !== undefined && props.baseline !== -1) {
        diff = props.value - props.baseline;
        let mult = props.baselineMagnitude !== undefined ? props.baselineMagnitude : 1
        if (diff > (0.2 * mult)) {
            progresColor = "veryPositive"
        } else if (diff < (-0.2 * mult)) {
            progresColor = "veryNegative"
        } else if (diff < (0.2 * mult) && diff > (mult * 0.035)) {
            progresColor = "positive"
        } else if (diff > (mult * -0.2) && diff < (mult * -0.35)) {
            progresColor = "negative"
        } else {
            progresColor = "neutral"
        }
    }

    let progressBar: JSX.Element | undefined;
    if ((props.total || props.unit === "%") && !props.dontShowProgress) {
        valEl = <div className={styles.label}>{val}
            <span>{unitStr}</span></div>

        let progVal = val;
        if (Array.isArray(props.total)) {
            progVal = (1 / props.total[1]) * (val - props.total[0]);
        } else if (props.total) {
            progVal = 1 / props.total * val;
        }


        progressBar = <LinearProgress value={progVal * (props.unit !== "%" ? 100 : 1)}
                                      variant={"determinate"}
                                      className={`${styles.progressBar} ${progresColor !== undefined ? `${styles.progressColor} ${styles[progresColor]}` : ""}`}

        />
    } else if (props.unit === "time") {
        valEl = <div className={styles.label}>{val}
            <span>{" min"}</span></div>
        const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

        progressBar = <LinearProgress value={normalise(props.value)}
                                      variant={"determinate"}

                                      className={`${styles.progressBar} ${progresColor !== undefined ? `${styles.progressColor} ${styles[progresColor]}` : ""}`}

        />
    } else {


        if (props.ci) {
            const ciVal = calcCIForSample(props.ci.n, props.value)

            const color = ciVal === 1 ? styles[`veryNegative`] : styles[`${progresColor}`]

            valEl = <Box sx={{
                display: "flex",
                flexDirection: layoutCtx.compact ? "row" : "column"
            }}>
                <Typography variant={"body2"} align={"center"}
                            sx={{marginRight: layoutCtx.compact ? "1rem" : undefined}}

                            className={`${styles.valueLabel} ${color}`}>{`${val}${unitStr}`}
                </Typography>


                <Tooltip
                    arrow
                    title={
                        <div>{ciVal === 1 ? `There are not enough samples to accurately estimate the win rate` : `Based on sample size the real value should be between ${propToPercent(props.value - ciVal, 2)}% and  ${propToPercent(props.value + ciVal, 2)}% (with 95% confidence)`}
                        </div>}
                >
                    <Stack>
                        <Typography variant={"subtitle2"}
                                    color={ciVal === 1 ? "values.negative" : undefined}
                                    align={"center"}
                                    className={`${styles.baselineLabel} ${styles.valueLabel} ${styles[`${progresColor}`]}`}

                                    sx={{fontSize: layoutCtx.compact ? "0.725rem" : undefined}}
                        >
                            {ciVal === 1 ? "!" : `±${propToPercent(ciVal, 3)}%`}
                        </Typography>
                    </Stack>
                </Tooltip>
            </Box>
        } else if (props.baseline && progresColor) {
            let baselineRelative = diff / props.baseline
            valEl = <Box sx={{display: "flex", flexDirection: "column"}}>
                <Typography variant={"body2"} align={"center"}
                            className={`${styles.valueLabel} ${styles[`${progresColor}`]}`}>{`${val}${unitStr}`}</Typography>
                <Tooltip
                    arrow
                    title={
                        <div>{`${propToPercent(baselineRelative)}% ${diff > 0 ? "higher" : "lower"} than all players average (${propToPercent(props.baseline)}%)`}<br/> {props.baselineLabel}
                        </div>}
                >
                    <Typography variant={"subtitle2"} align={"center"}
                                className={`${styles.baselineLabel} ${styles.valueLabel} ${styles[`${progresColor}`]}`}>
                        {`${diff > 0 ? "+" : ""}${propToPercent(baselineRelative)}%`}
                    </Typography>
                </Tooltip>
            </Box>
        } else {
            valEl =
                <Typography variant={"body2"} align={"center"}>{`${val}${unitStr}`}</Typography>

        }

        // if ((props.baseline && progresColor) || props.ci) {
        //     let baselineRelative = props.baseline && (diff / props.baseline);
        //     valEl = <Box sx={{display: "flex", flexDirection: "column"}}>
        //         <Typography variant={"body2"} align={"center"}
        //                     className={`${styles.valueLabel} ${styles[`${progresColor}`]}`}>{`${val}${unitStr}`}</Typography>
        //
        //         {props.ci === undefined && !props.hideBaseline && baselineRelative !== undefined && props.baseline !== undefined &&
        //             <Tooltip
        //                 arrow
        //                 title={
        //                     <div>{`${propToPercent(baselineRelative)}% ${diff > 0 ? "higher" : "lower"} than all players average (${propToPercent(props.baseline)}%)`}<br/> {props.baselineLabel}
        //                     </div>}
        //             >
        //                 <Typography variant={"subtitle2"} align={"center"}
        //                             className={`${styles.baselineLabel} ${styles.valueLabel} ${styles[`${progresColor}`]}`}>
        //                     {`${diff > 0 ? "+" : ""}${propToPercent(baselineRelative)}%`}
        //                 </Typography>
        //             </Tooltip>}
        //         {ciVal !== undefined &&
        //             <Tooltip
        //                 arrow
        //                 title={
        //                     <div>{ciVal === 1 ? `There are not enough samples to accurately estimate the win rate` : `Based on sample size the real value should be between ${propToPercent(props.value - ciVal, 2)}% and  ${propToPercent(props.value + ciVal, 2)}% (with 95% confidence)`}
        //                     </div>}
        //             >
        //                 <Typography variant={"subtitle2"}
        //                             color={ciVal === 1 ? "values.negative" : undefined}
        //                             align={"center"}
        //                             className={`${styles.baselineLabel} ${styles.valueLabel} ${styles[`${progresColor}`]}`}>
        //                     {`±${propToPercent(ciVal, 3)}%`}
        //                 </Typography>
        //             </Tooltip>}
        //     </Box>
        //
        // } else {
        //     valEl =
        //         <Typography variant={"body2"} align={"center"}>{`${val}${unitStr}`}</Typography>
        //
        // }
    }

    return <div className={styles.container}>
        {progressBar}
        {valEl}
    </div>
}