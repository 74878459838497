import {IconData, MetaData} from "./Data/ModelGenerated";

export const propToPercent = (prop: number, decimals?: number) => {
    let _dec = decimals !== undefined ? decimals : 2;
    let mult = Number("100" + "0".repeat(_dec))
    let div = Number("1" + "0".repeat(_dec))
    return Math.round(prop * mult) / div
}

export const GetDefaultMetaData = (target: MetaData | MetaData[] | [MetaData[], MetaData[]]) => {

    if (Array.isArray(target)) {

        if (Array.isArray(target[0])) {
            return target[0][0] as MetaData;
        }

        return target[0] as MetaData;
    } else {
        return target as MetaData;
    }
}

export const GetItemKeyForIconData = (target: IconData | IconData[] | [IconData[], IconData[]]) => {

    if (Array.isArray(target)) {
        if (Array.isArray(target[0])) {
            let targetMatchup = target as [IconData[], IconData[]]
            let teamA = targetMatchup[0].map(t => t?.key).filter(t => t !== undefined).join("")
            let teamB = targetMatchup[1].map(t => t?.key).filter(t => t !== undefined).join("")

            return [teamA, teamB].join("_")
        } else {
            let targetTeam = target as IconData[]

            return targetTeam.map(t => t.key).filter(t => t !== undefined).join("")
        }
    } else {
        return target.key
    }
}

export const GetItemKeyForMetaData = (target: MetaData | MetaData[] | [MetaData[], MetaData[]]) => {

    if (Array.isArray(target)) {
        if (Array.isArray(target[0])) {
            let targetMatchup = target as [MetaData[], MetaData[]]
            let teamA = targetMatchup[0].map(t => t.icon_data?.key).filter(t => t !== undefined).join("")
            let teamB = targetMatchup[1].map(t => t.icon_data?.key).filter(t => t !== undefined).join("")

            return [teamA, teamB].join("_")
        } else {
            let targetTeam = target as MetaData[]
            return targetTeam.map(t => t.icon_data?.key).filter(t => t !== undefined).join("")
        }
    } else {
        return target.icon_data?.key
    }
}


export const roundToDec = (value: number, dec?: number) => {

    let _dec = dec !== undefined ? dec : 2;
    let mult = Number("1" + "0".repeat(_dec))

    return Math.round(value * mult) / _dec
}

export function GetObjOrMapKeys(target: Object | Map<string, any>) {

    if (target instanceof Map) {
        return Array.from(target.keys())
    } else {
        return Object.keys(target)
    }
}