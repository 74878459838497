import * as React from 'react';
import {
    IconGroup,
    MetaData,
    NewDataGroup,
    NewDataRow,
    NewDataTable,
    NewDataTableGroupWithSummary
} from "../../Data/ModelGenerated";
import JSONTree from "react-json-tree";
import {Grid} from "@mui/material";

import {GridItem} from "./GridItem";
import {NewDataGroupWithLabel} from "../../Data/CheckType";
import {DataRendererExtensions, IPathObjectExtension} from "../DefaultDataRenderer";
import {DerivedCivOverview1v1} from "../DerivedStatsComponents/DerivedCivOverviewTeam";

export interface IGridViewProps {
    data: NewDataGroupWithLabel
}

export interface IGridViewForTableProps {
    data: NewDataTable
}

export const OnSelectTopLevelGridItem = (val: string, current: string[], set: (v: string[]) => void, group?: 'civ' | 'map_type') => {


    // TODO HACK
    const isTeamRm = current[0].length === 3 && current[0][1] === "v";
    if (isTeamRm) {

        const g: IconGroup = group !== "civ" ? "maps" : "civilizations";
        let currentSlice = [...current];

        let indexStart = current.indexOf("maps")
        if (indexStart === -1) {
            indexStart = current.indexOf("civilizations")
        }

        if (indexStart > -1) {
            currentSlice = current.slice(0, indexStart);
        }

        set([...currentSlice, g, val])
    } else if (current.includes('tableGroups') && current.includes("samples")) {
        let indexStart = current.indexOf("samples")
        let currentSlice = current.slice(0, indexStart);

        if (group) {
            if (!currentSlice.includes(group)) {
                let i = currentSlice.indexOf(group === "civ" ? "map_type" : "civ")
                if (i >= 0) {
                    currentSlice[i] = group;
                }
            }
        }
        set([...currentSlice, "samples", "All", "tableGroups", val])

    } else {
        set([...current, "samples", "All", "tableGroups", val])

    }
}

export function GridViewForTableGroup(props: IGridViewProps & DataRendererExtensions & IPathObjectExtension) {

    let tableGroups = props.data.samples["All"].tableGroups;
    // @ts-ignore
    let sortedKeys = Object.keys(tableGroups).filter(k => k !== "get").sort((a, b) => tableGroups[b].n - tableGroups[a].n)

    let gridItems: JSX.Element[] = sortedKeys.map(k => {
        let item = props.data.samples["All"].tableGroups.get(k) as unknown as NewDataTableGroupWithSummary;

        const derivedStatsView = item ? <DerivedCivOverview1v1 data={item}/> : undefined;
        //
        //
        // // @ts-ignore
        // if (["42", "41", "40", "20"].includes((item.meta_data as MetaData).icon_data.key)){
        //
        // }
        // else {
        //     return ;
        // }

        // let item = props.data.tableGroups[k]
        return <GridItem
            onSelectItem={() => OnSelectTopLevelGridItem(k, props.path.value, props.path.set)}// props.path.set([...props.path.value, "samples", "All", "tableGroups", k])}
            derivedStats={derivedStatsView}
            data={item}
            independent={false}/>
    })


    return <Grid container spacing={2}>
        {gridItems}
    </Grid>
}

export function GridViewForTable(props: IGridViewForTableProps & DataRendererExtensions & IPathObjectExtension) {

    // @ts-ignore
    let sortedKeys = Object.keys(props.data.data).filter(k => k !== "get").sort((a, b) => props.data.data[b].n - props.data.data[a].n)

    const viewType: "civ" | "map_type" = "civ";
    let gridItems: JSX.Element[] = sortedKeys.map(k => {
        let item = props.data.data[k] as unknown as NewDataRow;
        //
        //
        // // @ts-ignore
        // if (["42", "41", "40", "20"].includes((item.meta_data as MetaData).icon_data.key)){
        //
        // }
        // else {
        //     return ;
        // }

        // let item = props.data.tableGroups[k]

        if (Array.isArray(item.meta_data)) {
            throw Error("GridViewForTable only supports single MetaData obj per item")
        }

        const metaData = item.meta_data as MetaData;

        const groupPlaceholder: NewDataTableGroupWithSummary = {meta_data: metaData, n: 0, prop: 0, win_rate: 0}

        return <GridItem
            onSelectItem={() => OnSelectTopLevelGridItem(k, props.path.value, props.path.set, viewType)}// props.path.set([...props.path.value, "samples", "All", "tableGroups", k])}
            data={groupPlaceholder}/>
    })


    return <Grid container spacing={2}>
        {gridItems}
    </Grid>
}