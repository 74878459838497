import {Chip, Stack, Tooltip} from "@mui/material";
import {useContext} from "react";
import {DataTableLayoutContext} from "../Table/DataTable";
import {IconData} from "../../Data/ModelGenerated";
import {Direction, GetMapType} from "./DerivedStatsDefines";
import Box from "@mui/material/Box";
import React from "react";
import {RelativeStatsContext} from "./DerivedCivOverviewTeam";
import {propToPercent} from "../../Utils";

interface IObjectTypeTagsProps {
    data: IconData
}


export type TObjectTypeTagData = {

    mean_win_rate: number
    mean_adj?: number
    n: number
    label: string
}

export type TObjectTypeTagDataCollection = { [tag: string]: TObjectTypeTagData };

export interface IObjectTypeTagsWithDataProps {
    data: TObjectTypeTagDataCollection
    toggleRelative?: () => void
}

export interface IObjectTypeTagProps {
    label: Direction | string
    score?: number
    relativeScore?: number
    hover?: string
    clickable?: () => void
}

const getColorForTag = (v: number) => {
    let value = (v - 0.4) / (0.6 - 0.4);

    value = value > 1 ? 1 : value < 0 ? 0 : value

    let red = Math.floor(255 * (1 - value));
    let green = Math.floor(255 * value);

    // Calculate blue component as an average of green and red
    let blue = Math.floor((green + red) / 2);

    // Combine components into an RGB color string
    let color = `rgb(${red}, ${green}, ${blue})`;

    return color;

}

export const ObjectTypeTag = (props: IObjectTypeTagProps) => {

    const cl = props.clickable ? {
        variant: "outlined",
        onClick: props.clickable
    } : {}

    // @ts-ignore
    const tag = <Chip

        {...cl}
        label={`${props.label}`}
        size="small"
        sx={{
            minWidth: "75px",
            backgroundColor: props.score !== undefined ? getColorForTag(props.relativeScore !== undefined ? props.relativeScore : props.score) : undefined
        }}
    />

    if (props.hover) {
        return <Tooltip title={props.hover}>
            {tag}
        </Tooltip>
    }
    return tag
}

export const ObjectTypeTagsWithData = (props: IObjectTypeTagsWithDataProps) => {
    const relativeStatsCtx = useContext(RelativeStatsContext);
    const tags = Object.keys(props.data)

    return <Stack sx={{marginLeft: "0.55rem", marginRight: "0.55rem", width: "100%"}}>
        {tags.length > 0 && <Stack direction={"row"} spacing={1} sx={{
            width: "100%",
            maxWidth: 560,
            justifyContent: "start"
        }}>
            {tags.map((k) => <ObjectTypeTag

                label={k as Direction}
                score={props.data[k].mean_win_rate}
                relativeScore={props.data[k].mean_adj}

                hover={props.data[k].label + (relativeStatsCtx.relative ?
                    `(relative: ${propToPercent(props.data[k].mean_adj as number, 1)}%)` : "")}/>)}

            {/*{props.toggleRelative !== undefined &&*/}
            {/*    // <React.Fragment>*/}
            {/*    //     <Box sx={{flexGrow: 10}}/>*/}
            {/*        /!*<Tooltip title={*!/*/}
            {/*        /!*    relativeStatsCtx.relative ? "Civ strengths adjusted by it's general win rate" : "Civ strengths compared to all other civs, click to adjust by it's win rate to show it's relative strengths"*!/*/}
            {/*        /!**!/*/}
            {/*        /!*}>*!/*/}

            {/*            /!*<ObjectTypeTag label={relativeStatsCtx.relative ? "Relative" : "Global"}*!/*/}
            {/*            /!*               clickable={props.toggleRelative}/>*!/*/}
            {/*        /!*</Tooltip>*!/*/}
            {/*    // </React.Fragment>*/}
            {/*// }*/}

        </Stack>}
    </Stack>
}

export const ObjectTypeTags = (props: IObjectTypeTagsProps) => {

    const layoutCtx = useContext(DataTableLayoutContext);

    const mapTagsData = GetMapType(Number.parseInt(props.data.key))

    return <Stack sx={{marginLeft: "0.55rem", marginRight: "0.75rem"}}>
        {mapTagsData.length > 0 && <Stack direction={"row"} spacing={1}>
            {mapTagsData.map((d) => <ObjectTypeTag label={d}/>)}
        </Stack>}
    </Stack>
}