import {
    DatasetSplitByEloCollection,
    LazyLoadPath,
    MetaData,
    NewDataGroup, NewDataSample,
    NewDataSet,
    NewDataTableGroupWithSummary,
    PlayerMetaData, TeamRMStatsDataView,
    ViewRequestData
} from "./ModelGenerated";

//Keep in sync with 'StaticTableNames' in the backend
export type TStatsTableNames = "EmpireWars_Stats" | "RM_Stats" | "Team_RM_Stats"


export type DataModelType =
    undefined
    | "NewDataTableGroupWithSummary"
    | "NewDataGroup"
    | "NewDataSet"
    | "TeamRMStatsDataView"
    | "TeamRMStatsDataViewSplitByEloCollection"
    | "NewDataSetWithLabel"
    | ""


// Dict[str, NewDataTableGroupWithSummary]

// export type TEloBracket = "All" | "<950" | "950 - 1200" | "1200 - 1600" | ">1600"
export type TEloBracket = "All" | "<1100" | "1100 - 1500" | ">1500"
export type TTeamEloBracket = "All" | "<1400" | ">1400"
export type TTeamSize = "2v2" | "3v3" | "4v4"

export type DataItemType = undefined | "civ" | "map_type" | "rating_bin" | "profile_id" | "map_type_nested"

export const getEloBracketForElo : (elo: number) => TEloBracket = (elo: number) => {

    if (elo > 1500) {
        return ">1500";
    }
    if (elo > 1100) {
        return "1100 - 1500";
    }
    if (elo > 0) {
        return "<1100";
    }
    return "All"
}

const isNewDataTableGroupWithSummary = (obj: any) => {
    if (typeof obj === 'object') {
        //         n: number
        //      win_rate: number;
        // prop: number;
        // lazyLoadPath?: LazyLoadPath;
        // meta_data?: MetaData | PlayerMetaData;
        // tables?: {

        if (obj.hasOwnProperty("n")) {
            if (obj.hasOwnProperty("win_rate")) {
                if (obj.hasOwnProperty("prop")) {
                    if (obj.hasOwnProperty("meta_data") || obj.hasOwnProperty("tables")) {
                        return true;
                    }
                }
            }
        }
    }
    return false
}

export const isNewDataGroup = (obj: any) => {
    if (typeof obj === 'object') {
        if (obj.hasOwnProperty("name") && obj.hasOwnProperty("size") && obj.hasOwnProperty("samples")) {

            if (Object.keys(obj["samples"]).length > 0) {
                let items: any = Object.values(obj["samples"]["All"]["tableGroups"]);
                let item = items[0];

                return isNewDataTableGroupWithSummary(item)
            }
        }
    }
    return false
}

// TeamRMStatsDataView" || _loadedDataView.itemType === "TeamRMStatsDataViewSplitByEloCollection
export const isNewDataSetWithLabel: (obj: any) => boolean = (obj) => {
    return obj.hasOwnProperty("grouped_tables") && obj.hasOwnProperty("data_view")
}

export const getDataModelType: (obj: any) => DataModelType = (obj) => {


    if (isNewDataTableGroupWithSummary(obj)) {
        return "NewDataTableGroupWithSummary"
    } else if (isNewDataGroup(obj)) {
        return "NewDataGroup"
    }
    else if (isNewDataSetWithLabel(obj)){
        return "NewDataSetWithLabel"
    }
    return undefined;
}


export interface NewDataSetWithLabel extends Omit<NewDataSet, "grouped_tables"> {
    label: string,
    grouped_tables?: {
        [k: string]: NewDataGroupWithLabel;
    };
    data_view?: TeamRMStatsDataView
}


export interface DatasetSplitByEloCollectionWithLabel extends Omit<DatasetSplitByEloCollection, "dataset"> {
    label: string,
    type?: DataModelType
    dataset: {
        [k: string]: NewDataSetWithLabel;
    };

}

export type TableGroup = {
    [k: string]: NewDataTableGroupWithSummary
} & { get: (key: string) => NewDataTableGroupWithSummary }

export type TTableGroup = TableGroup | Map<string, NewDataTableGroupWithSummary>

export interface NewDataGroupWithLabel extends Omit<NewDataGroup, "samples"> {
    label: string
    // tableGroups: TTableGroup;
    samples: {
        [k: string]: NewDataSampleWithSummary;
    };

}

export interface NewDataSampleWithSummary extends Omit<NewDataSample, "tableGroups"> {
    tableGroups: TTableGroup;
}


export interface NewDataGroupWithLabelPagedView extends NewDataGroupWithLabel {
    viewData: ViewRequestData
}

export const getLabelForDataItemType = (type: DataItemType) => {
    if (type === "civ")
        return "Civs"
    else if (type === "map_type")
        return "Maps"
    else if (type === "rating_bin")
        return "Elo Bracket"
    else if (type === "profile_id")
        return "Player"

    // @ts-ignore
    return type ? type.toString() : "MISSING"
}

export const getTableGroups = (source: DatasetSplitByEloCollectionWithLabel | NewDataSetWithLabel, k: string) => {

    if (source.hasOwnProperty('DatasetSplitByEloCollection')) {
        let data = source as DatasetSplitByEloCollectionWithLabel
        if (data.dataset["All"].grouped_tables) {
            return data.dataset["All"].grouped_tables[k].samples["All"].tableGroups
        }

    } else {
        let data = source as NewDataSetWithLabel
        if (data.grouped_tables) {
            return data.grouped_tables[k].samples["All"].tableGroups
        }
    }

    return undefined;

}


export const getGroupedTablesKeys = (source: DatasetSplitByEloCollectionWithLabel | NewDataSetWithLabel) => {
    if (source.hasOwnProperty('DatasetSplitByEloCollection')) {
        let data = source as DatasetSplitByEloCollectionWithLabel

        if (data.dataset["All"].grouped_tables) {
            return Object.keys(data.dataset["All"].grouped_tables)
        }
    } else {
        let data = source as NewDataSetWithLabel
        if (data.grouped_tables) {
            return Object.keys(data.grouped_tables)
        }
        if (data.data_view){
            return ["civ", "map_type"]
            // return Object.keys(data.data_view.data)
        }
    }

    return ["TODO - NO TABLE KEYS"]
}

export const getDatasetInfo = (source: DatasetSplitByEloCollectionWithLabel | NewDataSetWithLabel) => {
    if (source.hasOwnProperty('DatasetSplitByEloCollection')) {
        let data = source as DatasetSplitByEloCollectionWithLabel
        return {totalGames: -1, lastUpdated: new Date(2000, 1, 1).toDateString()}
    } else {
        let data = source as NewDataSetWithLabel
        return {totalGames: data.sample_count, lastUpdated: data.date}
    }
}


export type TPatchVersionInfo = { sample_count: number, valid: boolean }
export type TPatchVersionInfoCollection = { [version: string]: TPatchVersionInfo }