import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';

// import TrajanusRoman from "./fonts/TrajanusRoman.ttf"

import "./index.css"

ReactDOM.render(
    <React.Fragment>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>
        <App/>
    </React.Fragment>,
    document.getElementById('root'),
);
