// Various civ stats inferred based on data available on the frontend
// TODO: eventually move to the backend to add more stuff?

import {
    MetaData,
    NewDataTable,
    NewDataTableGroupWithSummary,
    TeamRMRawStatsTable,
    TeamRMStatsDataTable
} from "../../Data/ModelGenerated";
import {Direction, GetMapType} from "./DerivedStatsDefines";
import {ObjectTypeTagsWithData, TObjectTypeTagDataCollection} from "./ObjectTypeTags";
import {Stack} from "@mui/material";
import {propToPercent} from "../../Utils";
import {createContext, useContext, useState} from "react";

interface IDerivedCivOverviewTeamProps {

    data: TeamRMStatsDataTable
    toggleRelative?: () => void
}

interface IDerivedCivOverview1v1Props {

    data: NewDataTableGroupWithSummary
    maxItemCount?: number

}


type TRelativeStatsContext = { relative: boolean }
export const RelativeStatsContext = createContext<TRelativeStatsContext>({relative: false});


const calculateWeightedAverage = (values: { v: number, p: number }[], adjustBy?: number) => {

    const weightedValuesSum = values.reduce((acc, value, index) => acc + value.v * value.p, 0);
    const weightsSum = values.reduce((acc, value) => acc + value.p, 0);

    if (adjustBy !== undefined) {
        const v = weightedValuesSum / weightsSum;
        return 0.5 - adjustBy + v;
    } else {
        return weightedValuesSum / weightsSum;
    }
}

type TMapsData = {
    n: number,
    win_rate: number,
    types: Direction[]


    adj_win_rate?: number | undefined
};

const EXCLUDE_TYPES: Direction[] = [Direction.WoodCenter, Direction.WoodSides]

export const GetCivMapTypesStrWeak = (data: TeamRMStatsDataTable) => {

    type TMapsDataCollection = {
        [key: number]: TMapsData
    }

    const relativeStatsCtx = useContext(RelativeStatsContext);
    const calculateNormalizedScorePerType = (_data: TMapsDataCollection) => {

        const statsByType: {
            [type: string]: {
                all: { v: number, p: number }[],
                mean: number,
                mean_adj?: number,
                n: number
            }
        } = {}

        const totalN = Object.values(_data).reduce((a, b) => a + b.n, 0);

        for (const _mapId of Object.keys(_data)) {
            const mapId = Number.parseInt(_mapId);
            const d = _data[mapId];

            // if (d.n > 1000) {
            for (const type of d.types) {
                if (EXCLUDE_TYPES.includes(type))
                    continue;

                if (statsByType[type] === undefined) {
                    statsByType[type] = {all: [], mean: 0, n: 0}
                }

                const prop = d.n / totalN;
                // const prop = Math.log(d.n) / Math.log(totalN);
                statsByType[type].all.push({v: d.win_rate, p: prop})
                statsByType[type].n += d.n;
            }
            // }
        }

        for (const t of Object.keys(statsByType)) {
            statsByType[t].mean = calculateWeightedAverage(statsByType[t].all)

            if (relativeStatsCtx.relative) {
                statsByType[t].mean_adj = calculateWeightedAverage(statsByType[t].all, data.summary?.win_rate)
            }
            statsByType[t].mean = calculateWeightedAverage(statsByType[t].all)
        }

        return statsByType
    }

    const mapsTable = data.tables[0] as TeamRMRawStatsTable;

    const mapsDataWithType: TMapsDataCollection = {}

    for (const mapId of Object.keys(mapsTable.data)) {

        const row = mapsTable.data[mapId] as TMapsData;
        const mapTypes = GetMapType(Number.parseInt(mapId));

        mapsDataWithType[Number.parseInt(mapId)] = {...row, types: mapTypes}
    }

    const scores = calculateNormalizedScorePerType(mapsDataWithType);

    const objArr = Object.keys(scores).map((s) => {
        return {type: s, ...scores[s]}
    })


    const filteredMinSize = objArr.filter(value => value.n > 150);
    filteredMinSize.sort((a, b) => b.n - a.n);

    const best: TObjectTypeTagDataCollection = {}
    const worst: TObjectTypeTagDataCollection = {}

    const getLabel = (w: number, n: number) => {
        return `Win Rate: ${propToPercent(w, 1)}% based on ${n} games`
    }

    filteredMinSize.slice(0, 5).forEach((v) => {
        best[v.type] = {label: getLabel(v.mean, v.n), mean_win_rate: v.mean, mean_adj: v.mean_adj, n: v.n}
    })
    // objArr.slice(0, 4).forEach((v) => {
    //     best[v.type] = {label: getLabel(v.mean, v.n), mean_win_rate: v.mean, n: v.n}
    // })
    // objArr.slice(-4).forEach((v) => {
    //     worst[v.type] = {label: getLabel(v.mean, v.n), mean_win_rate: v.mean, n: v.n}
    // })

    return {types: best}
    // return {best: best, worst: worst}
}

export const DerivedCivOverview1v1 = (props: IDerivedCivOverview1v1Props) => {


    const [relative, setRelative] = useState(false);

    const tbl: TeamRMRawStatsTable = {
        data: {}
    }


    if (props.data.tables && props.data.tables.map_type && props.data.tables.map_type) {
        const sourceTable: NewDataTable = props.data.tables.map_type as NewDataTable
        for (const mapName of Object.keys(sourceTable.data)) {
            const mapData = sourceTable.data[mapName];

            if (!Array.isArray(mapData)) {
                const md = mapData.meta_data as MetaData;
                if (md && md.icon_data) {
                    const row: TMapsData = {
                        n: mapData.n,
                        types: GetMapType(Number.parseInt(md.icon_data.key)),
                        win_rate: mapData.win_rate,
                        adj_win_rate: relative ? props.data.win_rate : undefined
                    }

                    tbl.data[md.icon_data.key] = row;
                }
            }
        }
    }

    const converted: TeamRMStatsDataTable = {
        tables: [
            tbl
        ],
        // @ts-ignore
        summary: {
            win_rate: props.data.win_rate
        }
    }
    return <RelativeStatsContext.Provider value={{relative: relative}}>
        <DerivedCivOverviewTeam data={converted}

                                toggleRelative={() => {
                                    setRelative(!relative)
                                }}/>
    </RelativeStatsContext.Provider>


}
export const DerivedCivOverviewTeam = (props: IDerivedCivOverviewTeamProps) => {
    const data = GetCivMapTypesStrWeak(props.data)

    return <Stack direction={"column"} spacing={1} sx={{width: "100%"}}>
        <Stack direction={"row"}>
            {/*<Typography variant={"subtitle2"}>Maps Most Popular on:</Typography>*/}
            <ObjectTypeTagsWithData data={data.types} toggleRelative={props.toggleRelative}/>
        </Stack>
        {/*<Stack direction={"row"}>*/}
        {/*    <Typography>Worst:</Typography>*/}

        {/*    <ObjectTypeTagsWithData data={data.worst}/>*/}
        {/*</Stack>*/}
    </Stack>
}