import React from "react";
import {useContext, useState} from "react";
import {AppSettingsContext, TDefaultDataView} from "../../App";
import {
    DataRendererExtensions, EGridOrDataTableOrChartRendererType,
    GetGridOrDataTableOrChartRendererControls, GetGridOrDataTableOrChartRendererControlsNextNode,
    IPathObjectExtension,
    IViewInfoExtensions, TGridOrDataTableOrChartRendererProps
} from "../DefaultDataRenderer";
import {DefaultDataTableForNewDataTable, IDataTableForNewNewDataTable} from "./DataTable";
import {Box, Button, IconButton, Typography} from "@mui/material";
import TableRowsSharpIcon from '@mui/icons-material/TableRowsSharp';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import {ObjectScatterPlot} from "../ObjectScatterPlot";
import {DataTableWrapper} from "./DataTableWrapper";
import {GetDefaultMetaData} from "../../Utils";
import {GridViewForTable, GridViewForTableGroup} from "../Grid/GridViewForTableGroup";
import {NewDataGroupWithLabel, NewDataSampleWithSummary, TableGroup, TTableGroup} from "../../Data/CheckType";


export type TTableOrGridOrScatterPlotRendererProps =
    IDataTableForNewNewDataTable
    & DataRendererExtensions
    & IViewInfoExtensions
    & IPathObjectExtension
    & TGridOrDataTableOrChartRendererProps

export function TableOrGridOrScatterPlotRenderer(props: TTableOrGridOrScatterPlotRendererProps) {


    const appSettings = useContext(AppSettingsContext)
    const [_expanded, _setExpanded] = useState<boolean>(false);
    const iconsForMode = GetGridOrDataTableOrChartRendererControls(props.supportedTypes)

    // const getDefaultMode = () => {
    //     return appSettings.getDataViewSessionMode() === "scatter" ? "scatter" : "table"
    // }
    const [defaultDataMode, setDefaultDataMode] = useState<TDefaultDataView>(appSettings.getDataViewSessionMode());

    const getDefaultDataMode = () => {

        if (iconsForMode[defaultDataMode] === undefined || !iconsForMode[defaultDataMode].enabled) {
            for (const k of Object.keys(iconsForMode)) {
                if (iconsForMode[k].enabled) {
                    return k as TDefaultDataView;
                }
            }
        }
        return defaultDataMode
    }

    const [currentMode, setCurrentMode] = useState<TDefaultDataView>(getDefaultDataMode());

    const mode = currentMode;// !== "grid" ? currentMode : "table";

    const setMode = setCurrentMode;//appSettings.setDataViewSessionMode;

    const nextMode: TDefaultDataView = GetGridOrDataTableOrChartRendererControlsNextNode(mode, iconsForMode);

    // const [mode, setMode] = useState<"scatter" | "table">(getDefaultMode())

    // const controlMode = <IconButton onClick={() => {
    //     setMode(mode === "scatter" ? "table" : "scatter")
    // }}>
    //     {mode === "table" ? <ScatterPlotIcon/> : <TableRowsSharpIcon/>}
    // </IconButton>


    const controlMode = <Box sx={{display: "flex"}}>
        {(nextMode !== mode) &&

            <IconButton onClick={() => {
                setMode(nextMode)
            }}>
                {iconsForMode[nextMode].icon}
            </IconButton>}

    </Box>


    let title = props.viewInfo.title;

    if (title === "maps_table") {
        title = "Maps"
    } else if (title === "civs_table") {
        title = "Civs"
    } else if (title === "civs_cross_stats_table") {
        title = "Against Other Civs";

        // Exclude self row from cross civs table
        let civKey = props.path.value[props.path.value.length - 1];

        let toDelete: string | undefined;
        for (let rowKey of Object.keys(props.data.data)) {
            let row = props.data.data[rowKey];


            if (GetDefaultMetaData(row.meta_data).icon_data?.key === civKey) {
                toDelete = rowKey;
            }
        }
        if (toDelete)
            delete props.data.data[toDelete];

        // props.data.
    }


    let content: JSX.Element
    if (mode === "table") {
        content = <DefaultDataTableForNewDataTable {...props}
                                                   nMin={props.viewInfo.nMin}
                                                   key={props.dataKey}
                                                   title={title}
                                                   expanded={{value: _expanded, set: _setExpanded}}
                                                   filterComponents={[controlMode]}
        />
    } else if (mode === "scatter") {

        // TODO: Figure out a way to split the container card into two
        content = <DataTableWrapper hideCard={true}
                                    expanded={{value: _expanded, set: _setExpanded}}
                                    filterComponents={[controlMode]}
            // filterComponents={_expanded ? [controlMode] : []}
                                    title={title}>
            {/*SCATTER!*/}
            <React.Fragment>
                <ObjectScatterPlot
                    showProp0={props.showProp0}
                    // key={props.dataKey}
                    detailsView={true}
                    minSizeFilter={props.viewInfo.nMin !== undefined ? props.viewInfo.nMin : 200}
                    resolveDataPromise={props.resolveDataPromise}
                    // key={props.dataKey}
                    dataKey={props.dataKey}
                    baselineDataProviders={[]} path={props.path}
                    data={props.data}
                    openItemOnSelect={true}
                />

                <Typography variant={"subtitle2"} fontSize={"0.75em"} marginLeft={"2rem"} marginBottom={"1rem"}>
                    {`*items with 200 or less games are greyed out`}
                    {/*{`*${title.toLowerCase()} with 200 or less games are greyed out`}*/}
                </Typography>
            </React.Fragment>

        </DataTableWrapper>
    } else {
        content = <DataTableWrapper hideCard={true}
                                    expanded={{value: _expanded, set: _setExpanded}}
                                    filterComponents={[controlMode]}
                                    title={title}>
            {/*SCATTER!*/}

            <GridViewForTable dataKey={props.dataKey}
                              resolveDataPromise={props.resolveDataPromise}
                              path={props.path}
                              data={props.data}/>

        </DataTableWrapper>

    }

    return <Box>
        {content}
    </Box>
    //     let content: JSX.Element
    // if (mode === "table") {
    //     content = <DefaultDataTableForNewDataTable {...props}
    //         expanded={true}
    //                                                // independent={true}
    //                                                filterComponents={[controlMode]}
    //     />
    // } else {
    //     content = <ObjectScatterPlot baselineDataProviders={[]} path={props.path} data={props.data}/>
    // }
    //
    //
    // return <Box>
    //     <DataTableWrapper filterComponents={[controlMode]} title={props.viewInfo.title}>
    //         {content}
    //     </DataTableWrapper>
    // </Box>

}