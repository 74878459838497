import React, {useState} from 'react';
import {DEFINES} from "../Data/Defines";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {IconData, IconGroup} from "../Data/ModelGenerated";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import {Box, Stack, Tooltip, Typography} from '@mui/material';

import styles from "./ObjectIcon.module.scss"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import AddIcon from '@mui/icons-material/Add';
import {IsDev} from "../Data/config";

export enum ObjectIconTypesEnum {
    Research = "researchEvents",
    Strategy = "playerStrategies",
    CustomEvent = "customEvents",
    PlacedBuilding = "placedBuildingEvents",
    QueuedUnit = "queuedUnitsEvents",
    Civ = "civilizations",
    Object = "objects"
}

export function GetLabelForType(type: ObjectIconTypesEnum) {
    if (type === ObjectIconTypesEnum.Research) {
        return "Tech"
    }
    if (type === ObjectIconTypesEnum.Strategy) {
        return "Strategies"
    }
    if (type === ObjectIconTypesEnum.CustomEvent) {
        return "Other"
    }
    if (type === ObjectIconTypesEnum.PlacedBuilding) {
        return "Buildings"
    }
    if (type === ObjectIconTypesEnum.QueuedUnit) {
        return "Units"
    }
    return "UNDEFINED"
}

export type ObjectIconTypesEnumKeys = keyof typeof ObjectIconTypesEnum
export type TypeMap = { [P in ObjectIconTypesEnumKeys]: string; } // will have strongly typed keys


// export const FALLBACK_ICONS = {ObjectIconTypesEnum.Research : "234"}

export const FALLBACK_ICONS = {
    "researchEvents": ShoppingCartIcon,
    "playerStrategies": ShoppingCartIcon,
    "customEvents": ShoppingCartIcon,
    "placedBuildingEvents": ShoppingCartIcon,
    "queuedUnitsEvents": ShoppingCartIcon,
}

export interface IObjectIconProps {
    data: IconData | { country: string },
    count?: number
    time?: number
    size?: number
    borderless?: boolean
    tooltip?: { show: boolean, value?: string }

    underLabelName?: boolean
}

export interface IObjectIconWrapperProps extends Omit<IObjectIconProps, "data"> {
    data: IconData | IconData[] | [IconData[], IconData[]] | { country: string }
}


export const GetIconUrl = (data: IconData) => {
    let url = `https://cdn.jsdelivr.net/gh/qwyt/aoe2-icon-resources/${data.group}/${parseInt(data.key).toString()}.png`
    return url
}

export function ObjectIcon(props: IObjectIconProps) {

    let freeHeight = false;
    const [failedToLoadImg, setFailedToLoadImg] = useState(false);
    //TODO add a switch in app context
    const DEBUG_MODE = false
    // return <div>{props.tooltip}</div>

    let fileName = "-1"
    let url: string;
    let iconData: IconData | undefined;
    if (props.data.hasOwnProperty("country")) {
        freeHeight = true;
        // @ts-ignore
        url = `https://flagcdn.com/w320/${props.data.country.toLowerCase()}.jpg`
    } else {
        iconData = props.data as IconData;
        url = GetIconUrl(iconData);
    }

    let itemLabel: string = "";

    try {
        // @ts-ignore
        itemLabel = DEFINES[props.data.group][props.data.key]
    } catch (ex) {
        // @ts-ignore
        itemLabel = iconData ? iconData.key : props.data.country
    }

    let size = props.size ? props.size : 32

    let item: any;

    if (props.data.hasOwnProperty("country")) {
        item = <img
            src={url}
            width="160"
            // height="120"
            // @ts-ignore
            alt={props.data.country}/>

    } else {
        item = <img className={styles.image} src={url} width={size} height={freeHeight ? undefined : size}
                    onError={() => {
                        setFailedToLoadImg(true)
                    }}/>
    }

    if (!props.borderless) {
        item = <Paper variant="outlined" className={styles.imgContainer}
                      style={{height: freeHeight ? undefined : size, width: size}}>
            {failedToLoadImg ? <ErrorOutlineIcon/> : item}
        </Paper>
    }

    // @ts-ignore
    if (props.count !== undefined && props.count > 1) {
        item = <Badge badgeContent={props.count} color="primary">
            {item}


        </Badge>
    }


    // let tooltipContent = props.tooltip ? props.tooltip : props.data.description ? props.data.description : itemLabel;// `${props.time} ${itemLabel} (${props.data.group}/${fileName}) ${JSON.stringify(props.data)}`
    //
    // if (DEBUG_MODE) {
    //     tooltipContent = `${props.tooltip} <br> ${props.data.description} ${props.time} ${itemLabel} <br> (${props.data.group}/${fileName}) ${JSON.stringify(props.data)}`
    // }
    let tooltipContent = "Tooltip: TODO"


    if (props.tooltip?.show) {
        return <Stack sx={{}}>

            <Tooltip title={props.tooltip.value ? props.tooltip.value : tooltipContent}>
                {/*<Paper variant="outlined" elevation={3}>*/}
                {item}
                {/*</Paper>*/}
            </Tooltip>
        </Stack>
    }

    if (props.underLabelName && props.data && props.data.hasOwnProperty('description')) {

        const id = props.data as IconData;
        return <Stack sx={{marginRight: "0.35rem", minWidth: "62px"}}>
            <Stack direction={"row"} sx={{justifyContent: "space-around"}}>
                {item}
            </Stack>
            <Stack direction={"row"} sx={{justifyContent: "space-around", maxWidth: "62px"}}>

                <Typography
                    variant={"subtitle2"}
                    sx={{
                        fontSize: "0.785rem"
                        // marginLeft: useDesktopLayout ? "1rem" : "2px",
                        // color: isLink ? "primary.dark" : undefined
                    }}>
                    {id.description}
                </Typography>
            </Stack>
        </Stack>
    } else {
        return <div className={styles.container}>
            {/*<Paper variant="outlined" elevation={3}>*/}
            {item}
            {IsDev() && iconData?.key}
            {/*</Paper>*/}
        </div>
    }

}


export function ObjectIconWrapper(props: IObjectIconWrapperProps) {

    const buildItemList = (data: IconData[]) => {
        // let items = data.map(i => <ObjectIcon {...props} data={i as IconData} underLabelName/>)
        let items: JSX.Element[] = []
        for (let item of data) {

            items.push(<ObjectIcon {...props} data={item as IconData} underLabelName/>)
            if (item !== data[data.length - 1]) {
                items.push(
                    <Stack direction={"column"} sx={{
                        justifyContent: "space-around",
                        marginLeft: " -15px",
                        marginRight: " -10px",
                        marginBottom: "7.5px"
                    }}>
                        <AddIcon/>
                    </Stack>)
            }
        }
        return items
    }

    if (Array.isArray(props.data)) {
        if (Array.isArray(props.data[0])) {
            let data = props.data as [IconData[], IconData[]]
            let teamA = buildItemList(data[0])
            let teamB = buildItemList(data[1])

            // let teamB = data[1].map(i => <ObjectIcon {...props} data={i as IconData} underLabelName/>)
            // let teamA = data[0].map(i => <ObjectIcon {...props} data={i as IconData} underLabelName/>)
            // let teamB = data[1].map(i => <ObjectIcon {...props} data={i as IconData} underLabelName/>)
            return <Stack direction={"row"}>
                <Stack direction={"row"}>{teamA}</Stack>
                <Stack direction={"column"}
                       sx={{
                           justifyContent: "space-around",
                           marginLeft: "0.125rem",
                           marginRight: "0.125rem"
                       }}>
                    <Typography
                        variant={"subtitle2"}
                        sx={{
                            fontSize: "0.895rem",
                            marginBottom: "0.205rem"
                            // marginLeft: useDesktopLayout ? "1rem" : "2px",
                            // color: isLink ? "primary.dark" : undefined
                        }}>
                        vs.
                    </Typography>
                </Stack>

                <Stack direction={"row"}>{teamB}</Stack>
            </Stack>
        } else {
            let data = props.data as IconData[]
            // let items = data.map(i => <ObjectIcon {...props} data={i as IconData} underLabelName/>)
            let items = buildItemList(data)
            // for (let item of data) {
            //
            //     items.push(<ObjectIcon {...props} data={item as IconData} underLabelName/>)
            //     if (item != data[items.length - 1]) {
            //         items.push(<Typography>+</Typography>)
            //     }
            // }


            return <Stack direction={"row"}>{items}</Stack>
        }
    } else {
        return <ObjectIcon {...props as IObjectIconProps}/>
    }

}

