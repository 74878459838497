import * as React from 'react';
import JSONTree from 'react-json-tree';
import {GridViewForTableGroup} from "./Grid/GridViewForTableGroup";
import {DataModelType, TEloBracket} from "../Data/CheckType";
import {GridItem} from "./Grid/GridItem";
import {useContext, useEffect, useState} from "react";
import {Box, IconButton, Typography} from "@mui/material";

import AppsIcon from '@mui/icons-material/Apps';
import TableRowsSharpIcon from '@mui/icons-material/TableRowsSharp';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

import DataTable, {
    DataTableForDataGroups,
    DataTableForPlayersList,
    IPaginationRequest,
    ISortRequest
} from "./Table/DataTable";
import {IPatchHistoryChartProps, ItemDetailsView} from './ItemDetails/ItemDetailsView';
import {DescriptiveStatsTablePromise, NewDataSet, TableBytesWrapper, ViewRequestData} from "../Data/ModelGenerated";
import {getLabelForPath, TTableServersude} from "../Internal/DataProvider";
import {DataProviderResponseMetaData} from "../Data/Model";
import {ObjectScatterPlot, ScatterPlotBaselineProvider} from "./ObjectScatterPlot";
import {AppSettingsContext, IAppSettingsContext, TDefaultDataView} from "../App";
import {TBaselineTableGroupSampleDelegate} from "../Data/BaselineProviders";
import {DatasetSummaryViewRenderer} from "./DatasetSummary/DatasetSummaryViewRenderer";
import {TeamStatsSummaryView} from "./TeamStatsView/TeamStatsViewRenderer";

export interface IDefaultDataRendererProps {
    dataKey: string
    data: any
    itemType: DataModelType
    disableGrid?: boolean
    disabledChart?: boolean
    pagination?: IPaginationRequest
    serversideSort?: ISortRequest
    tableServerside?: TTableServersude

    metaData?: DataProviderResponseMetaData
    getBaselineData?: TBaselineTableGroupSampleDelegate

}

export interface IPathObjectExtension {
    path: { value: string[], set: (path: string[]) => void }
    dataKey: string

    resolveDataPromise?: (promiseData: DescriptiveStatsTablePromise) => Promise<TableBytesWrapper>
}

export interface DataRendererExtensions {

    showPreview?: boolean
    nMin?: number
    compactView?: boolean
    pagination?: IPaginationRequest
    serversideSort?: ISortRequest
    filterComponents?: JSX.Element[]

    tableServerside?: TTableServersude
    baselineEloBracket?: TEloBracket
    getBaselineData?: TBaselineTableGroupSampleDelegate

    disableCollapse?: boolean

    showProp0?: boolean

    viewInfo?: TViewInfo;

}

export type TViewInfo = { title: string, showItemTags?: boolean, nMin?: number, noWinRate?: boolean }

export interface IViewInfoExtensions {
    viewInfo: TViewInfo
    viewRequestData?: ViewRequestData

}

export enum EGridOrDataTableOrChartRendererType {
    None = 0,
    Table = 1 << 1, // 0001 -- the bitshift is unnecessary, but done for consistency
    Scatter = 1 << 2,     // 0010
    Grid = 1 << 3,    // 0100
    // Boring = 1 << 3,   // 1000
    All = ~(~0 << 4)   // 1111
}

export type TIconsForNodeCollection = { [mode: string]: { enabled: boolean, icon: JSX.Element } };
export const GetGridOrDataTableOrChartRendererControls = (supportedTypes: EGridOrDataTableOrChartRendererType) => {
    const iconsForMode: TIconsForNodeCollection = {
        "grid": {
            enabled: (supportedTypes & EGridOrDataTableOrChartRendererType.Grid) === EGridOrDataTableOrChartRendererType.Grid,
            icon: <AppsIcon/>
        },
        "table": {
            enabled: (supportedTypes & EGridOrDataTableOrChartRendererType.Table) === EGridOrDataTableOrChartRendererType.Table,
            icon: <TableRowsSharpIcon/>
        },
        "scatter": {
            enabled: (supportedTypes & EGridOrDataTableOrChartRendererType.Scatter) === EGridOrDataTableOrChartRendererType.Scatter,
            icon: <ScatterPlotIcon/>
        },

    }

    return iconsForMode;
}

export const GetGridOrDataTableOrChartRendererControlsNextNode = (startMode: TDefaultDataView, iconsForMode: TIconsForNodeCollection) => {
    let selectedMode = startMode
    let i = 0;
    do {
        const currentModeIndex = Object.keys(iconsForMode).indexOf(selectedMode);
        let nextModeKey = currentModeIndex + 1 >= Object.keys(iconsForMode).length ? 0 : currentModeIndex + 1;
        selectedMode = Object.keys(iconsForMode)[nextModeKey] as TDefaultDataView

        if (selectedMode === startMode) {
            continue;
        }

        if (iconsForMode[selectedMode].enabled) {
            return selectedMode;
        }

        i++;
    } while (i < 20)


    return selectedMode
}

export type TGridOrDataTableOrChartRendererProps = { supportedTypes: EGridOrDataTableOrChartRendererType }

export function GridOrDataTableOrChartRenderer(props: IDefaultDataRendererProps & IPathObjectExtension & IViewInfoExtensions & TGridOrDataTableOrChartRendererProps) {

    const settings = useContext(AppSettingsContext)

    const getDefaultMode = () => {
        let mode = settings.getDataViewSessionMode()

        if (mode === "grid" && props.disableGrid) {
            mode = "table"
        } else if (mode === "scatter" && props.disableGrid) {
            mode = props.disableGrid ? "table" : "grid"
        }
        return mode
    }


    useEffect(() => {
        // alert("RENDER GridOrDataTableOrChartRenderer")
    }, [])

    // let [mode, setMode] = useState<TDefaultDataView>(getDefaultMode())
    // let [useGrid, setUseGrid] = useState(!props.disableGrid)

    let mode = settings.getDataViewSessionMode()
    const setMode = settings.setDataViewSessionMode;

    const iconsForMode = GetGridOrDataTableOrChartRendererControls(props.supportedTypes)

    // For now disable the scatter plot for maps, because the sim. calculation is broken.
    if (props.data.name === "map_type" && mode === "scatter") {
        mode = "grid";
        delete iconsForMode["scatter"];
    }


    const nextMode: TDefaultDataView = GetGridOrDataTableOrChartRendererControlsNextNode(mode, iconsForMode);


    let baselineDataProviders: ScatterPlotBaselineProvider[] | undefined = props.getBaselineData ? [{
        label: "Elo Brackets",
        key: "elo",
        getBaselineData: props.getBaselineData
    }] : []

    return <Box sx={{width: "100%"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
            {props.data.label && <Box alignSelf={"center"}><Typography variant={"h5"}
                                                                       component={"h2"}>{getLabelForPath(props.path.value)}</Typography>
            </Box>}
            <Box sx={{display: "flex"}}>
                {(nextMode !== mode) &&

                    <IconButton onClick={() => {
                        setMode(nextMode)
                    }}>
                        {iconsForMode[nextMode].icon}
                    </IconButton>}

            </Box>
        </Box>
        {/*<JSONTree data={props.data}/>*/}
        {mode === "scatter" ? <ObjectScatterPlot
            resolveDataPromise={props.resolveDataPromise}

            key={props.dataKey}
            dataKey={props.dataKey}
            // key={props.data}
            baselineDataProviders={baselineDataProviders}
            data={props.data}
            path={props.path}/> : (mode === "grid" ?
            <GridViewForTableGroup dataKey={props.dataKey}
                                   resolveDataPromise={props.resolveDataPromise}

                                   path={props.path} data={props.data}/> :
            <DataTableForDataGroups
                dataKey={props.dataKey}
                resolveDataPromise={props.resolveDataPromise}
                nMin={props.viewInfo.nMin}
                path={props.path}
                viewInfo={props.viewInfo}
                serversideSort={props.serversideSort}
                tableServerside={props.tableServerside}
                data={props.data}
                pagination={props.pagination}/>)}
    </Box>
}

export function DefaultDataRenderer(props: IDefaultDataRendererProps & IPathObjectExtension) {

    let item_key: string = props.path.value.join("-")
    const item_render_key = props.dataKey + ":" + item_key
    // const item_render_key = window.btoa(unescape(encodeURIComponent(`${props.dataKey}-${item_key}`)));

    // if (props.itemType === "TeamRMStatsDataView"){
    //     // console.error("TeamRMStatsDataView not Implemented!")
    //     // return <Box>
    //     //     TODO: TeamRMStatsDataView
    //     // </Box>
    //     return <TeamStatsSummaryView {...props} key={item_render_key}/>
    // }
    // else
    if (props.itemType === "NewDataTableGroupWithSummary") {
        return <ItemDetailsView {...props} key={item_render_key}/>
    } else if (props.itemType === "NewDataGroup") {
        let viewInfo = {
            title: props.itemType
        }

        if (props.data.hasOwnProperty("name")) {
            viewInfo.title = props.data.name;
        }

        if (props.data.name === "Players") {

            return <DataTableForPlayersList
                resolveDataPromise={props.resolveDataPromise}

                dataKey={props.dataKey}
                path={props.path}
                viewInfo={viewInfo}
                serversideSort={props.serversideSort}
                tableServerside={props.tableServerside}

                data={props.data}
                pagination={props.pagination}
                key={item_render_key}
            />

        } else {
            return <React.Fragment>
                <GridOrDataTableOrChartRenderer viewInfo={viewInfo} {...props}
                                                supportedTypes={EGridOrDataTableOrChartRendererType.All}/>
                {/*<JSONTree data={props.data}/>*/}
            </React.Fragment>
        }
    } else if (props.itemType === "NewDataSet") {
        return <DatasetSummaryViewRenderer data={props.data as NewDataSet} key={item_render_key}/>
    } else if (props.itemType === "NewDataSetWithLabel") {
        if (props.data.hasOwnProperty("data_view") && Object.keys(props.data["data_view"]).length > 0) {
            return <TeamStatsSummaryView {...props} key={item_render_key}/>
        }
    }


    return <div>type:[{props.itemType}]
        <div><JSONTree data={props.data}/></div></div>
}