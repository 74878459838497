export enum Direction {
    Land = "Land",
    Hybrid = "Hybrid",
    Water = "Water",

    Open = "Open",
    Closed = "Closed",
    SemiOpen = "Semi Open",

    Nomad = "Nomad",
    MegaRandom = "M.Random",

    CenterResources = "Center Res.",
    WoodSides = "Wood on Sides",
    WoodCenter = "Wood in Center",
    Hunt = "Plenty Hunt",

    FoodHeavy = "Food Heavy",
    LowFood = "Low Food",

    VeryAggresive = "Aggressive",
    ManyRelics = "Relics",
    Elevation = "Elevation",

    Quirky = "Quirky",

    Amphibious = "Amphibious"
}


const _Maps: { [id: number]: Direction[] } = {

    9: [Direction.Land, Direction.Open], //Arabia
    74: [Direction.Land, Direction.Open], //Lombardia
    168: [Direction.Land, Direction.Open, Direction.Quirky], //Aftermath

    150: [Direction.Land, Direction.Open, Direction.VeryAggresive], //Atacama
    173: [Direction.Land, Direction.Open, Direction.ManyRelics], //Sacred Springs
    124: [Direction.Land, Direction.Open, Direction.Elevation], //Mountain Ridge


    17: [Direction.Land, Direction.Open, Direction.Elevation, Direction.CenterResources], //Mountain Ridge
    122: [Direction.Land, Direction.Open, Direction.LowFood], //Mountain Ridge
    140: [Direction.Land, Direction.Open, Direction.Hybrid], //Mountain Ridge


    27: [Direction.Land, Direction.SemiOpen, Direction.FoodHeavy], //Yucatan
    31: [Direction.Land, Direction.SemiOpen], //Oasis
    76: [Direction.Land, Direction.Open, Direction.FoodHeavy], //Yucatan


    71: [Direction.Land, Direction.Open, Direction.CenterResources, Direction.Elevation], //Golden Pit

    33: [Direction.Hybrid, Direction.Open, Direction.Nomad], //Nomad
    149: [Direction.Land, Direction.Open, Direction.Nomad, Direction.WoodSides], //African Clearing
    75: [Direction.Land, Direction.Open, Direction.Nomad, Direction.WoodSides, Direction.Hunt], //African Clearing

    20: [Direction.Hybrid, Direction.Closed, Direction.Water], //Medd
    10: [Direction.Water], //Island
    19: [Direction.Water], //Island
    165: [Direction.Water], //Northern Isles

    29: [Direction.Land, Direction.Closed], //Arena
    16: [Direction.Land, Direction.Closed], //Fortress
    12: [Direction.Land, Direction.Closed], //BF

    147: [Direction.Land, Direction.Closed], //Amazon Tunnel
    25: [Direction.Land, Direction.Hybrid], //Scandinavia

    72: [Direction.Land, Direction.SemiOpen], //Hideout
    123: [Direction.Land, Direction.SemiOpen, Direction.Elevation, Direction.LowFood], //Bogland

    77: [Direction.MegaRandom], //MegaRandom


    114: [Direction.Water], //Pacific Island
    23: [Direction.Water], //Team Island
    21: [Direction.Hybrid, Direction.Water], //Migration
    84: [Direction.Land, Direction.Elevation], //Mountain Pass
    148: [Direction.Hybrid, Direction.Land], //Coastal Forest
    86: [Direction.Hunt, Direction.Land], //Serengeti

    154: [Direction.Closed, Direction.Land], // Michi

    141: [Direction.Open, Direction.Nomad, Direction.Land, Direction.Hunt], //Land Nomad

    11: [Direction.Hybrid, Direction.Closed, Direction.Water], // Baltic

    169: [Direction.Closed, Direction.Land, Direction.WoodCenter], // Enclosed

    115: [Direction.Hybrid, Direction.Amphibious], // Sandbank

    // 9: [Direction.Land, Direction.Open], //Arabia
    // 9: [Direction.Land, Direction.Open], //Arabia
    // 9: [Direction.Land, Direction.Open], //Arabia
}

export const GetMapType = (mapId: number) => {


    return _Maps[mapId] ? _Maps[mapId].sort() : []
}