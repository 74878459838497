import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';
import white from '@mui/material/colors/red';
import {AppSettingsContext} from "./App";
import {useTheme} from "styled-components";
import DropdownMenuPicker, {TDropdownMenuPickerValue} from "./Components/DropdownMenuPicker";
import {useEffect, useRef, useState} from "react";
import {MainContentView} from "./Components/MainContentView";
import {DataProvider} from "./Internal/DataProvider";
import {NewDataSet} from "./Data/ModelGenerated";
import {ISideBarNavData, Sidebar} from "./Components/Sidebar";
import {Route, HashRouter as Router, Switch, useHistory, Redirect} from 'react-router-dom';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsIcon from '@mui/icons-material/Settings';
import {AppSettingsPanel} from "./Components/AppSettingsPanel";
import {TEloBracket, TPatchVersionInfoCollection, TStatsTableNames, TTeamEloBracket, TTeamSize} from "./Data/CheckType";
import {Stack, Tooltip, useMediaQuery} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import {DataSamplingMessage, DataSamplingMessageTooltip} from "./Components/DatasetSummary/DatasetSummaryViewRenderer";
import {InitMetaDataResolverCached} from "./Components/TeamStatsView/CachedMetaDataProvider";

// import "../public/index.css"


function Copyright(props: any) {
    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid item md={6} lg={6} xl={6} xs={12}>
                        <Typography variant="body2" color="text.secondary" align="left" {...props}>
                            {/*Total Matches Loaded: {props.datasetInfo.totalGames}*/}
                        </Typography>
                    </Grid>
                    <Grid item md={6} lg={6} xl={6} xs={12}>
                        <Typography variant="body2" color="text.secondary" align="right" {...props}>
                            Data last updated: {props.datasetInfo.lastUpdated}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Typography variant="body2" color="text.secondary" align="left" {...props}>
                Age of Empires II: HD© and Age of Empires II: Definitive Edition© Microsoft Corporation. This site was
                created under Microsoft's "Game Content Usage Rules" using assets from Age of Empires II: Definitive
                Edition, and it is not endorsed by or affiliated with Microsoft.
                {/*{'Copyright © '}*/}
                {/*<Link color="inherit" href="https://mui.com/">*/}
                {/*    Your Website*/}
                {/*</Link>{' '}*/}
                {/*{new Date().getFullYear()}*/}
                {/*{'.'}*/}
            </Typography>
        </Box>
    );
}

const drawerWidth: number = 220;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(open && {
    //     marginLeft: drawerWidth,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // }),
}));
// sx={{
//   width: drawerWidth,
//   flexShrink: 0,
//   [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
// }}

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            flexShrink: 0,
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


// const mdTheme = createTheme({
//     palette: {
//         mode: "light",
//         primary: blue,
//         secondary: red
//     },
// });

const DEFAULT_PATCH_VERSION = "latest"
// const DEFAULT_ELO_BRACKET = ">1600"
const DEFAULT_ELO_BRACKET: TEloBracket = "All"

function DashboardRouteWrapper() {

    const [_disabledEloBracketPicker, setDisabledEloBracketPicker] = useState(false)
    const [_disabledPatchPicker, setDisabledPatchPicker] = useState<boolean | undefined>(undefined)
    const [_disabledTeamSizePicker, setDisabledTeamSizePicker] = useState<boolean | undefined | "hidden">(undefined)


    return <Router basename={"/data"}>
        <Switch>
            {/*// @ts-ignore*/}

            {/*// @ts-ignore*/}
            {/*// @ts-ignore*/}
            <Route exact path="/">
                {/*RM_Stats/latest/>1600/grouped_tables/civ*/}
                <Redirect to={`/RM_Stats/${DEFAULT_PATCH_VERSION}/${DEFAULT_ELO_BRACKET}/grouped_tables/civ`}/>
            </Route>

            {/*  // @ts-ignore*/}
            <Route path="/*"
                // @ts-ignore*/
                   render={(props: any) => {


                       let fullPath = props.match.params["0"].split("/") as string[]


                       const selectedDataset = fullPath[0] as TStatsTableNames
                       const isTeamDash = selectedDataset === "Team_RM_Stats";

                       const selectedPatchVersion = fullPath[1] as string
                       const selectedEloBracket = fullPath[2] as TEloBracket

                       const selectedTeamSize = fullPath[3] as TTeamSize


                       const localPath = fullPath.slice(3)


                       const checkIfDisablePickers = () => {
                           return localPath.length === 0

                           // return (selectedDataset === "RM_Stats") || (selectedDataset === "EmpireWars_Stats")
                       }


                       let disablePickers = checkIfDisablePickers();

                       const {disabledEloBracketPicker, disabledPatchPicker, disabledTeamSizePicker} = {
                           disabledEloBracketPicker: _disabledEloBracketPicker || disablePickers,
                           disabledPatchPicker: disablePickers ? true : (_disabledPatchPicker === undefined ? undefined : _disabledPatchPicker),
                           disabledTeamSizePicker: (isTeamDash ? (_disabledTeamSizePicker || disablePickers) : "hidden") as boolean | "hidden"

                       }


                       const replacePath = (newVals: { [key: number]: string | undefined }, clearLocalPath?: boolean) => {

                           let path = fullPath

                           for (let _i of Object.keys(newVals)) {
                               const i = parseInt(_i)
                               if (isNaN(i))
                                   return
                               let v = newVals[i]
                               path = [...path]

                               if (v !== undefined) {
                                   path[i] = v
                               } else {
                                   path.splice(i, 1)
                               }
                           }

                           if (clearLocalPath) {
                               // i.e. remove local path when swtiching between Team and RM datasets
                               // due incompatible nested data structure.
                               path.length = 3

                               // TODO HACK this is not great, we don't want to send user to /, rather to the default
                               // data view which is "All/grouped_tables/civ"
                               const isTeamDash = path[0] === "Team_RM_Stats";

                               if (!isTeamDash) {
                                   path.push("grouped_tables")
                                   path.push("civ")
                               } else {
                                   path.push("2v2")
                                   path.push("civilizations")
                               }

                           }

                           props.history.push("/" + path.join("/"))

                       }

                       const setSelectedDataset = (ds: TStatsTableNames) => {


                           // TODO, this should be defined in a better way somehow tied to TStatsTableNames

                           let setEloBracketTo: TTeamEloBracket | TTeamEloBracket | undefined;
                           let newPath: { [key: number]: string | undefined } = {}
                           let clearLocalPath = false;

                           if (selectedDataset === "Team_RM_Stats" && ds !== "Team_RM_Stats") {
                               newPath[2] = "All"
                               newPath[3] = undefined
                               clearLocalPath = true

                           } else if (ds === "Team_RM_Stats" && selectedDataset !== "Team_RM_Stats") {
                               let defaultTeamSize: TTeamSize = "2v2"
                               newPath[3] = defaultTeamSize
                               newPath[2] = "All"
                               clearLocalPath = true
                           }

                           newPath[0] = ds;
                           newPath[1] = "latest"

                           replacePath(newPath, clearLocalPath)
                       }
                       const setSelectedPatchVersion = (ds: string) => {
                           replacePath({1: ds})
                       }
                       const setEloBracket = (ds: TEloBracket | string) => {
                           replacePath({2: ds})
                       }

                       const setSelectedTeamSize = (ds: TTeamSize | string) => {
                           replacePath({3: ds})
                       }

                       const setLocalPath = (v: string[]) => {

                           // let tt = window.location.href
                           let fullPathTemp = props.match.params["0"].split("/") as string[]

                           let fullPath = props.history.location.pathname.split("/").slice(1) as string[]

                           let selectedDataset = fullPath[0] as TStatsTableNames
                           let selectedPatchVersion = fullPath[1] as string
                           let selectedEloBracket = fullPath[2] as TEloBracket


                           let newPath = [selectedDataset, selectedPatchVersion, selectedEloBracket, ...v]
                           let pathStr = "/" + newPath.join("/")
                           props.history.push(pathStr);

                       }

                       return <DashboardContent
                           dataset={{value: selectedDataset, set: setSelectedDataset}}
                           patchVersion={{value: selectedPatchVersion, set: setSelectedPatchVersion}}
                           eloBracket={{value: selectedEloBracket, set: setEloBracket}}
                           localPath={{value: localPath, set: setLocalPath}}

                           disabledEloBracketPicker={{
                               value: disabledEloBracketPicker,
                               set: setDisabledEloBracketPicker
                           }}
                           disabledPatchPicker={{value: disabledPatchPicker, set: setDisabledPatchPicker}}


                           teamSize={{value: selectedTeamSize, set: setSelectedTeamSize}}
                           disabledTeamSizePicker={{value: disabledTeamSizePicker, set: setDisabledTeamSizePicker}}/>
                   }
                   }/>
        </Switch>
    </Router>
}

interface IDashboardContentProps {
    dataset: { value: TStatsTableNames, set: (v: TStatsTableNames) => void }
    patchVersion: { value: string, set: (v: string) => void }
    eloBracket: { value: TEloBracket, set: (v: TEloBracket | string) => void }

    teamSize: { value: TTeamSize, set: (v: TTeamSize | string) => void }

    disabledEloBracketPicker: { value: boolean, set: (v: boolean) => void }
    disabledTeamSizePicker: { value: boolean | "hidden", set: (v: boolean | "hidden") => void }
    disabledPatchPicker: { value: boolean | undefined, set: (v: boolean) => void }

    localPath: { value: string[], set: (v: string[]) => void }
}

export type TQueryError = { error: string | number, message: string }
export type TQueryMessage = { progress: number, message: string, subLabel?: string, progressNext?: number }

function DashboardContent(props: IDashboardContentProps) {

    const useDesktopLayout = useMediaQuery('(min-width:600px)');


    const [_open, setOpen] = React.useState(useDesktopLayout);

    const toggleDrawer = () => {
        setOpen(!_open);
    };


    const [queryError, setQueryError] = useState<{ error: string | number, message: string } | undefined>()

    const drawerOpen = useDesktopLayout ? true : _open;

    // This is used when 'latest' version is queried instead of a specific number,
    // value is used to select the correct item in the dropdown (i.e. expect specific version with the latest tag)
    const [currentlyLoadedPatchVersionKey, setCurrentlyLoadedPatchVersionKey] = useState<undefined | string>();

    const open = true
    // const colorMode = React.useContext(ColorModeContext);
    const theme: any = useTheme();
    const appSettings = React.useContext(AppSettingsContext);

    // // const [selectedDataset, _dont_use] = useState<TStatsTableNames>(getInitialDataset());
    // const [selectedDataset, setSelectedDataset] = useState<TStatsTableNames | undefined>(undefined);

    // const [selectedPatchVersion, setSelectedPatchVersion] = useState<string | null>(null)

    // const [selectedEloBracketFilter, setSelectedEloBracketFilter] = useState<string | null>(null)

    const [availablePatchFilterValues, _setAvailablePatchFilterValues] = useState<TDropdownMenuPickerValue[]>([])
    const [availableEloBracketFilterValues, _setAvailableEloBracketFilterValues] = useState<TDropdownMenuPickerValue[]>([])
    // const [availableTeamSizeFilterValues, _setAvailableTeamSizeFilterValues] = useState<TTeamSize[]>([])

    const availableTeamSizeFilterValues: TDropdownMenuPickerValue[] = []

    // TODO this is wonky... oth this should be static value available on all datasets
    availableTeamSizeFilterValues.push({key: "2v2", label: "2v2"})
    availableTeamSizeFilterValues.push({key: "3v3", label: "3v3"})
    availableTeamSizeFilterValues.push({key: "4v4", label: "4v4"})

    // const [_disabledPatchPicker, setDisabledPatchPicker] = useState(false)


    const [disabledDatasetPicker, setDisabledDatasetPicker] = useState(false)
    //
    // const [_disabledEloBracketPicker, setDisabledEloBracketPicker] = useState(false)


    const [showSettingsPanel, setShowSettingsPanel] = useState(false)

    const [sidebarNavData, setSidebarNavData] = useState<ISideBarNavData>({
        datasetInfo: {
            lastUpdated: "",
            totalGames: 0
        }, topLevelItems: {}
    })

    let availableDatasets: TDropdownMenuPickerValue[] = [
        {
            label: "EW (1v1)",
            key: "EmpireWars_Stats",
            disabled: true
        },

        {
            label: "RM (1v1)",
            key: "RM_Stats",
            // disabled: true
        },

        {
            label: "RM (Team)",
            key: "Team_RM_Stats",
            disabled: false
        }


    ]


    const toolbarControlsMenus = [
        <DropdownMenuPicker
            fixedWidth={!useDesktopLayout}
            value={"aoe2"}
            onChangeValue={() => {
            }}
            disabled={false}
            disabledLabel={props.disabledEloBracketPicker.value ? "All" : undefined}
            // disabledLabel={props.disabledEloBracketPicker.value ? "All" : undefined}
            values={[{key: "aoe2", label: "AOE 2: DE", disabled: false},
                {key: "aoe4", label: "AOE IV (WIP)", disabled: true}]}
            label={"Game"}/>,

        <DropdownMenuPicker
            minWidth={115}
            fixedWidth={!useDesktopLayout}
            value={props.eloBracket.value === null ? "Loading..." : props.eloBracket.value}
            onChangeValue={props.eloBracket.set}
            disabled={props.disabledEloBracketPicker.value || availableEloBracketFilterValues.length === 0}
            disabledLabel={undefined}

            // disabledLabel={props.disabledEloBracketPicker.value ? "All" : undefined}

            values={availableEloBracketFilterValues}
            label={"Elo Bracket"}
            // dependencies={{check: window.location.href, disableOn: checkIfDisablePickers}}
        />,

        props.disabledTeamSizePicker.value !== "hidden" ? <DropdownMenuPicker
            fixedWidth={!useDesktopLayout}
            value={props.teamSize.value === null ? "Loading..." : props.teamSize.value}
            onChangeValue={props.teamSize.set}
            disabled={props.disabledTeamSizePicker.value === true || availableTeamSizeFilterValues.length === 0}
            disabledLabel={undefined}

            // disabledLabel={props.disabledEloBracketPicker.value ? "All" : undefined}

            values={availableTeamSizeFilterValues}
            label={"Team Size"}
            // dependencies={{check: window.location.href, disableOn: checkIfDisablePickers}}
        /> : undefined,


        <DropdownMenuPicker
            fixedWidth={!useDesktopLayout}
            // props.patchVersion.value = value in URL i.e. should be loaded
            // currentlyLoadedPatchVersionKey = currently loaded DS

            value={(() => {
                if (props.patchVersion.value === "latest" || props.disabledPatchPicker.value === undefined) {
                    if (currentlyLoadedPatchVersionKey === undefined || props.disabledPatchPicker.value === undefined) {
                        return "Loading"
                    } else {
                        return currentlyLoadedPatchVersionKey
                    }
                }
                return props.patchVersion.value
            })()}
            // value={((props.patchVersion.value === undefined) || (props.disabledPatchPicker.value === undefined)) ? "Loading..." : props.patchVersion.value}
            // value={((currentlyLoadedPatchVersionKey === undefined) || (props.disabledPatchPicker.value === undefined)) ? "Loading..." : currentlyLoadedPatchVersionKey}
            // value={props.patchVersion.value === null ? "Loading..." : props.patchVersion.value}
            onChangeValue={props.patchVersion.set}
            disabled={props.disabledPatchPicker.value || props.disabledPatchPicker.value === undefined || availablePatchFilterValues.length === 0}
            disabledLabel={props.disabledPatchPicker.value ? "All" : undefined}
            values={availablePatchFilterValues}
            label={"Patch"}
            // dependencies={{check: window.location.href, disableOn: checkIfDisablePickers}}
        />,
        // <Box>Patch: {currentlyLoadedPatchVersionKey} / {props.patchVersion.value}</Box>
        ,
        <DropdownMenuPicker
            fixedWidth={!useDesktopLayout}
            value={props.dataset.value ? props.dataset.value : "Placeholder"}
            disabled={disabledDatasetPicker || props.dataset.value === undefined}
            onChangeValue={(v) => {
                // props.patchVersion.set('latest')
                props.dataset.set(v as TStatsTableNames);

                // setSelectedDataset(v as TStatsTableNames, true);
            }}
            values={availableDatasets}
            label={"Dataset"}/>

    ]

    const toolbarButtons = [<IconButton sx={{ml: 1}} onClick={() => {
        appSettings.toggleColorMode()
    }} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon/> :
            <Brightness4Icon/>}
    </IconButton>,

        <IconButton aria-describedby={"settings-panel"} sx={{ml: 1}}
                    onClick={() => {
                        setShowSettingsPanel(!showSettingsPanel)
                    }} color="inherit">
            {showSettingsPanel ? <SettingsApplicationsIcon/> : <SettingsIcon/>}
        </IconButton>
    ]

    const appSettingsPanel = <AppSettingsPanel id={'settings-panel'}
                                               show={{
                                                   value: showSettingsPanel,
                                                   set: setShowSettingsPanel
                                               }}/>


    // @ts-ignore
    return <Box sx={{display: 'flex', flexDirection: useDesktopLayout ? "row" : "column"}}>
        <CssBaseline/>

        <AppBar position="fixed" open={true}>
            <Toolbar
                sx={{
                    // backgroundColor: "red",
                    justifyContent: "space-between",
                    pl: "5px",
                    pr: '0px', // keep right padding when drawer closed
                }}
            >
                <Stack direction={"row"} spacing={1} marginLeft={"5px"}>
                    <img style={{marginLeft: "0px", marginTop: "5px"}} width={42}
                         height={42}
                         src={process.env.PUBLIC_URL + '/app_icon.png'}/>

                    <Box
                        className={"headerTitle"}
                        whiteSpace={"nowrap"}
                        // fontFamily={"TrajanusRoman"}
                        // fontWeight={"700"}
                        fontSize={useDesktopLayout ? "20pt" : "16pt"}
                    >
                        AOE Stats Dashboard
                    </Box>
                </Stack>

                {useDesktopLayout && <Stack direction={useDesktopLayout ? "row" : "column"}
                    // sx={{flexWrap: 'wrap'}}
                                            spacing={2}>
                    {/*disabledPatchPicker: [{disabledPatchPicker.toString()}]*/}
                    {toolbarControlsMenus}
                    {toolbarButtons}
                    {appSettingsPanel}

                </Stack>}
                {!useDesktopLayout && <Stack justifyContent={"center"} alignItems={"center"}> <IconButton

                    onClick={() => {
                        toggleDrawer();
                    }}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{mr: 2}}
                >
                    <MenuIcon/>
                </IconButton></Stack>}
            </Toolbar>
        </AppBar>
        <Sidebar path={props.localPath.value}
                 mobile={useDesktopLayout ? false : true}
                 data={sidebarNavData} as={Drawer}
                 open={{value: drawerOpen, toggle: toggleDrawer}}>
            {useDesktopLayout ? undefined : <Box
                marginLeft={"10px"}
                marginTop={"10px"}
                marginBottom={"5px"}>
                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    <Stack direction={"column"} spacing={1}>
                        {toolbarControlsMenus}
                    </Stack>
                    <Stack direction={"row"} spacing={1} justifyContent={"flex-start"} height={"32px"}>
                        {toolbarButtons}
                    </Stack>
                </Stack>
                {appSettingsPanel}
            </Box>}
        </Sidebar>
        {/*// <Drawer variant="permanent" open={open}>*/}

        {(!drawerOpen || useDesktopLayout) && <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}>
            <Toolbar/>
            <Container
                id={"main-container"}
                // maxWidth="lg"
                maxWidth={false}
                sx={{mt: 4, mb: 4, marginTop: "5px !important;"}}>
                <DataProvider

                    setCurrentlyLoadedPatchVersionKey={setCurrentlyLoadedPatchVersionKey}
                    selectedEloBracket={props.eloBracket.value}
                    disabledEloBracketPicker={props.disabledEloBracketPicker}


                    selectedPatchVersion={props.patchVersion.value}
                    disabledPatchVersionPicker={props.disabledPatchPicker}

                    dataset={props.dataset.value}
                    disabledDatasetPicker={{
                        value: disabledDatasetPicker,
                        set: setDisabledDatasetPicker
                    }}


                    onLoadedEloBracketData={(availableEloBrackets: string[] | null) => {

                        if (availableEloBrackets) {
                            let eloBracketSortOrder: (TEloBracket | TTeamEloBracket)[];

                            if (props.dataset.value === "Team_RM_Stats") {
                                eloBracketSortOrder = ["All", "<1400", ">1400"]
                            } else {
                                // eloBracketSortOrder = ["All", "<950", "950 - 1200", "1200 - 1600", ">1600"]
                                eloBracketSortOrder = ["All", "<1100", "1100 - 1500", ">1500"]
                            }

                            const getAllLabel = (v: string) => {
                                if (props.dataset.value === "Team_RM_Stats")
                                    return v;
                                return v === "All" ? ">1100" : v;
                            }

                            let options = eloBracketSortOrder.map((v) => {

                                    return {
                                        label: getAllLabel(v),
                                        key: v,
                                        disabled: false,
                                        // disabled: v === "All",
                                        // disabledLabel: v === "All" ?
                                        disabledLabel:
                                            <Stack direction={"row"} justifyContent={"space-between"}
                                                   width={"100%"}>
                                                {v}
                                                {/*// @ts-ignore*/}
                                                <DataSamplingMessageTooltip/>
                                                {/*<Tooltip arrow*/}
                                                {/*         style={{cursor: "help"}}*/}
                                                {/*         enterDelay={250}*/}
                                                {/*         title={<Box minWidth={"350px"}>{*/}
                                                {/*             <DataSamplingMessage alert/>}</Box>}>*/}
                                                {/*    <InfoOutlined/>*/}
                                                {/*</Tooltip>*/}
                                            </Stack>
                                        // : undefined
                                    }
                                }
                            );

                            // let latest = Math.max(...availablePatchVersion.map(v => Number(v)))

                            // options.forEach((opt) => {
                            //     if (opt.key === latest.toString()) {
                            //         opt.label = `Latest (${opt.label})`
                            //     }
                            // })

                            // options.push({label: latest.toString(), key: latest.toString()})
                            // @ts-ignore
                            _setAvailableEloBracketFilterValues(options)
                            // setSelectedEloBracketFilter(">1600")

                        }
                    }}

                    onLoadedPatchesData={(availablePatchVersions: TPatchVersionInfoCollection) => {

                        let options = Object.keys(availablePatchVersions).map((version) => {
                                return {
                                    label: version,
                                    key: version,
                                    n: availablePatchVersions[version].sample_count,
                                    valid: availablePatchVersions[version].valid,
                                    subLabel: ""
                                }
                            }
                        );

                        let latest = Math.max(...Object.keys(availablePatchVersions).filter(k => availablePatchVersions[k].valid).map(v => Number(v)))

                        if (isNaN(latest)) {
                            latest = Math.max(...Object.keys(availablePatchVersions).map(v => Number(v)))
                        }

                        options.forEach((opt) => {
                            if (opt.key === latest.toString()) {
                                opt.subLabel = 'Latest'
                                // opt.label = `Latest (${opt.label})`
                            }
                        })

                        // options.push({label: latest.toString(), key: latest.toString()})
                        _setAvailablePatchFilterValues(options)
                        // setSelectedPatchVersion(latest.toString())
                    }}

                    availablePatchesData={Object.fromEntries(Object.values(availablePatchFilterValues).map(v => [v.key, {
                        sample_count: v.n !== undefined ? v.n : 0,
                        valid: !!v.valid
                    }]))}

                    // viewType={"static"}
                    lazyLoad={false}
                    // tableName={"EmpireWars_Stats"}
                    // @ts-ignore
                    // tableName={props.match.params["0"].split("/")[0]}
                    path={{
                        value: props.localPath.value,
                        set: (v: string[]) => {
                            setOpen(false);
                            props.localPath.set(v)
                        }
                    }}
                    sidebarNavData={{value: sidebarNavData, set: setSidebarNavData}}
                >
                    <MainContentView/>
                    {/*{(props.localPath.value.length > 0 && props.localPath.value[0] === "about") ? <Box>Aboot</Box> : <MainContentView/>}*/}
                </DataProvider>
                <Copyright sx={{pt: 4}} datasetInfo={sidebarNavData.datasetInfo}/>
            </Container>
        </Box>}
    </Box>
}

export default function Dashboard() {
    return <DashboardRouteWrapper/>;
}
