import * as React from "react";
import {
    GameDurationDescriptiveDataCollection,
    MetaData,
    NewDataRow,
    NewDataTable,
    TeamRMRawStatsTable
} from "../../Data/ModelGenerated";
import {Box} from "@mui/material";
import {TeamStatsSummaryViewContainer} from "./TeamStatsSummaryViewContainer";
import {TableOrGridOrScatterPlotRenderer} from "../Table/TableOrGridOrScatterPlotRenderer";
import {EGridOrDataTableOrChartRendererType, IPathObjectExtension, TViewInfo} from "../DefaultDataRenderer";
import {getMetaDataForObject} from "./CachedMetaDataProvider";


export interface ITeamRMRawStatsTableRendererProps {
    table: TeamRMRawStatsTable
    summary?: boolean


}


export const TeamRMRawStatsTableRenderer = (props: ITeamRMRawStatsTableRendererProps & IPathObjectExtension) => {


    if (props.summary && false) {
        return <TeamStatsSummaryViewContainer/>
    }

    const rows: { [key: string]: NewDataRow } = {}

    const countRows = Object.values(props.table.data).map(r => (r as any).n);
    const nSum = countRows.length > 0 ? countRows.reduce((acc, v) => acc + v) : 0;


    for (let key of Object.keys(props.table.data)) {
        let sourceRow = props.table.data[key] as any;

        let itemGroupKey: string = sourceRow.hasOwnProperty("civ") ? "civ" : "map_type"
        const duration: GameDurationDescriptiveDataCollection | undefined = sourceRow.t_m ? {
            win_data: {
                std: 0,
                m: sourceRow.t_m,
                a: sourceRow.t_m,
                q8: sourceRow.t_q8,
                d2: sourceRow.t_d2
            }
        } : undefined

        const row: NewDataRow = {
            meta_data: getMetaDataForObject(itemGroupKey === "civ" ? "civilizations" : "maps", sourceRow[itemGroupKey]),
            n: sourceRow.n,
            prop: sourceRow.n / nSum,
            win_rate: sourceRow.win_rate,
            duration: duration
        }

        rows[key] = row
    }

    const data: NewDataTable = {
        data: rows,
        label: "Civ Win Rates",
        n: 0,
        prop: 0,
        type: 'civs_table',
        win_rate: 0
    }

    let label: string;
    if (props.path.value.length > 2) {
        label = props.path.value.includes("civilizations") ? "Map" : "Civ";
    } else {
        label = props.path.value.includes("civilizations") ? "Civ" : "Map";
    }

    //Try to guess by actuall data
    const firstItemMd = Object.keys(data.data).length > 0 ? data.data[Object.keys(data.data)[0]].meta_data : undefined;
    let showItemTags: boolean = false;
    let title: string = "Stats";
    let supportedTypes: EGridOrDataTableOrChartRendererType = EGridOrDataTableOrChartRendererType.Table;
    let hideWinrates: boolean | undefined;
    let nMin: number | undefined;
    if (firstItemMd && !Array.isArray(firstItemMd)) {
        const md = firstItemMd as MetaData;

        if (md.icon_data?.group === "civilizations") {
            label = "Civ";
            title = `Raw Win Rates by ${label}`;
            supportedTypes = EGridOrDataTableOrChartRendererType.Scatter | EGridOrDataTableOrChartRendererType.Table;
        } else if (md.icon_data?.group === "maps") {
            label = "Map";
            title = 'Map Play Rates';
            showItemTags = true;
            hideWinrates = true;
            nMin = 200;
        }
    }

    // const viewInfo: TViewInfo = {title: "Team RM Map Stats", showItemTags: true, nMin: 500, noWinRate: true};

    return <Box>

        <TableOrGridOrScatterPlotRenderer
            nMin={nMin}
            supportedTypes={supportedTypes}
            showPreview={true}
            data={data}
            dataKey={props.dataKey}
            path={props.path}
            viewInfo={{title: title, showItemTags: showItemTags, noWinRate: hideWinrates, nMin: nMin}}
        />
    </Box>
}