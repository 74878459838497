import JSONTree from "react-json-tree";
import * as React from "react";
import {GenericTeamRMStatsTable, TeamRMRawStatsTable} from "../../Data/ModelGenerated";
import {TeamRMRawStatsTableRenderer} from "./TeamRMRawStatsTableRenderer";
import {IPathObjectExtension} from "../DefaultDataRenderer";
import {TeamRMCivTeamsTableRenderer} from "./TeamRMCivTeamsTableRenderer";
import {TeamRMCivMatchupsTableRenderer} from "./TeamRMCivMatchupsTableRenderer";


export interface ITeamStatsViewGenericTableRendererProps {
    table: GenericTeamRMStatsTable
    summary?: boolean
}

export const TeamStatsViewGenericTableRenderer = (props: ITeamStatsViewGenericTableRendererProps & IPathObjectExtension) => {

    const pathInfo: IPathObjectExtension = {
        dataKey: props.dataKey,
        path: props.path,
        resolveDataPromise: props.resolveDataPromise
    }

    if (props.table.view_type === "TeamRMRawStatsTable") {

        return <TeamRMRawStatsTableRenderer summary={props.summary}
                                            {...pathInfo}
                                            table={props.table as TeamRMRawStatsTable}/>
    }
    else if (props.table.view_type === "TeamRMCivTeamsTable") {
        return <TeamRMCivTeamsTableRenderer summary={props.summary}
                                            {...pathInfo}
                                            table={props.table as TeamRMRawStatsTable}/>

    }
    else if (props.table.view_type === "TeamRMCivTeamsMatchupsTable") {
        return <TeamRMCivMatchupsTableRenderer summary={props.summary}
                                            {...pathInfo}
                                            table={props.table as TeamRMRawStatsTable}/>

    }

    return <JSONTree data={props}/>
}