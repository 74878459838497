import {GridItemCardContainer} from "../Grid/GridItem";
import * as React from "react";
import {Box, Stack} from "@mui/material";


export interface ITeamStatsSummaryViewContainerProps {

}

export const TeamStatsSummaryViewContainer = (props: ITeamStatsSummaryViewContainerProps) => {


    let content = <Stack>
        <Box>1</Box>
        <Box>2</Box>
        <Box>3</Box>
    </Stack>

    return <GridItemCardContainer size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 5}}
                                  tall={true}
        // vertical={props.vertical}
                                  onSelectItem={() => {
                                      alert(`Selected`)
                                  }}
                                  content={content}/>
}