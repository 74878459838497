import {Alert, Box, ButtonBase, Container, Link, Stack, Typography} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";
import {ImageBackdrop, ImageButton, ImageMarked, Image, ImageSrc} from "../UI/ImageButton";


export const AboutRow = (props: { title: string, children: JSX.Element | JSX.Element[] | any }) => {

    return <Stack spacing={0}>
        <Box><Typography sx={{textDecoration: "underline"}}
                         fontSize={"1.05rem"}
                         fontWeight={500}>{props.title}</Typography></Box>
        <Box>
            {props.children}
        </Box>
    </Stack>
}

const ImageIcon = (props: { href: string, imgSrc: string, label: string }) => {

    return <Box sx={{display: 'flex', flexWrap: 'wrap', minWidth: 128, marginLeft: 5, marginRight: 5}}>
        <ImageButton
            // @ts-ignore
            href={props.href}
            focusRipple
            key={props.label}
            style={{
                width: 128,
                height: 28
            }}
        >
            <ImageSrc style={{backgroundImage: `url(${props.imgSrc})`}}/>
            <ImageBackdrop className="MuiImageBackdrop-root"/>
            <Image>
                {/*<Typography*/}
                {/*    component="span"*/}
                {/*    variant="subtitle1"*/}
                {/*    color="inherit"*/}
                {/*    sx={{*/}
                {/*        position: 'relative',*/}
                {/*        p: 4,*/}
                {/*        pt: 2,*/}
                {/*        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {props.label}*/}
                <ImageMarked className="MuiImageMarked-root"/>
                {/*</Typography>*/}
            </Image>
        </ImageButton>
    </Box>
}


export function AbootViewRenderer(props: {}) {
    return <Container sx={{minHeight: "75vh"}}>
        <Box height={"10px"}/>
        <Typography variant={"h3"} fontSize={"1.25rem"}>About</Typography>

        <Stack spacing={2} marginTop={"10px"} marginBottom={"10px"}>


            <Alert severity="warning">
                This site is still under development and might have various bugs and issues.
                If you run into any, please contact me (see details at the bottom of the page)</Alert>


            <AboutRow title={"Where does the data come from?"}>
                <Typography fontSize={"0.95rem"} fontWeight={300}>
                    Everything on this site is based on data queried using the aoe2.net API.
                </Typography>
            </AboutRow>

            {/*<AboutRow title={"How often is this updated?"}>*/}
            {/*    <Typography fontSize={"0.95rem"} fontWeight={300}>*/}
            {/*        Currently the site is updated manually. However my plan is (and the site is designed to support it*/}
            {/*        and new patches are detected automatically)*/}
            {/*        to have it update automatically every week (or so) eventually.*/}
            {/*    </Typography>*/}
            {/*</AboutRow>*/}

            <AboutRow title={"What is the 'Game Duration Analysis' card?"}>
                <Typography fontSize={"0.95rem"} fontWeight={300}>
                    It shows a KDE (Kernel Density Distribution) which basically is the estimated probability of the game
                    ending on any given minute (x axis) (dropdown allows filtering only won/lost games for the current target).
                    <br/>
                    It should provide a more accurate way to compare the performance of civs and players across
                    time ranges than fixed time brackets
                </Typography>
            </AboutRow>

            {/*<AboutRow title={"How does the player leaderboard work?"}>*/}
            {/*    <Typography fontSize={"0.95rem"} fontWeight={300}>*/}
            {/*        You can compare players based on their win rate on/with specific maps/civs .*/}
            {/*        E.g. if you filter by a specific map/civ, only the games with it are included when calculating the*/}
            {/*        win rate.*/}
            {/*        Currently it's not possible to filter using both a civ and a map at the same time.*/}
            {/*    </Typography>*/}
            {/*</AboutRow>*/}


            {/*<AboutRow title={"What do the colored checkbox icons in the leaderboard mean?"}>*/}
            {/*    <Typography fontSize={"0.95rem"} fontWeight={300}>*/}
            {/*        Currently due to server capacity constraints detailed player data (i.e. civ/map win rates, win rates*/}
            {/*        against specific civs, game duration analysis) is only generated when a player's profile page is opened (or by clicking the*/}
            {/*        start/refresh button in the leaderboard table). The report can only be generated more once per day.*/}
            {/*    </Typography>*/}
            {/*</AboutRow>*/}

            {/*<AboutRow title={"Upcoming Features?"}>*/}
            {/*    <Typography fontSize={"0.95rem"} fontWeight={300}>*/}
            {/*        The players table is still a bit glitchy if you sort by certain columns, so I'., working on fixing that*/}
            {/*        (currently all players are included only when sorting by latest elo, for all other columns a*/}
            {/*        player's page has to be opened at least once to be included).*/}
            {/*        <Box/>*/}
            {/*        - Civ vs Civ win rate table*/}
            {/*        <Box/>*/}
            {/*        - Support for Team RM/EW ladder data*/}
            {/*        <Box/>*/}
            {/*        - Add player steam profile images.*/}
            {/*        <Box/>*/}
            {/*        <Box/>*/}
            {/*        - Allow comparing player win rate for several maps/civ at once.*/}
            {/*        <Box/>*/}
            {/*        - Individual match analysis and aggregated stats (e.g. which civs and players produce which units more and use*/}
            {/*        what strategies on which maps. I have scripts which detect:*/}
            {/*        laming sheep, boar, luring deer, MAA, archers vs scouts, fast castle and various other strategies from recorded games.*/}
            {/*        But I haven't had the time to deploy them on a larger scale and integrating them into this website yet.*/}
            {/*        <Box/>*/}
            {/*        - Improve support for mobile/small screens*/}
            {/*        <Box/>*/}
            {/*        - General fixes and stability improvements*/}
            {/*        <Box/>*/}
            {/*        - Open to your suggestions*/}
            {/*    </Typography>*/}
            {/*</AboutRow>*/}


            <AboutRow title={"Have any issues, suggestions or questions?"}>

                <Stack direction={"row"} flexWrap={"wrap"} spacing={1} width={"80%"} marginTop={1} marginBottom={2}>
                    <Typography fontSize={"0.95rem"} fontWeight={300} flexGrow={0}>
                        Contact me on:
                    </Typography>

                    {/*<Stack direction={"row"}>*/}
                    <ImageIcon
                        href={"https://www.reddit.com/user/tetrakishexahedron"}
                        imgSrc={process.env.PUBLIC_URL + '/reddit-icon.webp'} label={"Reddit"}/>
                    <Box width={"0rem"}/> or
                    <ImageIcon
                        href={"https://discordapp.com/users/127519206232031232"}
                        imgSrc={process.env.PUBLIC_URL + '/discord-icon.webp'} label={"Discord"}/>
                    {/*</Stack>*/}
                </Stack>
                {/*Or use this reddit thread for feedback: TODO*/}
            </AboutRow>


        </Stack>
        {/*<Box>*/}
        {/*    Have any questions, suggestions or issues?*/}
        {/*</Box>*/}
        {/*<Stack direction={"row"} spacing={1}>*/}
        {/*    <Box>*/}
        {/*        Contact me on:</Box>*/}
        {/*    <Box>*/}
        {/*        <Link href={"https://www.reddit.com/user/tetrakishexahedron"}>Reddit</Link> or <Link*/}
        {/*        href={"https://discordapp.com/channels/@me/qwyt#7385/"}>Discord</Link></Box>*/}
        {/*</Stack>*/}

        {/*<Box marginTop={"10px"}>Uses data from on aoe2.net</Box>*/}

        {/*<Box marginTop={"10px"}>Created by [USER]</Box>*/}

        <Typography marginTop={"2rem"} fontSize={"1.0rem"} fontWeight={350} width={"100%"} textAlign={"center"}>
            @ aoedash.net | 2023
        </Typography>

    </Container>
}