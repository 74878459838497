import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {PlayerStatsViewQueryResponseMetaData} from "../../Data/Model";
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    LinearProgress,
    LinearProgressProps,
    Stack, Tooltip,
    Typography
} from "@mui/material";
import {END_POINTS_URL} from "../../Data/config";
import JSONTree from "react-json-tree";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
    BuildPlayerProfileQueryStatus,
    NewDataTableGroupWithSummary,
    PlayerMetaData,
    PlayerStatsViewQueryJobInfo
} from "../../Data/ModelGenerated";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import {LoadingIndicator} from "../LoadingIndicator";
import ErrorIcon from '@mui/icons-material/Error';

export interface PlayerStatsQueryJobInfoRendererProps {

    data: PlayerStatsViewQueryResponseMetaData
    inline?: boolean

    table: string
    meta_data: PlayerMetaData
    last_updated?: string
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, label: string }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" whiteSpace={"nowrap"} color="text.secondary">{`${Math.round(
                    props.value,
                )}% (${props.label})`}</Typography>
            </Box>
        </Box>
    );
}

type TJobStatusCollection = { [jobId: number]: any }


export function PlayerStatsQueryJobInfoRenderer(props: PlayerStatsQueryJobInfoRendererProps) {

    const getInitialJobStatus = () => {
        if (props.data.scheduled_or_running_job) {
            return {id: props.data.scheduled_or_running_job.job_id, status: props.data.scheduled_or_running_job.status}
        }
    }

    const getHasActiveJob = (job: { id: number | undefined, status: number } | undefined | "scheduled") => {

        if (job === "scheduled") {
            return true
        } else if (job !== undefined) {
            if (job.status >= 0 && job.status < 10) {
                return true
            } else if (job.status >= 10) {
                return "completed";
            } else {
                return "failed";
            }
        }
        return false;
        // if (props.data.scheduled_or_running_job)
    }

    const [currentJob, setCurrentJob] = useState<{ id: number | undefined, status: number } | undefined | "scheduled">(getInitialJobStatus())
    const currentJobId = (currentJob === "scheduled" || currentJob === undefined) ? undefined : currentJob.id;
    const hasActiveJob = getHasActiveJob(currentJob)

    // const [currentJobId, setCurrentJobId] = useState(props.data.scheduled_or_running_job?.job_id)
    // const [hasActiveJob, setHasActiveJob] = useState<boolean | "failed" | "completed">(item.job_info ? (item.job_info.status > 0 && item.job_info.status < 10) : false)

    //
    const hasSuccessfulFinishedJob = props.data.last_finished_job && props.data.last_finished_job.status === 10

    let renderType: "inline" | "header" | "fullscreen" = props.inline ? "inline" : (hasSuccessfulFinishedJob ? "header" : "fullscreen")

    let table: string = props.table;
    let meta_data = props.meta_data;

    let jobInfo: PlayerStatsViewQueryJobInfo | undefined = props.data.scheduled_or_running_job ? props.data.scheduled_or_running_job : props.data.last_finished_job;
    let item: { last_updated?: string, job_info: PlayerStatsViewQueryJobInfo | undefined } = {
        last_updated: props.last_updated,
        job_info: jobInfo
    }
    //For inline:
    let thresholdDay = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    let dt = item.last_updated ? Date.parse(item.last_updated) : undefined;


    let hoursSinceLastJob: number | undefined;
    let hoursSinceLastJobLabel: string | undefined;
    if (props.data.last_finished_job || dt) {

        let lastFinishedDate = props.data.last_finished_job ? Date.parse(props.data.last_finished_job.last_updated) : new Date(dt as number).getTime()

        let tDiff = Date.now() - lastFinishedDate
        hoursSinceLastJob = Math.round(tDiff / (1000 * 3600))
        let diffHours = hoursSinceLastJob
        if (diffHours < 1) {
            hoursSinceLastJobLabel = "Last updated less than an hour ago"
        } else {
            hoursSinceLastJobLabel = `Last updated ${diffHours} hours ago`
        }
    }

    const runJob = () => {
        let meta = meta_data as PlayerMetaData

        const url = `${END_POINTS_URL}/schedule_job/table_view/${props.table}/${meta.profile_id}`;

        fetch(url).then(response => {
            return response.json();
        })
            .then(data => {
                if (data.status === undefined) {
                    setCurrentJob({id: undefined, status: -2})
                } else if (data.status === -1) {
                    setCurrentJob({id: data.job_id, status: -1});
                } else if (data.status === 10) {
                    setCurrentJob({id: data.job_id, status: 10});
                } else {
                    setCurrentJob({id: data.job_id, status: data.status});
                }
            }).catch((error) => {
            console.error(`Could not schedule a new job for ${props.table} -> ${meta.profile_id}:`, error);
            setCurrentJob({id: undefined, status: -10})

            // setHasActiveJob("failed")
        });

        setCurrentJob("scheduled")
    }


    //For old:


    // const [jobFinished, setJobFinished ] = useState<undefined | "succeeded" | "failed">(undefined);
    const [jobIntervalRef, setJobIntervalRed] = useState<{ query: TJobStatusCollection, extrapolate: TJobStatusCollection }>({
        query: {},
        extrapolate: {}
    })

    const [buildProgress, setBuildProgress] = useState<undefined | { message: string, progReal: number, progExrapolated: number }>({
        message: "Preparing data",
        progReal: 0,
        progExrapolated: 0
    })
    // const [buildProgress, setBuildProgress] = useState<undefined | { message: string, progReal: number, progExrapolated: number }>(undefined)

    const buildProgressRef = useRef<undefined | { message: string, progReal: number, progExrapolated: number }>(undefined)


    buildProgressRef.current = buildProgress;


    const getReportAgeDays = () => {
        if (props.data.last_finished_job) {
            let dateFinishedS = Date.parse(props.data.last_finished_job.last_updated)
            if (!isNaN(dateFinishedS)) {
                const diffDays = Math.floor(Math.abs(Date.now() - new Date(dateFinishedS).getTime()) / (1000 * 60 * 60 * 24));
                return diffDays
            }
        }
        return false
    }

    useEffect(() => {
        const queryStatusForJob = () => {
            if (currentJobId !== undefined) {
                let url = `${END_POINTS_URL}/__s_/job_status/${currentJobId}`

                // (buildProgress === undefined ? 0 : (buildProgress.prog + 5))
                fetch(url).then(response => response.json()).then(data => {

                    if (data.status === 10 && !props.inline) {
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                    }


                    setCurrentJob({id: currentJobId, status: data.status})

                    let statusMessage: string = "Preparing data"

                    let status = data.status as BuildPlayerProfileQueryStatus
                    let s = -1;
                    // 0 | 1 | 2 | 10 | -1;
                    if (status >= 10) {
                        statusMessage = "Completed"
                        s = 100
                    } else if (status >= 2) {
                        statusMessage = "Generating report"
                        s = 80
                    } else if (status >= 1) {
                        statusMessage = "Querying aoe2.net"
                        s = 20
                    } else if (status > 0) {
                        statusMessage = "Preparing data"
                        s = 0
                    } else {
                        statusMessage = `${data.message}`
                        s = -1
                    }

                    // let s = -1;
                    // s = (data.status * 10) * 4;
                    // s = s > 100 ? 100 : s
                    //


                    setBuildProgress({message: statusMessage, progReal: s, progExrapolated: s})
                }).catch((error) => {


                    let statusMessage = `Could not generate a report, failed with: ${error.toString()}`
                    setBuildProgress({message: statusMessage, progReal: -1, progExrapolated: -1,})
                });

            }
        }

        const extrapolateProgress = () => {

            if (buildProgressRef.current) {
                if (buildProgressRef.current.progExrapolated < 99) {
                    setBuildProgress({
                        ...buildProgressRef.current,
                        progExrapolated: buildProgressRef.current.progExrapolated + 0.75,
                    })
                } else {
                    setBuildProgress({
                        ...buildProgressRef.current,
                        progExrapolated: buildProgressRef.current.progReal,
                    })
                }
            }
        }

        if (currentJobId !== undefined) {

            const runIntveral = props.inline ? 4000 : 1000;

            let doSet = false
            let query = {...jobIntervalRef.query}
            if (jobIntervalRef.query[currentJobId] === undefined) {
                let queryInterval = setInterval(queryStatusForJob, runIntveral)
                query[currentJobId] = queryInterval;
                doSet = true
            }

            let extrapolate = {...jobIntervalRef.extrapolate};
            if (jobIntervalRef.extrapolate[currentJobId] === undefined) {
                let extrapolateInterval = setInterval(extrapolateProgress, 150)
                extrapolate[currentJobId] = extrapolateInterval;
                doSet = true
            }

            if (doSet) {
                setJobIntervalRed({
                    query: query,
                    extrapolate: extrapolate
                })
            }
        }

    }, [currentJobId])


    const buildProgressDep = buildProgress ? buildProgress.progReal : 0

    useEffect(() => {

        if (buildProgress && (buildProgress.progReal <= -1 || buildProgress.progReal >= 100)) {

            for (let t of Object.values(jobIntervalRef.query)) {
                clearInterval(t)
            }
            for (let t of Object.values(jobIntervalRef.extrapolate)) {
                clearInterval(t)
            }
        } else {
            ;
        }

    }, [buildProgressDep])

    let infoMessage: JSX.Element;


    if (props.data.last_finished_job) {
        let dateFinishedS = Date.parse(props.data.last_finished_job.last_updated)

        let dateAgeDescription: string;
        if (isNaN(dateFinishedS)) {
            // dateAgeDescription = `Was generated on ${props.data.last_finished_job.last_updated}`
            dateAgeDescription = ``;
        } else {
            // dateAgeDescription = `Shown report is more than ${getReportAgeDays()} days old`
            dateAgeDescription = `Data is more than ${getReportAgeDays()} days old. `
        }

        if (props.data.scheduled_or_running_job) {
            infoMessage = <span>
        {/*<p>{dateAgeDescription}</p>*/}
                <p>{dateAgeDescription}
                    A new report is being generated, the page will refresh automatically when it's ready</p>
    </span>
        } else {
            infoMessage = <span>
        <p>{dateAgeDescription}</p>
    </span>
        }

    } else {

        infoMessage = <span>
        <p>A stats dashboard has not yet been generated for this player</p>
            {props.data.scheduled_or_running_job &&
                <React.Fragment>
                    <p>Please wait while we prepare the data (feel free to close the window and return later)</p>
                    <p></p>
                </React.Fragment>}
    </span>

    }


    let dontShowAlert = false;

    // is a job running now?
    // if (props.data.last_finished_job?.job_id === undefined){
    //     dontShowAlert = true;
    // }
    // else if (props.data.last_finished_job && props.data.scheduled_or_running_job)
    if (!props.data.scheduled_or_running_job) {
        dontShowAlert = true;
    }

    // let dontShowAlert = getReportAgeDays() === 0 && props.data.last_finished_job !== undefined && props.data.last_finished_job.status === 10

    const [showError, setShowError] = useState(false)

    const showErrorProps = {
        onClick: () => {
            setShowError(!showError)
        },
        cursor: "pointer"
    }

    let ret: JSX.Element


    // @ts-ignore
    if (renderType === "header") {
        if (buildProgress && buildProgress.progReal <= -1) {
            ret = <Alert severity={"error"}>
                <Stack>
                    <Box>Something Went Wrong!</Box>
                    <Box>Could not generate a report for {props.meta_data.name}</Box>
                    <Box>First please try refreshing the page and contact me (see about tab) if the problem persists</Box>

                    <Stack direction={"row"} alignItems={"center"}> {showError ?
                        <ExpandLessIcon {...showErrorProps}/> :
                        <ExpandMoreIcon {...showErrorProps}/>} {showError ? "Hide Details" : "Show Details"}</Stack>
                    <Box visibility={showError ? "visible" : "hidden"}>Error: {buildProgress.message}</Box>
                </Stack>
            </Alert>
        } else if (dontShowAlert) {
            if (hoursSinceLastJobLabel) {
                ret = <Typography color={"disabled.main"} fontSize={"0.85em"}>{hoursSinceLastJobLabel}</Typography>
            } else {
                ret = <Box></Box>
            }
            // } else if (props.full) {
            //     ret = <div>TODO</div>
        } else {
            ret = <Alert severity="warning">
                {infoMessage}
                {buildProgress &&
                    <LinearProgressWithLabel value={buildProgress.progExrapolated}
                                             label={buildProgress.message}/>}
            </Alert>
        }

        return <div style={{marginBottom: "10px", marginTop: "5px"}}>
            {ret}
            {/*<JSONTree data={props}/>*/}
        </div>
    } else if (renderType === "inline") {

        let statusIconWithTooltip: JSX.Element | undefined;
        let tooltipLabel: string | undefined;
        let actionButton: JSX.Element | undefined;
        if (hasActiveJob === true) {
            // return <Stack marginLeft={"3px"} direction={"row"} alignItems={"center"}>
            //     {/*<Box height={"1.25em"} width={"1.25em"}>*/}
            //     {/*// @ts-ignore*/}
            // @ts-ignore
            statusIconWithTooltip = <CircularProgress size={"1.25em"} color={"progressSmall"} value={100}
                                                      variant={"indeterminate"}/>
            // {/*</Box>*/
            // }
            // // </Stack>

        } else if (hasActiveJob === "failed") {
            statusIconWithTooltip = <ErrorIcon color={"error"}/>
            tooltipLabel = `Could not generated a report for ${props.data.last_finished_job?.profile_id}, try refreshing or contacting me if that doesen't help (see about page)`

        } else if (dt === undefined) {
            // return <Stack direction={"row"}>

            actionButton = <PlayArrowIcon sx={{cursor: "pointer"}} onClick={runJob}/>
            // </Stack>

        } else if (dt > thresholdDay || hasActiveJob === "completed") {
            // return <Stack direction={"row"}>
            tooltipLabel = hoursSinceLastJobLabel;
            statusIconWithTooltip = <CheckCircleIcon color={"success"}/>
            // </Stack>
        } else {
            tooltipLabel = `Data last updated more than ${hoursSinceLastJob} hours ago`
            // return <Stack direction={"row"}>
            statusIconWithTooltip = <CheckCircleIcon color={"warning"}/>
            actionButton = <RefreshIcon sx={{cursor: "pointer", height: "29px"}} onClick={runJob}/>
            // </Stack>
        }

        return <Stack direction={"row"} height={"29px"} maxHeight={"29px"} alignItems={"center"}>
            {tooltipLabel ?
                <Tooltip
                    title={tooltipLabel}>
                    <Stack
                        sx={{
                            cursor: "help",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "29px"
                        }}>{statusIconWithTooltip}</Stack></Tooltip> : statusIconWithTooltip}
            {actionButton}
        </Stack>

    } else if (renderType === "fullscreen") {

        let progressProps: any = buildProgress !== undefined ? {
            value: buildProgress.progExrapolated,
            variant: "determinate"
        } : {variant: "indeterminate"}

        if (buildProgress && buildProgress.progReal < 0) {
            return <LoadingIndicator value={100}
                                     label={"Failed with error:"}
                                     error/>

        } else {
            return <LoadingIndicator value={buildProgress && buildProgress.progExrapolated} label={"Generating view..."}
                                     sublabel={buildProgress ? buildProgress.message : "Preparing data"}/>
        }
        // <Container maxWidth={"lg"}
        //                   sx={{display: "flex", justifyContent: "center", height: "75vh", alignItems: "center"}}
        // >
        //     <CircularProgress size={"5rem"}/>
        //     {/*<JSONTree data={_loadedDataView}/>*/}
        // </Container>
        // return <Container sx={{backgroundColor: undefined, height: "65vh"}}>
        //
        //     <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
        //
        //         <CircularProgress size={"10rem"} {...progressProps}/>
        //         <Typography>Building report: {buildProgress && buildProgress.progReal}</Typography>
        //         <Box height={"10rem"}/>
        //
        //     </Stack>
        // </Container>
    }

    return <Box>1</Box>

}

export function PlayerReportStatusRenderer(props: { table: string, item: NewDataTableGroupWithSummary }) {

    let respMeta: PlayerStatsViewQueryResponseMetaData = {};
    let meta_data = props.item.meta_data as PlayerMetaData;

    if (meta_data.profile_id === undefined) {
        //TODO throw exception or something
        return <Box>?!</Box>
    }

    return <PlayerStatsQueryJobInfoRenderer data={respMeta}
                                            inline
                                            last_updated={props.item.last_updated}
                                            meta_data={meta_data}
                                            table={props.table}/>

    // let thresholdDay = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
    // let dt = props.item.last_updated ? Date.parse(props.item.last_updated) : undefined;
    //
    // const [hasActiveJob, setHasActiveJob] = useState<boolean | "failed" | "completed">(props.item.job_info ? (props.item.job_info.status > 0 && props.item.job_info.status < 10) : false)


    // const runJob = () => {
    //     let meta = props.item.meta_data as PlayerMetaData
    //
    //     const url = `${END_POINTS_URL}/schedule_job/table_view/${props.table}/${meta.profile_id}`;
    //
    //     fetch(url).then(response => {
    //         return response.json();
    //     })
    //         .then(data => {
    //             if (data.status === undefined || data.status === -1) {
    //                 setHasActiveJob("failed");
    //             } else if (data.status === 10) {
    //                 setHasActiveJob("completed");
    //             } else {
    //                 setHasActiveJob(true);
    //             }
    //         }).catch((error) => {
    //         console.error(`Could not schedule a new job for ${props.table} -> ${meta.profile_id}:`, error);
    //         setHasActiveJob("failed")
    //     });
    //
    //     setHasActiveJob(true)
    // }

    // if (hasActiveJob === true) {
    //     return <Stack marginLeft={"3px"} direction={"row"} alignItems={"center"}>
    //         <Box height={"1.25em"} width={"1.25em"}>
    //             {/*// @ts-ignore*/}
    //             <CircularProgress size={"1.25em"} color={"progressSmall"} value={100} variant={"indeterminate"}/>
    //         </Box>
    //     </Stack>
    //
    // } else if (hasActiveJob === "failed") {
    //     return <Stack direction={"row"}>
    //         FAILED
    //     </Stack>
    //
    // } else if (dt === undefined) {
    //     return <Stack direction={"row"}>
    //         <PlayArrowIcon sx={{cursor: "pointer"}} onClick={runJob}/>
    //     </Stack>
    //
    // } else if (dt > thresholdDay || hasActiveJob === "completed") {
    //     return <Stack direction={"row"}>
    //         <CheckCircleIcon color={"success"}/>
    //     </Stack>
    // } else {
    //     return <Stack direction={"row"}>
    //         <CheckCircleIcon color={"warning"}/>
    //         <RefreshIcon sx={{cursor: "pointer"}} onClick={runJob}/>
    //     </Stack>
    //
    // }

}