// export const END_POINTS_URL = "http://152.70.167.134:8000"
export const END_POINTS_URL = (() => {

    let apiUrl: string | undefined;
    //
    // return apiUrl

    console.warn(`env:\n ${JSON.stringify(process.env)}`)

    if (process.env.NODE_ENV === 'production' ||  process.env.REACT_APP_REMOTE_TEST_MODE === "YES"){
        apiUrl = process.env.REACT_APP_API_URL_PROD
        // return "http://api.aoedash.net:8000"
    } else {
        apiUrl = process.env.REACT_APP_API_URL
    }

    if (apiUrl === undefined) {
        throw Error("API URL undefined, please set REACT_APP_API_URL, REACT_APP_API_URL_PROD")
    }
    //Enable to test prod backend locally
    // apiUrl = process.env.REACT_APP_API_URL_PROD;

    console.warn(`API URL : [${apiUrl}] `)

    return apiUrl
    // return "http://api.aoedash.net:8000"
    // return "http://127.0.0.1:8000"
})()

export const IsDev = () => {
    if (process.env.NODE_ENV === 'production')
        return false;
    return false
    // return true
}

// export const END_POINTS_URL = "http://127.0.0.1:8000"