import {GameDurationDescriptiveData, GameDurationDescriptiveDataCollection} from "../../Data/ModelGenerated";
import Box from "@mui/material/Box";

// import {Chart} from 'chart.js';
// import { Chart } from 'react-chartjs-2';

// import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar, Chart, Line} from 'react-chartjs-2';
import {LegendItem} from "chart.js";
import * as React from "react";

// Chart.register(annotationPlugin);


interface IMedianGameDurationWidgetProps {
    data: GameDurationDescriptiveDataCollection
    type?: "table" | "inline"
    maxDuration?: number
}

export const MedianGameDurationWidget = (props: IMedianGameDurationWidgetProps) => {

    // const options = {
    //
    //     scales: {
    //         xAxes: {
    //             display: true
    //         },
    //         yAxes: {
    //             display: false
    //         }
    //     },
    //     indexAxis: 'y' as const,
    //     elements: {
    //         bar: {
    //             borderWidth: 2,
    //         },
    //     },
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             display: false,
    //             position: 'right' as const,
    //         },
    //         title: {
    //             display: false,
    //             text: 'Chart.js Horizontal Bar Chart',
    //         },
    //         annotation: {
    //             annotations: {
    //                 ellipse1: {
    //                     type: 'ellipse',
    //                     xMin: 1,
    //                     xMax: 1,
    //                     yMin: 0,
    //                     yMax: 2,
    //                     backgroundColor: 'rgba(68,46,210,0.25)'
    //                 },
    //                 // line1: {
    //                 //     // type: 'line',
    //                 //     // xMin: 5,
    //                 //     // xMax: 5,
    //                 //     // yMin: 77,
    //                 //     // yMax: 5,
    //                 //     borderColor: 'rgb(255, 99, 132)',
    //                 //     borderWidth: 2,
    //                 // }
    //             }
    //         }
    //
    //     },
    // };
    if (!props.data.win_data) {
        return <Box>1</Box>
    }
    const win_data = props.data.win_data as GameDurationDescriptiveData

    let color: { borderColor: string, backgroundColor: string };

    if (false) {
        color = {borderColor: 'rgb(255, 99, 132)', backgroundColor: 'rgba(255, 99, 132, 0.5)'};
    } else {
        color = {borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)'};
    }
    let datasets = [
        // @ts-ignore
        {
            type: 'bar',
            // borderColor: themeCtx.palette.values.plotLine,
            label: "Avg. won game time",
            data: [[win_data.d2, win_data.q8]],
            borderColor: color.borderColor,
            backgroundColor: color.backgroundColor,

        }]

    let y1Max = 0.12
    let annotations: any = {
        line1: {
            type: 'line',
            xMax: win_data.m,
            xMin: win_data.m,
            yMin: -0.35,
            yMax: 0.35,
            borderColor: 'grey',
            borderWidth: 2,
        }
    }

    // const maxTPre = win_data.q8 + 10
    // const max_t = maxTPre > 60 ? maxTPre : 60;
    //
    // const minTPre = win_data.d2 - 8
    // const min_t = minTPre < 15 ? minTPre : 15;
    // @ts-ignore
    const min_t = 10;
    const max_t = props.maxDuration === undefined ? 60 : (() => {
        if (props.maxDuration > 90) {
            return 100;
        } else if (props.maxDuration > 80) {
            return 90;
        } else if (props.maxDuration > 70) {
            return 80;
        } else if (props.maxDuration > 60) {
            return 70;
        }
        return 60;
    })()

    return <Bar data={{
        labels: ["Won Game Duration"],
        // @ts-ignore
        datasets: datasets
        // datasets: [
        //     // @ts-ignore
        //     {type: 'line', borderColor: "#0038ff", label: "Play Rate", data: yValPlayRate, yAxisID: 'y1',},
        //     {type: 'bar', backgroundColor: "#ff0000", label: "Win Rate", data: yVal, yAxisID: 'y',},
        // ]
    }}
                options={{
                    indexAxis: 'y',
                    interaction: {
                        mode: 'x'
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                            // position: "right",
                            // align: 'start',
                            // maxWidth: 55, maxHeight: 100,
                            // display: false,
                            // labels: {
                            //     generateLabels: function (chart) {
                            //         let labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                            //         let newLabels: LegendItem[] = []
                            //         labels.forEach((l, i) => {
                            //
                            //             newLabels.push(l)
                            //             let n: LegendItem = {...l}
                            //             newLabels.push(n)
                            //             l.text = "";
                            //             // labels[key].fillStyle = "rgba(133, 4, 12, 0.7)";
                            //             // labels[key].strokeStyle = "rgba(33, 44, 22, 0.7)";
                            //         });
                            //         return newLabels;
                            //     }
                            // }
                        },
                        // @ts-ignore
                        autocolors: false,
                        annotation: {
                            annotations: annotations
                        },
                        tooltip: {
                            // intersect: 'average',
                            mode: 'point',
                            callbacks: {
                                title: () => "",
                                label: (tooltipItem) => {
                                    const toolips = [` 20th percentile: ${win_data.d2} min`, `Median: ${win_data.m} min`, `80th percentile: ${win_data.q8} min`];
                                    return `Median: ${win_data.m} min`;
                                    // return yValFormatted[tooltipItem.dataIndex]
                                },
                                // footer: () => {
                                //     const toolips = [`20th percentile: ${win_data.d2} min`, `80th percentile: ${win_data.q8} min`, `St. deviation: ${win_data.std}`];
                                //     return toolips
                                // }
                            }
                        }
                    },
                    scales: {
                        y: {
                            display: false,


                            // ticks: {
                            //     callback: tickPercentCallback
                            // },

                            // min: 0, max: 1
                        },
                        x: {
                            display: props.type !== "inline",
                            afterBuildTicks: (axis) => {
                                let values = axis.ticks.map(t => t.value);
                                let max = Math.max(...values);
                                let min = Math.min(...values);
                                axis.ticks = (() => {

                                    let baseValues = [
                                        {value: 10},
                                        {value: max_t}
                                    ]
                                    const isCloserByN = (num: number, N: number) => baseValues.map(v => v.value).some(item => Math.abs(num - item) <= N);

                                    const nDist = max_t / 6;

                                    if (!isCloserByN(win_data.m, nDist)) {
                                        baseValues.push({
                                            value: win_data.m
                                        })
                                    }
                                    if (!isCloserByN(win_data.q8, nDist)) {
                                        baseValues.push({
                                            value: win_data.q8
                                        })
                                    }
                                    if (!isCloserByN(win_data.d2, nDist)) {
                                        baseValues.push({
                                            value: win_data.d2
                                        })
                                    }
                                    if (!isCloserByN(60, nDist)) {
                                        baseValues.push({
                                            value: 60
                                        })
                                    }

                                    baseValues.forEach((v) => {
                                        v.value = Math.round(v.value)
                                    })

                                    return baseValues;
                                })()
                            },

                            offset: false,
                            ticks: {

                                callback: (val, index) => {
                                    // Hide every 2nd tick label
                                    return val;
                                    // return [10, 20, 30, 40, 50].includes(val as number) ? val : '2';
                                },
                                padding: 0


                            },
                            min: min_t, max: max_t + 5
                        }
                        // y1: {
                        //     // ticks: {
                        //     //     callback: tickPercentCallback
                        //     // },
                        //
                        //     min: 0,
                        //     max: y1Max,
                        //     type: 'linear',
                        //     display: true,
                        //     position: 'right',
                        //
                        //     // grid line settings
                        //     grid: {
                        //         drawOnChartArea: false, // only want the grid lines for one axis to show up
                        //     },
                        // },
                    }
                }}/>

    // const labels = ['January'];
    //
    // let color: { borderColor: string, backgroundColor: string };
    //
    // if (true) {
    //     color = {borderColor: 'rgb(255, 99, 132)', backgroundColor: 'rgba(255, 99, 132, 0.5)'};
    // } else {
    //     color = {borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)'};
    // }
    //
    // const data = {
    //     labels,
    //     datasets: [
    //         {
    //             // label: 'Dataset 1',
    //             data: [[win_data.d2, win_data.q8]],
    //             borderColor: color.borderColor,
    //             backgroundColor: color.backgroundColor,
    //         },
    //
    //     ],
    // };
    //
    //
    // return <Box>
    //     {/*// @ts-ignore*/}
    //     <Bar options={options} data={data}/>;
    //
    // </Box>
}