import {Theme, ThemeOptions} from "@mui/material";
import * as React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Dashboard from "./Dashboard";
import {red} from '@mui/material/colors';


// import TrajanusRoman from 'TrajanusRoman.ttf';

export const BuildDefaultTheme = (mode: 'light' | 'dark') => {
    return {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        typography: {
            fontFamily: [
                'Jost',
                'Futura',
                "Century Gothic", "Corbel", "Sans-Serif"
            ].join(','),

            body2: {
                fontFamily: "Sans-Serif"
            }
        },
        palette: {
            // primary: {
            //     // main: red[500],
            // },
            //
            mode,
            disabled: {
                main: "grey"
            },

            altCardBg: {
                main: mode === "dark" ? "cyan" : "blue",
            }
            ,
            progressSmall: {
                main: mode === "dark" ? "white" : "black",
            },
            values: {
                negative: "red",
                positive: "green",

                // progressSmall: mode === "dark" ? "white" : "blue",

                plotLine: mode === "dark" ? "#2E8BC0" : "blue"
            },


            chartColors: [
                '#2f4f4f',
                '#556b2f',
                '#8b4513',
                '#6b8e23',
                '#800000',
                '#191970',
                '#708090',
                '#808000',
                '#483d8b',
                '#008000',
                '#3cb371',
                '#bc8f8f',
                '#663399',
                '#bdb76b',
                '#008b8b',
                '#cd853f',
                '#4682b4',
                '#d2691e',
                '#9acd32',
                '#cd5c5c',
                '#00008b',
                '#32cd32',
                '#daa520',
                '#8fbc8f',
                '#8b008b',
                '#b03060',
                '#66cdaa',
                '#9932cc',
                '#ff4500',
                '#00ced1',
                '#ff8c00',
                '#ffd700',
                '#ffff00',
                '#c71585',
                '#0000cd',
                '#deb887',
                '#7fff00',
                '#00ff00',
                '#ba55d3',
                '#00ff7f',
                '#4169e1',
                '#e9967a',
                '#dc143c',
                '#00ffff',
                '#00bfff',
                '#9370db',
                '#0000ff',
                '#a020f0',
                '#ff6347',
                '#d8bfd8',
                '#ff00ff',
                '#db7093',
                '#eee8aa',
                '#ffff54',
                '#6495ed',
                '#dda0dd',
                '#b0e0e6',
                '#ff1493',
                '#ee82ee',
                '#98fb98',
                '#87cefa',
                '#7fffd4',
                '#ff69b4']



            // text: {
            //     disabled: "orange",
            //     primary: "orange",
            //     secondary: "orange"
            // }

        },
        components: {
            MuiCard: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === "dark" ? "rgb(43,43,43)" : undefined,
                        // color: theme.palette.primary.contrastText,
                    }
                }
            }
        },
        // overrides: {
        //     MuiCssBaseline: {
        //         '@global': {
        //             '@font-face': [TrajanusRoman],
        //         }
        //     }
        // }
    }
}


// Used for storybooks
// TODO use in app as well
export const DefaultThemeWrapper = (props: { children: any }) => {

    const theme: Theme = createTheme(BuildDefaultTheme("light"));
    // const theme: Theme  = createTheme(BuildDefaultTheme("dark"));
    // const theme: Theme = React.useMemo(
    //     () =>
    //         createTheme(BuildDefaultTheme("dark")),
    //     [],
    // );
    return <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>

}