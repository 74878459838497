import {Box, CircularProgress, Container, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";

export interface ILoadingIndicatorProps {
    value?: number
    valueNext?: number
    label?: string
    sublabel?: string
    error?: boolean
}

const PROGRESS_REFRESH_RATE = 100 //ms

export function LoadingIndicator(props: ILoadingIndicatorProps) {

    const [extrapolatedValue, _setExtrapolatedValue] = useState<undefined | number>();
    const intervalRef = useRef<number>();
    const extrapolatedValueRef = useRef<number | undefined>(props.value);
    const extrapolatedValueInterval = useRef<number>();

    const setExtrapolatedValue = (value: number | undefined) => {
        let newValue = (value !== undefined && props.valueNext !== undefined) ? (value >= props.valueNext ? props.value : value) : undefined;
        extrapolatedValueRef.current = newValue;
        _setExtrapolatedValue(value)
    }

    // useEffect(() => {
    //         return () => {
    //             if (intervalRef.current) {
    //                 clearInterval(intervalRef.current)
    //             }
    //
    //         }
    //     }, []
    // )

    useEffect(() => {

        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

        if (props.valueNext && props.value !== undefined) {

            if (extrapolatedValueInterval.current === undefined) {
                // every 2 seconds
                extrapolatedValueInterval.current = (props.valueNext - props.value) / (5000 / PROGRESS_REFRESH_RATE);
            }
            let updateValue = () => {
                const interval = extrapolatedValueRef.current !== undefined ? extrapolatedValueRef.current : props.value;
                if (extrapolatedValueInterval.current && interval !== undefined) {
                    //(extrapolatedValue !== undefined ? extrapolatedValue : props.value) as number;
                    setExtrapolatedValue(interval + extrapolatedValueInterval.current)

                }
            }

            updateValue();
            const id = setInterval(() => {
                updateValue();
                // alert("II")

            }, PROGRESS_REFRESH_RATE) as unknown as number;

            intervalRef.current = id;
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }

        }


    }, [props.valueNext, props.value])

    if (props.valueNext !== undefined) {

    }

    const variant: 'determinate' | 'indeterminate' = extrapolatedValue !== undefined ? "determinate" : (props.value !== undefined ? "determinate" : "indeterminate")
    const value = extrapolatedValue !== undefined ? extrapolatedValue : props.value;
    return <Container maxWidth={"lg"}
                      sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "75vh",
                          alignItems: "center",
                          flexDirection: "column"
                      }}
    >
        <Box height={"9rem"}/>
        <Box position={"relative"}>
            <CircularProgress size={"5rem"}

                              color={props.error ? "error" : undefined}
                              value={value}
                              variant={variant}/>
            {/*/!*{props.value && <Box position={"absolute"}>*!/*/}
            {/*/!*    <Typography>{Math.round(props.value)}%</Typography>*!/*/}
            {/*</Box>}*/}
            {/*<Box>{value} ({extrapolatedValueInterval.current} = {props.valueNext} - {props.value})</Box>*/}
            {/*<Box>{props.valueNext}</Box>*/}
        </Box>
        <Stack height={"11rem"} maxHeight={"11rem"} justifyContent={"flex-start"} alignItems={"center"}>
            {props.label && <Typography>{props.label}</Typography>}
            {props.sublabel && <Typography variant={"subtitle2"}>{props.sublabel}</Typography>}
            {props.error &&
                <Typography variant={"subtitle2"}>Please try refreshing the page, come back later or contact me (see about) if that
                    doesn't work</Typography>}
        </Stack>
        {/*<JSONTree data={_loadedDataView}/>*/}
    </Container>
}