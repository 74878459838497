import {Box, Container, Grid, Stack} from "@mui/material";
import {GridItem, GridItemCardContainer} from "../Grid/GridItem";
import {
    NewDataRow,
    NewDataTable,
    NewDataTableGroupWithSummary,
    NewTableGroupSummary,
    PlayerMetaData
} from "../../Data/ModelGenerated";
import {MapDataTableForPlayer} from "../Table/DataTable";
import {getEloBracketForElo} from "../../Data/CheckType";
import {TableOrGridOrScatterPlotRenderer} from "../Table/TableOrGridOrScatterPlotRenderer";
import {LoadingIndicator} from "../LoadingIndicator";
import * as React from "react";
import {DescriptiveStatsTableWrapper, TTablePromiseState} from "./ItemDetailsView";
import {DerivedCivOverview1v1} from "../DerivedStatsComponents/DerivedCivOverviewTeam";

export interface IItemDetailsViewContainerProps {

    useDesktopLayout: boolean
    metaDataView: JSX.Element
    viewBeingGenerated: boolean
    largerNameLabel: boolean
    tall: boolean
    data?: NewDataTableGroupWithSummary
    summaryCard: JSX.Element | undefined
    additionalSummaryCards: JSX.Element[]

    tableViews: (JSX.Element | undefined)[]
    dataPromiseStates: TTablePromiseState[]
    item_render_key: string
}

export interface IItemTableWrapperProps {
    item_render_key: string | number
    table: JSX.Element
}

export function ItemTableWrapper(props: IItemTableWrapperProps) {
    return <Grid item {...{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}} key={props.item_render_key}>
        {/*MAPS:{JSON.stringify(props.path)} {t.type}*/}
        {/* @ts-ignore*/}
        {props.table}
    </Grid>

}

export function ItemDetailsViewContainer(props: IItemDetailsViewContainerProps) {

    const {
        useDesktopLayout,
        metaDataView,
        viewBeingGenerated,
        largerNameLabel,
        tall,
        data,
        summaryCard,
        additionalSummaryCards,
        tableViews,
        dataPromiseStates,
        item_render_key
    } = {...props}

    const derivedStatsView = props.data ? <DerivedCivOverview1v1 data={props.data}/> : undefined;

    return <Container maxWidth={"xl"}
                      sx={{
                          marginTop: "20px",
                          paddingLeft: useDesktopLayout ? undefined : "0px !important;",
                          paddingRight: useDesktopLayout ? undefined : "0px !important;"
                      }}
    >

        {!viewBeingGenerated && <Grid container spacing={2}>

            {metaDataView}
            {/*!!! This is player profile info card / civ info card*/}
            {data && <GridItem largerNameLabel={largerNameLabel}
                               tall={tall}
                               data={data}
                               derivedStats={derivedStatsView}
                               size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 5}}
            />}



            {summaryCard}

            {additionalSummaryCards}

            {/*<Grid item {...{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}} sx={{flexGrow: 1}}>*/}
            {/*    <Card sx={{height: "100%"}}>*/}
            {/*        <Skeleton variant="rectangular" width={"100%"}*/}
            {/*                  sx={{margin: "2.5%", height: "95%"}}/>*/}
            {/*    </Card>*/}
            {/*</Grid>*/}

            {tableViews}
            {dataPromiseStates.map((st) => {
                if (st.data) {
                    return <Grid item {...{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}>
                        <DescriptiveStatsTableWrapper key={item_render_key} t={st.data}/>
                    </Grid>
                } else {
                    return <Grid height={381} item {...{xs: 12, sm: 12, md: 12, lg: 12, xl: 12}} key={item_render_key}>
                        <GridItemCardContainer content={<Stack height={381} direction={"row"} justifyContent={"center"}>
                            <Stack justifyContent={"center"} height={381}>
                                <LoadingIndicator/>
                            </Stack>
                        </Stack>}/>
                    </Grid>
                }
            })}

        </Grid>}
        {/*RENDER KEY : {item_render_key}*/}
        {/*<br/>*/}
        {/*DATA KEY: {props.dataKey}*/}
        {/*<JSONTree data={props.data}/>*/}
    </Container>

    // return <Box>123</Box>
}