export const DEFINES = {
    "dataset": {
        "version": "33315",
        "name": "Definitive Edition"
    },
    "civilizations": {
        "1": {
            "name": "Britons"
        },
        "2": {
            "name": "Franks"
        },
        "3": {
            "name": "Goths"
        },
        "4": {
            "name": "Teutons"
        },
        "5": {
            "name": "Japanese"
        },
        "6": {
            "name": "Chinese"
        },
        "7": {
            "name": "Byzantines"
        },
        "8": {
            "name": "Persians"
        },
        "9": {
            "name": "Saracens"
        },
        "10": {
            "name": "Turks"
        },
        "11": {
            "name": "Vikings"
        },
        "12": {
            "name": "Mongols"
        },
        "13": {
            "name": "Celts"
        },
        "14": {
            "name": "Spanish"
        },
        "15": {
            "name": "Aztecs"
        },
        "16": {
            "name": "Mayans"
        },
        "17": {
            "name": "Huns"
        },
        "18": {
            "name": "Koreans"
        },
        "19": {
            "name": "Italians"
        },
        "20": {
            "name": "Indians"
        },
        "21": {
            "name": "Incas"
        },
        "22": {
            "name": "Magyars"
        },
        "23": {
            "name": "Slavs"
        },
        "24": {
            "name": "Portuguese"
        },
        "25": {
            "name": "Ethiopians"
        },
        "26": {
            "name": "Malians"
        },
        "27": {
            "name": "Berbers"
        },
        "28": {
            "name": "Khmer"
        },
        "29": {
            "name": "Malay"
        },
        "30": {
            "name": "Burmese"
        },
        "31": {
            "name": "Vietnamese"
        },
        "32": {
            "name": "Bulgarians"
        },
        "33": {
            "name": "Tatars"
        },
        "34": {
            "name": "Cumans"
        },
        "35": {
            "name": "Lithuanians"
        },
        "36": {
            "name": "Burgundians"
        },
        "37": {
            "name": "Sicilians"
        }
    },
    "maps": {
        "9": "Arabia",
        "10": "Archipelago",
        "11": "Baltic",
        "12": "Black Forest",
        "13": "Coastal",
        "14": "Continental",
        "15": "Crater Lake",
        "16": "Fortress",
        "17": "Gold Rush",
        "18": "Highland",
        "19": "Islands",
        "20": "Mediterranean",
        "21": "Migration",
        "22": "Rivers",
        "23": "Team Islands",
        "24": "Full Random",
        "25": "Scandinavia",
        "26": "Mongolia",
        "27": "Salt Marsh",
        "28": "Yucatan",
        "29": "Arena",
        "30": "King of the Hill",
        "31": "Oasis",
        "32": "Ghost Lake",
        "33": "Nomad",
        "67": "Acropolis",
        "68": "Budapest",
        "69": "Cenotes",
        "70": "City of Lakes",
        "71": "Golden Pit",
        "72": "Hideout",
        "73": "Hill Fort",
        "74": "Lombardia",
        "75": "Steppe",
        "76": "Valley",
        "77": "MegaRandom",
        "78": "Hamburger",
        "79": "CtR Random",
        "80": "CtR Monsoon",
        "81": "CtR Pyramid Descent",
        "82": "CtR Spiral",
        "83": "Kilimanjaro",
        "84": "Mountain Pass",
        "86": "Serengeti",
        "87": "Socotra",
        "112": "Bog Islands",
        "122": "Alpine Lakes",
        "125": "Ravines",
        "126": "Wolf Hill",
        "139": "Golden Swamp",
        "140": "Four Lakes",
        "148": "Coastal Forest",
        "149": "African Clearing",
        "150": "Atacama",
        "151": "Seize the Mountain"
    },
    "technologies": {
        "0": "",
        "1": "",
        "2": "Elite Tarkan",
        "3": "Yeomen",
        "4": "El Dorado",
        "5": "Furor Celtica",
        "6": "Drill",
        "7": "Mahouts",
        "8": "Town Watch",
        "9": "Zealotry",
        "10": "Artillery",
        "11": "Crenellations",
        "12": "Crop Rotation",
        "13": "Heavy Plow",
        "14": "Horse Collar",
        "15": "Guilds",
        "16": "Anarchy",
        "17": "Banking",
        "18": "",
        "19": "Cartography",
        "20": "",
        "21": "Atheism",
        "22": "Loom",
        "23": "Coinage",
        "24": "Garland Wars",
        "25": "",
        "26": "",
        "27": "Elite Plumed Archer",
        "28": "",
        "29": "",
        "30": "",
        "31": "",
        "32": "",
        "33": "",
        "34": "War Galley",
        "35": "Galleon",
        "36": "",
        "37": "Cannon Galleon",
        "38": "",
        "39": "Husbandry",
        "40": "",
        "41": "",
        "42": "",
        "43": "",
        "44": "",
        "45": "Faith",
        "46": "",
        "47": "Chemistry",
        "48": "Caravan",
        "49": "Berserkergang",
        "50": "Masonry",
        "51": "Architecture",
        "52": "Rocketry",
        "53": "",
        "54": "Treadmill Crane",
        "55": "Gold Mining",
        "56": "",
        "57": "",
        "58": "",
        "59": "Kataparuto",
        "60": "Elite Conquistador",
        "61": "Logistica",
        "62": "",
        "63": "Keep",
        "64": "Bombard Tower",
        "65": "Gillnets",
        "66": "",
        "67": "Forging",
        "68": "Iron Casting",
        "69": "",
        "70": "",
        "71": "",
        "72": "",
        "73": "",
        "74": "Scale Mail Armor",
        "75": "Blast Furnace",
        "76": "Chain Mail Armor",
        "77": "Plate Mail Armor",
        "78": "",
        "79": "",
        "80": "Plate Barding Armor",
        "81": "Scale Barding Armor",
        "82": "Chain Barding Armor",
        "83": "Bearded Axe",
        "84": "",
        "85": "Hand Cannon",
        "86": "",
        "87": "",
        "88": "",
        "89": "",
        "90": "Tracking",
        "91": "",
        "92": "",
        "93": "Ballistics",
        "94": "Scorpion",
        "95": "",
        "96": "Capped Ram",
        "97": "",
        "98": "Elite Skirmisher",
        "99": "",
        "100": "Crossbowman",
        "101": "Feudal Age",
        "102": "Castle Age",
        "103": "Imperial Age",
        "104": "Dark Age",
        "105": "",
        "106": "",
        "107": "",
        "108": "",
        "109": "",
        "110": "",
        "111": "",
        "112": "",
        "113": "",
        "114": "",
        "115": "",
        "116": "",
        "117": "",
        "118": "",
        "119": "",
        "120": "",
        "121": "",
        "122": "",
        "123": "",
        "124": "",
        "125": "",
        "126": "",
        "127": "",
        "128": "",
        "129": "",
        "130": "",
        "131": "",
        "132": "",
        "133": "",
        "134": "",
        "135": "",
        "136": "",
        "137": "",
        "138": "",
        "139": "",
        "140": "Guard Tower",
        "141": "",
        "142": "",
        "143": "",
        "144": "",
        "145": "",
        "146": "",
        "147": "",
        "148": "",
        "149": "",
        "150": "",
        "151": "",
        "152": "",
        "153": "",
        "154": "",
        "155": "",
        "156": "",
        "157": "",
        "158": "",
        "159": "",
        "160": "",
        "161": "",
        "162": "",
        "163": "",
        "164": "",
        "165": "",
        "166": "",
        "167": "",
        "168": "",
        "169": "",
        "170": "",
        "171": "",
        "172": "",
        "173": "",
        "174": "",
        "175": "",
        "176": "",
        "177": "",
        "178": "",
        "179": "",
        "180": "",
        "181": "",
        "182": "Gold Shaft Mining",
        "183": "",
        "184": "",
        "185": "",
        "186": "",
        "187": "",
        "188": "Bombard Cannon",
        "189": "",
        "190": "",
        "191": "",
        "192": "",
        "193": "",
        "194": "Fortified Wall",
        "195": "",
        "196": "",
        "197": "Pikeman",
        "198": "",
        "199": "Fletching",
        "200": "Bodkin Arrow",
        "201": "Bracer",
        "202": "Double-Bit Axe",
        "203": "Bow Saw",
        "204": "",
        "205": "",
        "206": "",
        "207": "Long Swordsman",
        "208": "",
        "209": "Cavalier",
        "210": "",
        "211": "Padded Archer Armor",
        "212": "Leather Archer Armor",
        "213": "Wheelbarrow",
        "214": "",
        "215": "Squires",
        "216": "",
        "217": "Two-Handed Swordsman",
        "218": "Heavy Cav Archer",
        "219": "Ring Archer Armor",
        "220": "",
        "221": "Two-Man Saw",
        "222": "Man-at-Arms",
        "223": "",
        "224": "",
        "225": "",
        "226": "",
        "227": "",
        "228": "",
        "229": "",
        "230": "Block Printing",
        "231": "Sanctity",
        "232": "",
        "233": "Illumination",
        "234": "",
        "235": "",
        "236": "Heavy Camel Rider",
        "237": "Arbalester",
        "238": "",
        "239": "Heavy Scorpion",
        "240": "",
        "241": "",
        "242": "",
        "243": "",
        "244": "Heavy Demolition Ship",
        "245": "",
        "246": "Fast Fire Ship",
        "247": "",
        "248": "",
        "249": "Hand Cart",
        "250": "",
        "251": "",
        "252": "Fervor",
        "253": "",
        "254": "Light Cavalry",
        "255": "Siege Ram",
        "256": "",
        "257": "Onager",
        "258": "",
        "259": "",
        "260": "",
        "261": "",
        "262": "",
        "263": "",
        "264": "Champion",
        "265": "Paladin",
        "266": "",
        "267": "",
        "268": "",
        "269": "",
        "270": "",
        "271": "",
        "272": "",
        "273": "",
        "274": "",
        "275": "",
        "276": "",
        "277": "",
        "278": "Stone Mining",
        "279": "Stone Shaft Mining",
        "280": "Town Patrol",
        "281": "",
        "282": "",
        "283": "",
        "284": "",
        "285": "",
        "286": "",
        "287": "",
        "288": "",
        "289": "",
        "290": "",
        "291": "",
        "292": "",
        "293": "",
        "294": "",
        "295": "",
        "296": "",
        "297": "",
        "298": "",
        "299": "",
        "300": "",
        "301": "",
        "302": "",
        "303": "",
        "304": "",
        "305": "",
        "306": "",
        "307": "",
        "308": "",
        "309": "",
        "310": "",
        "311": "",
        "312": "",
        "313": "",
        "314": "",
        "315": "Conscription",
        "316": "Redemption",
        "317": "",
        "318": "",
        "319": "Atonement",
        "320": "Siege Onager",
        "321": "Sappers",
        "322": "Murder Holes",
        "323": "",
        "324": "",
        "325": "",
        "326": "",
        "327": "",
        "328": "",
        "329": "",
        "330": "",
        "331": "",
        "332": "",
        "333": "",
        "334": "",
        "335": "",
        "336": "",
        "337": "",
        "338": "",
        "339": "",
        "340": "",
        "341": "",
        "342": "",
        "343": "",
        "344": "",
        "345": "",
        "346": "",
        "347": "",
        "348": "",
        "349": "",
        "350": "",
        "351": "",
        "352": "",
        "353": "",
        "354": "",
        "355": "",
        "356": "",
        "357": "",
        "358": "",
        "359": "",
        "360": "Elite Longbowman",
        "361": "Elite Cataphract",
        "362": "Elite Chu Ko Nu",
        "363": "Elite Throwing Axeman",
        "364": "Elite Teutonic Knight",
        "365": "Elite Huskarl",
        "366": "Elite Samurai",
        "367": "Elite War Elephant",
        "368": "Elite Mameluke",
        "369": "Elite Janissary",
        "370": "Elite Woad Raider",
        "371": "Elite Mangudai",
        "372": "Elite Longboat",
        "373": "Shipwright",
        "374": "Careening",
        "375": "Dry Dock",
        "376": "Elite Cannon Galleon",
        "377": "Siege Engineers",
        "378": "",
        "379": "Hoardings",
        "380": "Heated Shot",
        "381": "",
        "382": "",
        "383": "",
        "384": "Eagle Warrior",
        "385": "",
        "386": "",
        "387": "",
        "388": "",
        "389": "",
        "390": "",
        "391": "",
        "392": "",
        "393": "",
        "394": "",
        "395": "",
        "396": "",
        "397": "",
        "398": "Elite Berserk",
        "399": "",
        "400": "",
        "401": "",
        "402": "",
        "403": "",
        "404": "",
        "405": "",
        "406": "",
        "407": "",
        "408": "Spies/Treason",
        "409": "",
        "410": "",
        "411": "",
        "412": "",
        "413": "",
        "414": "",
        "415": "",
        "416": "",
        "417": "",
        "418": "",
        "419": "",
        "420": "",
        "421": "",
        "422": "",
        "423": "",
        "424": "",
        "425": "",
        "426": "",
        "427": "",
        "428": "Hussar",
        "429": "Halberdier",
        "430": "",
        "431": "",
        "432": "Elite Jaguar Warrior",
        "433": "",
        "434": "Elite Eagle Warrior",
        "435": "Bloodlines",
        "436": "Parthian Tactics",
        "437": "Thumb Ring",
        "438": "Theocracy",
        "439": "Heresy",
        "440": "Supremacy",
        "441": "Herbal Medicine",
        "442": "",
        "443": "",
        "444": "",
        "445": "Shinkichon",
        "446": "",
        "447": "",
        "448": "Elite Turtle Ship",
        "449": "",
        "450": "Elite War Wagon",
        "451": "",
        "452": "",
        "453": "",
        "454": "",
        "455": "",
        "456": "",
        "457": "Perfusion",
        "458": "",
        "459": "",
        "460": "Atlatl",
        "461": "Warwolf",
        "462": "Great Wall",
        "463": "Chieftains",
        "464": "Greek Fire",
        "465": "",
        "466": "",
        "467": "",
        "468": "Elite Genoese Crossbowman",
        "469": "",
        "470": "",
        "471": "",
        "472": "Elite Magyar Huszar",
        "473": "",
        "474": "",
        "475": "",
        "476": "",
        "477": "",
        "478": "",
        "479": "",
        "480": "",
        "481": "Elite Elephant Archer",
        "482": "Stronghold",
        "483": "Marauders",
        "484": "Yasama",
        "485": "Hul'che Javelineers",
        "486": "Eupseong",
        "487": "Nomads",
        "488": "Kamandaran",
        "489": "Ironclad",
        "490": "Madrasah",
        "491": "Sipahi",
        "492": "Inquisition",
        "493": "Chivalry",
        "494": "Pavise",
        "495": "",
        "496": "",
        "497": "",
        "498": "",
        "499": "Silk Road",
        "500": "",
        "501": "",
        "502": "",
        "503": "",
        "504": "Elite Boyar",
        "505": "",
        "506": "Sultans",
        "507": "Shatagni",
        "508": "",
        "509": "Elite Kamayuk",
        "510": "",
        "511": "",
        "512": "Orthodoxy",
        "513": "Druzhina",
        "514": "Corvinian Army",
        "515": "Recurve Bow",
        "516": "Andean Sling",
        "517": "Fabric Shields",
        "518": "",
        "519": "",
        "520": "",
        "521": "Imperial Camel Rider",
        "522": "",
        "523": "",
        "524": "",
        "525": "Revetments",
        "526": "",
        "527": "Fire Tower",
        "528": "",
        "529": "Britons",
        "530": "Franks",
        "531": "Goths",
        "532": "Teutons",
        "533": "Japanese",
        "534": "Chinese",
        "535": "Byzantines",
        "536": "Persians",
        "537": "Saracens",
        "538": "Turks",
        "539": "Vikings",
        "540": "Mongols",
        "541": "Celts",
        "542": "Spanish",
        "543": "Aztecs",
        "544": "Mayans",
        "545": "Huns",
        "546": "Koreans",
        "547": "Italians",
        "548": "Indians",
        "549": "Incas",
        "550": "Magyars",
        "551": "Slavs",
        "552": "",
        "553": "",
        "554": "",
        "555": "Enable Sheep",
        "556": "Enable Llamas",
        "557": "Enable Cows",
        "558": "Enable Turkeys",
        "559": "",
        "560": "",
        "561": "",
        "562": "",
        "563": "Elite Organ Gun",
        "564": "",
        "565": "Elite Camel Archer",
        "566": "",
        "567": "Elite Gbeto",
        "568": "",
        "569": "Elite Shotel Warrior",
        "570": "",
        "571": "",
        "572": "Carrack",
        "573": "Arquebus",
        "574": "Royal Heirs",
        "575": "Torsion Engines",
        "576": "Tigui",
        "577": "Farimba",
        "578": "Kasbah",
        "579": "Maghrebi Camels",
        "580": "Portuguese",
        "581": "Ethiopians",
        "582": "Malians",
        "583": "Berbers",
        "584": "",
        "585": "",
        "586": "",
        "587": "",
        "588": "",
        "589": "",
        "590": "",
        "591": "",
        "592": "",
        "593": "",
        "594": "",
        "595": "",
        "596": "",
        "597": "Elite Caravel",
        "598": "",
        "599": "Elite Genitour",
        "600": "Free Cartography",
        "601": "",
        "602": "Arson",
        "603": "",
        "604": "",
        "605": "",
        "606": "",
        "607": "",
        "608": "Arrowslits",
        "609": "",
        "610": "",
        "611": "",
        "612": "",
        "613": "",
        "614": "",
        "615": "Elite Ballista Elephant",
        "616": "",
        "617": "Elite Karambit Warrior",
        "618": "",
        "619": "Elite Arambai",
        "620": "",
        "621": "Elite Rattan Archer",
        "622": "Tusk Swords",
        "623": "Double Crossbow",
        "624": "Thalassocracy",
        "625": "Forced Levy",
        "626": "Howdah",
        "627": "Manipur Cavalry",
        "628": "Chatras",
        "629": "Paper Money",
        "630": "",
        "631": "Elite Battle Elephant",
        "632": "",
        "633": "",
        "634": "",
        "635": "",
        "636": "",
        "637": "",
        "638": "",
        "639": "Town Center Spawn",
        "640": "",
        "641": "",
        "642": "",
        "643": "",
        "644": "",
        "645": "",
        "646": "",
        "647": "",
        "648": "",
        "649": "",
        "650": "Khmer",
        "651": "Malay",
        "652": "Burmese",
        "653": "Vietnamese",
        "654": "",
        "655": "Imperial Skirmisher",
        "656": "",
        "657": "",
        "658": "Set maximum population (no Houses)",
        "659": "",
        "660": "",
        "661": "",
        "662": "",
        "663": "",
        "664": "",
        "665": "Vietnamese Vision",
        "666": "",
        "667": "",
        "668": "",
        "669": "",
        "670": "",
        "671": "",
        "672": "",
        "673": "Bulgarians",
        "674": "Tatars",
        "675": "Cumans",
        "676": "Lithuanians",
        "677": "",
        "678": "Elite Konnik",
        "679": "",
        "680": "Elite Keshik",
        "681": "",
        "682": "Elite Kipchak",
        "683": "",
        "684": "Elite Leitis",
        "685": "Stirrups",
        "686": "Bagains",
        "687": "Silk Armor",
        "688": "Timurid Siegecraft",
        "689": "Steppe Husbandry",
        "690": "Cuman Mercenaries",
        "691": "Hill Forts",
        "692": "Tower Shields",
        "693": "",
        "694": "",
        "695": "",
        "696": "",
        "697": "",
        "698": "",
        "699": "",
        "700": "",
        "701": "",
        "702": "",
        "703": "",
        "704": "",
        "705": "",
        "706": "",
        "707": "",
        "708": "",
        "709": "",
        "710": "",
        "711": "",
        "712": "",
        "713": "",
        "714": "",
        "715": "Elite Steppe Lancer",
        "716": "Supplies",
        "717": "",
        "718": "",
        "719": "",
        "720": "",
        "721": "",
        "722": "",
        "723": "",
        "724": "",
        "725": "",
        "726": "",
        "727": "",
        "728": "",
        "729": "",
        "730": "",
        "731": "",
        "732": "",
        "733": "",
        "734": "",
        "735": "",
        "736": "",
        "737": "Resources last 15% longer",
        "738": "Resources last 30% longer",
        "739": "Resources last 40% longer",
        "740": "Resources last 50% longer",
        "741": "Resources last 75% longer",
        "742": "Resources last 100% longer",
        "743": "Resources last 125% longer",
        "744": "Resources last 150% longer",
        "745": "Resources last 175% longer",
        "746": "Resources last 200% longer",
        "747": "Resources last 300% longer",
        "748": "Burgundians",
        "749": "Sicilians",
        "750": "",
        "751": "Elite Coustillier",
        "752": "",
        "753": "Elite Serjeant",
        "754": "Burgundian Vineyards",
        "755": "Flemish Revolution",
        "756": "First Crusade",
        "757": "Scutage",
        "758": "",
        "759": "",
        "760": "",
        "761": "",
        "762": "",
        "763": "",
        "764": "",
        "765": "",
        "766": "",
        "767": "",
        "768": "",
        "769": "",
        "770": "",
        "771": "",
        "772": "",
        "773": "",
        "774": "",
        "775": "",
        "776": "",
        "777": "",
        "778": "",
        "779": "",
        "780": "",
        "781": "",
        "782": "",
        "783": "",
        "784": "",
        "785": "",
        "786": "",
        "787": "",
        "788": "",
        "789": "",
        "790": "",
        "791": "",
        "792": "",
        "793": "",
        "794": "",
        "795": "",
        "796": "",
        "797": "",
        "798": "",
        "799": "",
        "800": ""
    },
    "terrain": {
        "0": {
            "name": "Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "1": {
            "name": "Water, Shallow",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "2": {
            "name": "Beach",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "3": {
            "name": "Dirt 3",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "4": {
            "name": "Shallows",
            "colors": {
                "up": "#5492b0",
                "level": "#5492b0",
                "down": "#5492b0"
            }
        },
        "5": {
            "name": "Underbrush",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "6": {
            "name": "Dirt",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "7": {
            "name": "Farm1",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "8": {
            "name": "Farm2",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "9": {
            "name": "Grass 3",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "10": {
            "name": "Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "11": {
            "name": "Dirt 2",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "12": {
            "name": "Grass 2",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "13": {
            "name": "Palm Desert",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "14": {
            "name": "Desert",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "15": {
            "name": "Water, Old",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "16": {
            "name": "Old Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "17": {
            "name": "Jungle",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "18": {
            "name": "Bamboo",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "19": {
            "name": "Pine Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "20": {
            "name": "Forest, Oak Bush",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "21": {
            "name": "Snow Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "22": {
            "name": "Water, Deep",
            "colors": {
                "up": "#004aa1",
                "level": "#004aa1",
                "down": "#004aa1"
            }
        },
        "23": {
            "name": "Water, Medium",
            "colors": {
                "up": "#004abb",
                "level": "#004abb",
                "down": "#004abb"
            }
        },
        "24": {
            "name": "Road",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "25": {
            "name": "Road, Broken",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "26": {
            "name": "Ice Navigable",
            "colors": {
                "up": "#98c0f0",
                "level": "#98c0f0",
                "down": "#98c0f0"
            }
        },
        "27": {
            "name": "Foundation",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "28": {
            "name": "Water, Bridge",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "29": {
            "name": "Farm Cnst1",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "30": {
            "name": "Farm Cnst2",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "31": {
            "name": "Farm Cnst3",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "32": {
            "name": "Snow",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "35": {
            "name": "Ice",
            "colors": {
                "up": "#98c0f0",
                "level": "#98c0f0",
                "down": "#98c0f0"
            }
        },
        "36": {
            "name": "Snow Foundation",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "37": {
            "name": "Ice, Beach",
            "colors": {
                "up": "#98c0f0",
                "level": "#98c0f0",
                "down": "#98c0f0"
            }
        },
        "40": {
            "name": "Rock 1",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "41": {
            "name": "Savannah",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "42": {
            "name": "Dirt 4",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "45": {
            "name": "Desert, Cracked",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "46": {
            "name": "Desert, Quicksand",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "47": {
            "name": "Black",
            "colors": {
                "up": "#1c1c1c",
                "level": "#1c1c1c",
                "down": "#1c1c1c"
            }
        },
        "48": {
            "name": "Dragon Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "49": {
            "name": "Baobab Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "50": {
            "name": "Acacia Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "51": {
            "name": "Beach, White Vegetation",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "52": {
            "name": "Beach, Vegetation",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "53": {
            "name": "Beach, White",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "54": {
            "name": "Mangrove Shallows",
            "colors": {
                "up": "#5492b0",
                "level": "#5492b0",
                "down": "#5492b0"
            }
        },
        "55": {
            "name": "Mangrove Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "56": {
            "name": "Rainforest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "57": {
            "name": "Water, Deep Ocean",
            "colors": {
                "up": "#004aa1",
                "level": "#004aa1",
                "down": "#004aa1"
            }
        },
        "58": {
            "name": "Water, Azure",
            "colors": {
                "up": "#0054b0",
                "level": "#0054b0",
                "down": "#0054b0"
            }
        },
        "59": {
            "name": "Shallows, Azure",
            "colors": {
                "up": "#5492b0",
                "level": "#5492b0",
                "down": "#5492b0"
            }
        },
        "60": {
            "name": "Jungle Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "63": {
            "name": "RFarm1",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "64": {
            "name": "RFarm2",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "65": {
            "name": "RFarm Cnst1",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "66": {
            "name": "RFarm Cnst2",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "67": {
            "name": "RFarm Cnst3",
            "colors": {
                "up": "#8a8b57",
                "level": "#82884d",
                "down": "#768241"
            }
        },
        "68": {
            "name": "Reserved",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "69": {
            "name": "Reserved",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "70": {
            "name": "Gravel Default",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "71": {
            "name": "Underbrush, Leaves",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "72": {
            "name": "Underbrush, Snow",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "73": {
            "name": "Snow Light",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "74": {
            "name": "Snow Deep",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "75": {
            "name": "NEW Road, Fungus",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#bdd1fd"
            }
        },
        "76": {
            "name": "NEW Moorland",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "77": {
            "name": "NEW Leaves, Jungle",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "78": {
            "name": "NEW Road, Gravel (Desert)",
            "colors": {
                "up": "#f3aa5c",
                "level": "#e4a252",
                "down": "#da9c69"
            }
        },
        "79": {
            "name": "Beach (Non-Navigable)",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "80": {
            "name": "Beach, Wet (Non-Navigable)",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "81": {
            "name": "Beach, Gravel Wet (Non-Navigable)",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "82": {
            "name": "Beach, Rock Wet (Non-Navigable)",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "83": {
            "name": "Jungle Grass (Rainforest)",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "84": {
            "name": "Moddable Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "85": {
            "name": "Moddable Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "86": {
            "name": "Moddable Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "87": {
            "name": "Moddable Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "88": {
            "name": "Forest, Mediterranean",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "89": {
            "name": "Forest, Bush",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "90": {
            "name": "Forest, Reeds (Shallows)",
            "colors": {
                "up": "#5492b0",
                "level": "#5492b0",
                "down": "#5492b0"
            }
        },
        "91": {
            "name": "Forest, Reeds (Beach)",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "92": {
            "name": "Forest, Reeds",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "93": {
            "name": "Moddable Shallows",
            "colors": {
                "up": "#5492b0",
                "level": "#5492b0",
                "down": "#5492b0"
            }
        },
        "94": {
            "name": "Moddable Shallows",
            "colors": {
                "up": "#5492b0",
                "level": "#5492b0",
                "down": "#5492b0"
            }
        },
        "95": {
            "name": "Water, Green",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "96": {
            "name": "Water, Brown",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "97": {
            "name": "Moddable Normal Water",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "98": {
            "name": "Moddable Normal Water",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "99": {
            "name": "Moddable Deep Water",
            "colors": {
                "up": "#305db6",
                "level": "#305db6",
                "down": "#305db6"
            }
        },
        "100": {
            "name": "Dry Grass",
            "colors": {
                "up": "#00a900",
                "level": "#339727",
                "down": "#008d00"
            }
        },
        "101": {
            "name": "Bogland",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "102": {
            "name": "Desert, Gravel",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "104": {
            "name": "Forest, Autumn",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "105": {
            "name": "Show Autumn",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "106": {
            "name": "Dead Forest",
            "colors": {
                "up": "#257439",
                "level": "#157615",
                "down": "#007200"
            }
        },
        "107": {
            "name": "Beach, Wet",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "108": {
            "name": "Beach, Gravel Wet",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        },
        "109": {
            "name": "Beach, Rock Wet",
            "colors": {
                "up": "#f8c98a",
                "level": "#e8b478",
                "down": "#bd966f"
            }
        }
    },
    "objects": {
        "0": "Moveable Map Revealer",
        "1": "Legionary",
        "4": "Archer",
        "5": "Hand Cannoneer",
        "6": "Elite Skirmisher",
        "7": "Skirmisher",
        "8": "Longbowman",
        "10": "Archery Range",
        "11": "Mangudai",
        "12": "Barracks",
        "13": "Fishing Ship",
        "14": "Archery Range",
        "15": "Junk",
        "17": "Trade Cog",
        "18": "Blacksmith",
        "19": "Blacksmith",
        "20": "Barracks",
        "21": "War Galley",
        "24": "Crossbowman",
        "25": "Teutonic Knight",
        "30": "Monastery",
        "31": "Monastery",
        "32": "Monastery",
        "33": "Fortress",
        "35": "Battering Ram",
        "36": "Bombard Cannon",
        "37": "Light Cavalry",
        "38": "Knight",
        "39": "Cavalry Archer",
        "40": "Cataphract",
        "41": "Huskarl",
        "42": "Trebuchet",
        "45": "Dock",
        "46": "Janissary",
        "47": "Dock",
        "48": "Wild Boar",
        "49": "Siege Workshop",
        "50": "Farm",
        "51": "Dock",
        "52": "Royal Janissary",
        "53": "Fish (Perch)",
        "56": "Fisherman",
        "57": "Fisherman",
        "59": "Forage Bush",
        "61": "Dolphin",
        "63": "Fortified Gate (up.)",
        "64": "Gate (up.)",
        "65": "Deer",
        "66": "Gold Mine",
        "67": "Fortified Gate",
        "68": "Mill",
        "69": "Shore Fish",
        "70": "House",
        "71": "Town Center",
        "72": "Palisade Wall",
        "73": "Chu Ko Nu",
        "74": "Militia",
        "75": "Man-at-Arms",
        "76": "Heavy Swordsman",
        "77": "Long Swordsman",
        "78": "Gate",
        "79": "Watch Tower",
        "80": "Fortified Gate",
        "81": "Gate",
        "82": "Castle",
        "84": "Market",
        "85": "Fortified Gate (down.)",
        "86": "Stable",
        "87": "Archery Range",
        "88": "Gate (down.)",
        "89": "Dire Wolf",
        "90": "Fortified Gate",
        "91": "Gate",
        "92": "Fortified Gate",
        "93": "Spearman",
        "94": "Berserk",
        "95": "Gate",
        "96": "Hawk",
        "101": "Stable",
        "102": "Stone Mine",
        "103": "Blacksmith",
        "104": "Monastery",
        "105": "Blacksmith",
        "106": "Leif Erikson",
        "109": "Town Center",
        "110": "Trade Workshop",
        "112": "Flare",
        "114": "Stoertebeker",
        "116": "Market",
        "117": "Stone Wall",
        "118": "Builder",
        "119": "Fortified Palisade Wall",
        "120": "Forager",
        "122": "Hunter",
        "123": "Lumberjack",
        "124": "Stone Miner",
        "125": "Monk",
        "126": "Wolf",
        "128": "Trade Cart (Empty)",
        "129": "Mill",
        "130": "Mill",
        "131": "Mill",
        "132": "Barracks",
        "133": "Dock",
        "137": "Market",
        "141": "Town Center",
        "142": "Town Center",
        "143": "Rubble 1 x 1",
        "144": "Rubble 2 x 2",
        "145": "Rubble 3 x 3",
        "146": "Rubble 4 x 4",
        "147": "Rubble 6 x 6",
        "148": "Rubble 8 x 8",
        "150": "Siege Workshop",
        "153": "Stable",
        "155": "Fortified Wall",
        "156": "Repairer",
        "159": "Relic Cart",
        "160": "Richard the Lionheart",
        "161": "The Black Prince",
        "163": "Friar Tuck",
        "164": "Sheriff of Nottingham",
        "165": "Charlemagne",
        "166": "Roland",
        "167": "Belisarius",
        "168": "Theodoric the Goth",
        "169": "Aethelfrith",
        "170": "Siegfried",
        "171": "Erik the Red",
        "172": "Tamerlane",
        "173": "King Arthur",
        "174": "Lancelot",
        "175": "Gawain",
        "176": "Mordred",
        "177": "Archbishop",
        "179": "Trade Workshop",
        "184": "Condottiero",
        "185": "Slinger",
        "188": "Flamethrower",
        "190": "Fire Tower",
        "191": "House",
        "192": "House",
        "193": "Vlad Dracula",
        "195": "Kitabatake",
        "196": "Minamoto",
        "197": "Alexander Nevski",
        "198": "El Cid",
        "199": "Fish Trap",
        "200": "Robin Hood",
        "201": "Tree",
        "202": "Rabid Wolf",
        "203": "Vasco da Gama",
        "204": "Trade Cart (Full)",
        "206": "VMDL",
        "207": "Imperial Camel Rider",
        "209": "University",
        "210": "University",
        "212": "Builder",
        "214": "Farmer",
        "216": "Hunter",
        "218": "Lumberjack",
        "220": "Stone Miner",
        "222": "Repairer",
        "223": "Alaric the Goth",
        "230": "King Bela IV",
        "231": "Aqueduct",
        "232": "Woad Raider",
        "234": "Guard Tower",
        "235": "Keep",
        "236": "Bombard Tower",
        "239": "War Elephant",
        "241": "Cracks",
        "248": "Pile of Stone",
        "250": "Longboat",
        "251": "Amphitheatre",
        "252": "Pile of Gold",
        "253": "Pile of Wood",
        "259": "Farmer",
        "262": "Pile of Food",
        "263": "Colosseum",
        "-1": "Test String",
        "274": "Flare",
        "275": "Centurion",
        "276": "Wonder",
        "278": "Dead Fish Trap",
        "279": "Scorpion",
        "280": "Mangonel",
        "281": "Throwing Axeman",
        "282": "Mameluke",
        "283": "Cavalier",
        "284": "Tree TD",
        "285": "Relic",
        "286": "Monk with Relic",
        "287": "British Relic",
        "288": "Byzantine Relic",
        "289": "Chinese Relic",
        "290": "Frankish Relic",
        "291": "Samurai",
        "292": "Gothic Relic",
        "294": "Japanese Relic",
        "295": "Persian Relic",
        "296": "Saracen Relic",
        "297": "Teutonic Relic",
        "298": "Turkish Relic",
        "299": "Bandit",
        "301": "Grass Patch, Green",
        "302": "Bush A",
        "303": "Seagulls",
        "304": "Bonfire",
        "305": "Llama",
        "306": "Black Tile",
        "307": "Cuauhtemoc",
        "309": "Monk with Turkish Relic",
        "310": "Mountain 1",
        "311": "Mountain 2",
        "329": "Camel Rider",
        "330": "Heavy Camel Rider",
        "331": "Trebuchet (Packed)",
        "332": "Flare",
        "333": "Deer",
        "334": "Flowers 1",
        "335": "Flowers 2",
        "336": "Flowers 3",
        "337": "Flowers 4",
        "338": "Path 4",
        "339": "Path 1",
        "340": "Path 2",
        "341": "Path 3",
        "342": "Cuman Chief",
        "345": "Ruins",
        "347": "Test String",
        "348": "Tree (Bamboo Forest)",
        "349": "Tree (Oak)",
        "350": "Tree (Pine Forest)",
        "351": "Tree (Palm Forest)",
        "354": "Forager",
        "357": "Dead Farm",
        "358": "Pikeman",
        "359": "Halberdier",
        "361": "Norse Warrior",
        "370": "City Wall",
        "389": "Sea Rocks 1",
        "396": "Sea Rocks 2",
        "399": "Tree A",
        "400": "Tree B",
        "401": "Tree C",
        "402": "Tree D",
        "403": "Tree E",
        "404": "Tree F",
        "405": "Tree G",
        "406": "Tree H",
        "407": "Tree I",
        "408": "Tree J",
        "409": "Tree K",
        "410": "Tree L",
        "411": "Tree (Oak Forest)",
        "413": "Tree (Snow Pine)",
        "414": "Tree (Jungle)",
        "415": "Stump",
        "418": "Henry the Lion",
        "420": "Cannon Galleon",
        "422": "Capped Ram",
        "424": "Charles Martel",
        "425": "Francisco de Orellana",
        "426": "Harald Hardrada",
        "427": "Gonzalo Pizarro",
        "428": "Hrolf the Ganger",
        "429": "Frederick Barbarossa",
        "430": "Joan the Maid",
        "432": "William Wallace",
        "434": "King",
        "437": "Prithviraj",
        "439": "Francesco Sforza",
        "440": "Petard",
        "441": "Hussar",
        "442": "Galleon",
        "444": "Town Center (Packed)",
        "445": "Poenari Castle",
        "446": "Port",
        "448": "Scout Cavalry",
        "450": "Great Fish (Marlin)",
        "451": "Great Fish (Marlin)",
        "452": "Dolphin",
        "453": "Ataulf",
        "455": "Fish (Dorado)",
        "456": "Fish (Salmon)",
        "457": "Fish (Tuna)",
        "458": "Fish (Snapper)",
        "463": "House",
        "464": "House",
        "465": "House",
        "472": "Loot",
        "473": "Two-Handed Swordsman",
        "474": "Heavy Cavalry Archer",
        "481": "Town Center",
        "482": "Town Center",
        "483": "Town Center",
        "484": "Town Center",
        "486": "Bear",
        "487": "Gate",
        "488": "Fortified Gate",
        "490": "Gate",
        "491": "Fortified Gate",
        "492": "Arbalester",
        "493": "Heavy Crossbowman",
        "498": "Barracks",
        "499": "Torch A",
        "527": "Demolition Ship",
        "528": "Heavy Demolition Ship",
        "529": "Fire Ship",
        "530": "Elite Longbowman",
        "531": "Elite Throwing Axeman",
        "532": "Fast Fire Ship",
        "533": "Elite Longboat",
        "534": "Elite Woad Raider",
        "539": "Galley",
        "542": "Heavy Scorpion",
        "545": "Transport Ship",
        "546": "Light Cavalry",
        "548": "Siege Ram",
        "550": "Onager",
        "553": "Elite Cataphract",
        "554": "Elite Teutonic Knight",
        "555": "Elite Huskarl",
        "556": "Elite Mameluke",
        "557": "Elite Janissary",
        "558": "Elite War Elephant",
        "559": "Elite Chu Ko Nu",
        "560": "Elite Samurai",
        "561": "Elite Mangudai",
        "562": "Lumber Camp",
        "563": "Lumber Camp",
        "564": "Lumber Camp",
        "565": "Lumber Camp",
        "566": "Watch Tower",
        "567": "Champion",
        "569": "Paladin",
        "579": "Gold Miner",
        "581": "Gold Miner",
        "583": "Genitour",
        "584": "Mining Camp",
        "585": "Mining Camp",
        "586": "Mining Camp",
        "587": "Mining Camp",
        "588": "Siege Onager",
        "590": "Shepherd",
        "592": "Shepherd",
        "594": "Sheep",
        "596": "Elite Genitour",
        "597": "Town Center",
        "598": "Outpost",
        "599": "Cathedral",
        "600": "Flag A",
        "601": "Flag B",
        "602": "Flag C",
        "603": "Flag D",
        "604": "Flag E",
        "605": "Bridge A--Top",
        "606": "Bridge A--Middle",
        "607": "Bridge A--Bottom",
        "608": "Bridge B--Top",
        "609": "Bridge B--Middle",
        "610": "Bridge B--Bottom",
        "611": "Town Center",
        "612": "Town Center",
        "613": "Town Center",
        "614": "Town Center",
        "615": "Town Center",
        "616": "Town Center",
        "617": "Town Center",
        "618": "Town Center",
        "619": "Town Center",
        "620": "Town Center",
        "621": "Town Center",
        "623": "Rock 1",
        "624": "Pavilion A",
        "625": "Pavilion C",
        "626": "Pavilion B",
        "629": "Joan of Arc",
        "632": "Frankish Paladin",
        "634": "Sieur de Metz",
        "636": "Sieur Bertrand",
        "637": "Temple of Heaven",
        "638": "Duke D'Alen\u00e7on",
        "639": "Penguin",
        "640": "La Hire",
        "642": "Lord de Graville",
        "644": "Jean de Lorrain",
        "646": "Constable Richemont",
        "648": "Guy Josselyne",
        "650": "Jean Bureau",
        "652": "Sir John Fastolf",
        "655": "Mosque",
        "659": "Gate (hori.)",
        "660": "Fortified Gate (hori.)",
        "661": "Gate",
        "662": "Fortified Gate",
        "663": "Gate",
        "664": "Fortified Gate",
        "665": "Gate",
        "666": "Fortified Gate",
        "667": "Gate (vert.)",
        "668": "Fortified Gate (vert.)",
        "669": "Gate",
        "670": "Fortified Gate",
        "671": "Gate",
        "672": "Fortified Gate",
        "673": "Gate",
        "674": "Fortified Gate",
        "678": "Reynald de Chatillon",
        "680": "Master of the Templar",
        "682": "Bad Neighbor",
        "683": "God's Own Sling",
        "684": "The Accursed Tower",
        "685": "The Tower of Flies",
        "686": "Archer of the Eyes",
        "688": "Piece of the True Cross",
        "689": "Pyramid",
        "690": "Dome of the Rock",
        "691": "Elite Cannon Galleon",
        "692": "Berserk",
        "694": "Elite Berserk",
        "696": "Great Pyramid",
        "698": "Subotai",
        "700": "Hunting Wolf",
        "702": "Kushluk",
        "703": "Topa Yupanqui",
        "704": "Shah",
        "705": "Cow A",
        "706": "Saboteur",
        "707": "Ornlu the Wolf",
        "709": "Cactus",
        "710": "Skeleton",
        "711": "Rugs",
        "712": "Yurt A",
        "713": "Yurt B",
        "714": "Yurt C",
        "715": "Yurt D",
        "716": "Yurt E",
        "717": "Yurt F",
        "718": "Yurt G",
        "719": "Yurt H",
        "720": "Nine Bands",
        "721": "Shipwreck A",
        "722": "Shipwreck B",
        "723": "Crater",
        "725": "Jaguar Warrior",
        "726": "Elite Jaguar Warrior",
        "728": "Ice, Navigable",
        "729": "God's Own Sling (Packed)",
        "730": "Bad Neighbor (Packed)",
        "731": "Genghis Khan",
        "733": "Emperor in a Barrel",
        "737": "Bamboo Stump",
        "738": "Bridge A--Cracked",
        "739": "Bridge A--Broken Top",
        "740": "Bridge A--Broken Bottom",
        "741": "Bridge B--Cracked",
        "742": "Bridge B--Broken Top",
        "743": "Bridge B--Broken Bottom",
        "744": "Mountain 3",
        "745": "Mountain 4",
        "748": "Cobra Car",
        "749": "Cusi Yupanqui",
        "751": "Eagle Scout",
        "752": "Elite Eagle Warrior",
        "753": "Eagle Warrior",
        "755": "Tarkan",
        "757": "Elite Tarkan",
        "758": "Burned building",
        "759": "Huskarl",
        "761": "Elite Huskarl",
        "763": "Plumed Archer",
        "765": "Elite Plumed Archer",
        "771": "Conquistador",
        "773": "Elite Conquistador",
        "775": "Missionary",
        "777": "Attila the Hun",
        "778": "Canoe",
        "779": "Bleda the Hun",
        "781": "Pope Leo I",
        "783": "Scythian Wild Woman",
        "785": "Sea Tower",
        "788": "Sea Wall",
        "789": "Palisade Gate (up.)",
        "790": "Palisade Gate",
        "791": "Palisade Gate",
        "792": "Palisade Gate",
        "793": "Palisade Gate (down.)",
        "794": "Palisade Gate",
        "795": "Palisade Gate",
        "796": "Palisade Gate",
        "797": "Palisade Gate (hori.)",
        "798": "Palisade Gate",
        "799": "Palisade Gate",
        "800": "Palisade Gate",
        "801": "Palisade Gate (vert.)",
        "802": "Palisade Gate",
        "803": "Palisade Gate",
        "804": "Palisade Gate",
        "805": "Dock",
        "806": "Dock",
        "807": "Dock",
        "808": "Dock",
        "809": "Stump",
        "810": "Iron Boar",
        "812": "Jaguar",
        "814": "Horse A",
        "816": "Macaw",
        "818": "Plant",
        "819": "Sign",
        "820": "Grave",
        "821": "Head",
        "822": "Javelina",
        "824": "El Cid Campeador",
        "825": "Amazon Warrior",
        "826": "Monument",
        "827": "War Wagon",
        "829": "Elite War Wagon",
        "831": "Turtle Ship",
        "832": "Elite Turtle Ship",
        "833": "Turkey",
        "835": "Wild Horse",
        "837": "Map Revealer",
        "838": "King Sancho",
        "839": "Rock (Stone)",
        "840": "King Alfonso",
        "841": "Rock (Gold)",
        "842": "Imam",
        "844": "Admiral Yi Sun-shin",
        "845": "Nobunaga",
        "846": "Donkey",
        "847": "Henry V",
        "849": "William the Conqueror",
        "850": "Amazon Archer",
        "851": "ES Flag",
        "852": "Scythian Scout",
        "854": "Torch A (Convertable)",
        "855": "Old Stone Head",
        "856": "Roman Ruins",
        "857": "Hay Stack",
        "858": "Broken Cart",
        "859": "Flower Bed",
        "860": "Furious the Monkey Boy",
        "862": "Stormy Dog",
        "863": "Rubble 1 x 1",
        "864": "Rubble 2 x 2",
        "865": "Rubble 3 x 3",
        "866": "Genoese Crossbowman",
        "868": "Elite Genoese Crossbowman",
        "869": "Magyar Huszar",
        "871": "Elite Magyar Huszar",
        "872": "Quimper Cathedral",
        "873": "Elephant Archer",
        "875": "Elite Elephant Archer",
        "876": "Boyar",
        "878": "Elite Boyar",
        "879": "Kamayuk",
        "881": "Elite Kamayuk",
        "882": "Condottiero",
        "884": "Wild Camel",
        "885": "Siege Tower",
        "886": "Tarkan",
        "887": "Elite Tarkan",
        "892": "Heavy Pikeman",
        "894": "Eastern Swordsman",
        "896": "Waterfall (Overlay)",
        "897": "Camel",
        "899": "Arch of Constantine",
        "900": "Rain",
        "901": "Flag F",
        "902": "Smoke",
        "904": "Wooden Bridge A--Top",
        "905": "Wooden Bridge A--Middle",
        "906": "Wooden Bridge A--Bottom",
        "907": "Wooden Bridge B--Top",
        "908": "Wooden Bridge B--Middle",
        "909": "Wooden Bridge B--Bottom",
        "910": "Impaled Corpse",
        "914": "Quarry",
        "915": "Lumber",
        "916": "Goods",
        "917": "Vulture",
        "918": "Rock 2",
        "922": "Monk with Relic",
        "923": "Queen",
        "925": "Sanyogita",
        "926": "Prithvi",
        "927": "Chand Bardai",
        "929": "Saladin",
        "930": "Khosrau",
        "931": "Jarl",
        "932": "Savar",
        "933": "Barrels",
        "934": "Alfred the Alpaca",
        "936": "Elephant",
        "938": "Dragon Ship",
        "939": "Flame 1",
        "940": "Flame 2",
        "941": "Flame 3",
        "942": "Flame 4",
        "943": "Osman",
        "944": "Relic Cart",
        "1001": "Organ Gun",
        "1003": "Elite Organ Gun",
        "1004": "Caravel",
        "1006": "Elite Caravel",
        "1007": "Camel Archer",
        "1009": "Elite Camel Archer",
        "1010": "Genitour",
        "1012": "Elite Genitour",
        "1013": "Gbeto",
        "1015": "Elite Gbeto",
        "1016": "Shotel Warrior",
        "1018": "Elite Shotel Warrior",
        "1019": "Zebra",
        "1021": "Feitoria",
        "1023": "Priest",
        "1025": "Monk with Relic",
        "1026": "Ostrich",
        "1028": "Stork",
        "1029": "Lion",
        "1031": "Crocodile",
        "1033": "Grass Patch, Dry",
        "1034": "Musa ibn Nusayr",
        "1035": "Sundjata",
        "1036": "Tariq ibn Ziyad",
        "1037": "Richard de Clare",
        "1038": "Tristan",
        "1039": "Princess Yodit",
        "1040": "Henry II",
        "1041": "Mountain 5",
        "1042": "Mountain 6",
        "1043": "Mountain 7",
        "1044": "Mountain 8",
        "1045": "Snow Mountain 1",
        "1046": "Snow Mountain 2",
        "1047": "Snow Mountain 3",
        "1048": "Rock Formation 1",
        "1049": "Rock Formation 2",
        "1050": "Rock Formation 3",
        "1051": "Tree (Dragon)",
        "1052": "Tree (Baobab)",
        "1053": "Bush B",
        "1054": "Bush C",
        "1056": "Falcon",
        "1059": "Fruit Bush",
        "1060": "Goat",
        "1062": "Fence",
        "1063": "Tree (Acacia)",
        "1064": "Yekuno Amlak",
        "1066": "Yodit",
        "1067": "Itzcoatl",
        "1068": "Mustafa Pasha",
        "1069": "Pacal II",
        "1070": "Babur",
        "1071": "Abraha Elephant",
        "1072": "Guglielmo Embriaco",
        "1073": "Su Dingfang",
        "1074": "Pachacuti",
        "1075": "Huayna Capac",
        "1076": "Miklos Toldi",
        "1077": "Little John",
        "1078": "Zawisza the Black",
        "1080": "Sumanguru",
        "1081": "Storage",
        "1082": "Hut A",
        "1083": "Hut B",
        "1084": "Hut C",
        "1085": "Hut D",
        "1086": "Hut E",
        "1087": "Hut F",
        "1088": "Hut G",
        "1089": "Granary",
        "1090": "Barricade A",
        "1091": "Animal Skeleton",
        "1092": "Stelae A",
        "1093": "Stelae B",
        "1094": "Stelae C",
        "1095": "Gallow",
        "1096": "Palace",
        "1097": "Tent A",
        "1098": "Tent B",
        "1099": "Tent C",
        "1100": "Tent D",
        "1101": "Tent E",
        "1102": "Fortified Tower",
        "1103": "Fire Galley",
        "1104": "Demolition Raft",
        "1105": "Siege Tower",
        "1106": "Dagnajan",
        "1109": "Gidajan",
        "1120": "Ballista Elephant",
        "1122": "Elite Ballista Elephant",
        "1123": "Karambit Warrior",
        "1125": "Elite Karambit Warrior",
        "1126": "Arambai",
        "1128": "Elite Arambai",
        "1129": "Rattan Archer",
        "1131": "Elite Rattan Archer",
        "1132": "Battle Elephant",
        "1134": "Elite Battle Elephant",
        "1135": "Komodo Dragon",
        "1137": "Tiger",
        "1139": "Rhinoceros",
        "1141": "Box Turtles",
        "1142": "Water Buffalo",
        "1144": "Tree (Mangrove)",
        "1145": "Ninja",
        "1146": "Tree (Rainforest)",
        "1148": "Rock (Beach)",
        "1149": "Rock (Jungle)",
        "1150": "Flag G",
        "1151": "Flag H",
        "1152": "Flag I",
        "1153": "Flag J",
        "1155": "Imperial Skirmisher",
        "1157": "Gajah Mada",
        "1158": "Jayanegara",
        "1159": "Raden Wijaya",
        "1160": "Sunda Royal Fighter",
        "1162": "Suryavarman I",
        "1163": "Udayadityavarman I",
        "1164": "Jayaviravarman",
        "1165": "Bayinnaung",
        "1166": "Tabinshwehti",
        "1171": "Buddha Statue A",
        "1172": "Buddha Statue B",
        "1173": "Buddha Statue C",
        "1174": "Buddha Statue D",
        "1175": "Fern Patch",
        "1176": "Trowulan Gate",
        "1177": "Vases",
        "1178": "Le Loi",
        "1179": "Le Lai",
        "1180": "Le Lai",
        "1181": "Le Trien",
        "1182": "Luu Nhan Chu",
        "1183": "Bui Bi",
        "1184": "Dinh Le",
        "1185": "Wang Tong",
        "1186": "Envoy",
        "1187": "Rice Farm",
        "1188": "Dead Rice Farm",
        "1189": "Harbor",
        "1191": "Stupa",
        "1192": "Gate",
        "1196": "Army Tent A",
        "1197": "Army Tent B",
        "1198": "Army Tent C",
        "1199": "Army Tent D",
        "1200": "Army Tent E",
        "1201": "Pagoda A",
        "1202": "Pagoda B",
        "1203": "Pagoda C",
        "1204": "Bridge C--Top",
        "1205": "Bridge C--Middle",
        "1206": "Bridge C--Bottom",
        "1207": "Bridge D--Top",
        "1208": "Bridge D--Middle",
        "1209": "Bridge D--Bottom",
        "1210": "Bridge C--Cracked",
        "1211": "Bridge C--Broken Top",
        "1212": "Bridge C--Broken Bottom",
        "1213": "Bridge D--Cracked",
        "1214": "Bridge D--Broken Top",
        "1215": "Bridge D--Broken Bottom",
        "1216": "Sanchi Stupa",
        "1217": "Gol Gumbaz",
        "1218": "Barricade B",
        "1219": "Barricade C",
        "1220": "Barricade D",
        "1222": "Sharkatzor",
        "1225": "Konnik",
        "1227": "Elite Konnik",
        "1228": "Keshik",
        "1230": "Elite Keshik",
        "1231": "Kipchak",
        "1233": "Elite Kipchak",
        "1234": "Leitis",
        "1236": "Elite Leitis",
        "1237": "Bactrian Camel",
        "1239": "Ibex",
        "1241": "Snow Leopard",
        "1243": "Goose",
        "1245": "Pig",
        "1247": "Wild Bactrian Camel",
        "1248": "Tree (Oak Autumn)",
        "1249": "Tree (Oak Autumn Snow)",
        "1250": "Tree (Dead)",
        "1251": "Krepost",
        "1252": "Konnik (Dismounted)",
        "1253": "Elite Konnik (Dismounted)",
        "1254": "Konnik",
        "1255": "Elite Konnik",
        "1258": "Battering Ram",
        "1260": "Elite Kipchak",
        "1262": "Tokhtamysh Khan",
        "1263": "Flaming Camel",
        "1264": "Shrine",
        "1265": "Ivaylo",
        "1266": "Tsar Konstantin",
        "1267": "Kotyan Khan",
        "1268": "Cuman Chief",
        "1269": "Girgen Khan",
        "1270": "Dismantled Cart",
        "1271": "Ox Cart",
        "1273": "Ox Wagon",
        "1275": "Khan",
        "1276": "Urus Khan",
        "1279": "Statue (Civilization)",
        "1281": "Vytautas the Great",
        "1282": "Flag K",
        "1283": "Flag L",
        "1284": "Flag M",
        "1285": "FE Flag",
        "1290": "Ivaylo",
        "1291": "Invisible Object",
        "1292": "Queen",
        "1293": "Sanyogita",
        "1294": "Prithvi",
        "1295": "Chand Bardai",
        "1296": "Saladin",
        "1297": "Khosrau",
        "1298": "Jarl",
        "1299": "Savar",
        "1300": "Alfred the Alpaca",
        "1301": "Elephant",
        "1302": "Dragon Ship",
        "1303": "Osman",
        "1304": "Relic Cart",
        "1305": "Vulture",
        "1306": "Rain",
        "1307": "Flag F",
        "1308": "Smoke",
        "1309": "Wooden Bridge A--Top",
        "1310": "Wooden Bridge A--Middle",
        "1311": "Wooden Bridge A--Bottom",
        "1312": "Wooden Bridge B--Top",
        "1313": "Wooden Bridge B--Middle",
        "1314": "Wooden Bridge B--Bottom",
        "1315": "Impaled Corpse",
        "1319": "Quarry",
        "1320": "Lumber",
        "1321": "Goods",
        "1322": "Statue (Column)",
        "1323": "Rock 2",
        "1327": "Monk with Relic",
        "1330": "Barrels",
        "1333": "Flame 1",
        "1334": "Flame 2",
        "1335": "Flame 3",
        "1336": "Flame 4",
        "1338": "Cart",
        "1343": "Statue (Left)",
        "1345": "Statue (Right)",
        "1347": "Tree (Cypress)",
        "1348": "Tree (Italian Pine)",
        "1349": "Tree (Olive)",
        "1350": "Tree (Reeds)",
        "1351": "Plant (Jungle)",
        "1352": "Plant (Underbrush Tropical)",
        "1353": "Plant (Underbrush)",
        "1354": "Plant (Rainforest)",
        "1355": "Plant (Underbrush Rainforest)",
        "1356": "Horse B",
        "1358": "Grass, Green",
        "1359": "Grass, Dry",
        "1360": "Plant (Bush, Green)",
        "1361": "Plant (Bush, Dry)",
        "1362": "Plant (Shrub, Green)",
        "1363": "Plant (Shrub, Dry)",
        "1364": "Plant (Weeds)",
        "1365": "Plant (Dead)",
        "1366": "Plant (Flowers)",
        "1367": "Sankore Madrasah",
        "1368": "Tower of London",
        "1369": "Dormition Cathedral",
        "1370": "Steppe Lancer",
        "1372": "Elite Steppe Lancer",
        "1374": "Iroquois Warrior",
        "1376": "Torch B",
        "1377": "Torch B (Convertable)",
        "1378": "Rock Church",
        "1379": "Sea Gate",
        "1380": "Sea Gate",
        "1381": "Sea Gate",
        "1382": "Sea Gate",
        "1383": "Sea Gate",
        "1384": "Sea Gate",
        "1385": "Sea Gate",
        "1386": "Sea Gate",
        "1387": "Sea Gate",
        "1388": "Sea Gate",
        "1389": "Sea Gate",
        "1390": "Sea Gate",
        "1391": "Sea Gate",
        "1392": "Sea Gate",
        "1393": "Sea Gate",
        "1394": "Sea Gate",
        "1396": "Chain",
        "1397": "Chain",
        "1398": "Chain",
        "1399": "Chain",
        "1400": "Priest with Relic",
        "1497": "Rubble 4 x 4",
        "1498": "Rubble 8 x 8",
        "1550": "Bridge E--Top",
        "1551": "Bridge E--Middle",
        "1552": "Bridge E--Bottom",
        "1553": "Bridge F--Top",
        "1554": "Bridge F--Middle",
        "1555": "Bridge F--Bottom",
        "1556": "Bridge E--Cracked",
        "1557": "Bridge E--Broken Top",
        "1558": "Bridge E--Broken Bottom",
        "1559": "Bridge F--Cracked",
        "1560": "Bridge F--Broken Top",
        "1561": "Bridge F--Broken Bottom",
        "1562": "Paifang Gate",
        "1563": "Nubian Pyramid",
        "1564": "Target A",
        "1565": "Target B",
        "1566": "Temple Ruin",
        "1567": "Well",
        "1568": "Mounted Samurai",
        "1570": "Xolotl Warrior",
        "1572": "Merchant",
        "1574": "Sosso Guard",
        "1577": "Photonman",
        "1579": "City Gate",
        "1580": "City Gate",
        "1581": "City Gate",
        "1582": "City Gate",
        "1583": "City Gate",
        "1584": "City Gate",
        "1585": "City Gate",
        "1586": "City Gate",
        "1587": "City Gate",
        "1588": "City Gate",
        "1589": "City Gate",
        "1590": "City Gate",
        "1591": "City Gate",
        "1592": "City Gate",
        "1593": "City Gate",
        "1594": "City Gate",
        "1596": "Cow B",
        "1598": "Cow C",
        "1600": "Cow D",
        "1602": "Horse C",
        "1604": "Horse D",
        "1606": "Horse E",
        "1608": "Butterflies",
        "1609": "Butterflies",
        "1610": "Butterflies",
        "1622": "Aachen Cathedral",
        "1631": "The Middlebrook",
        "1635": "Waterfall (Background)",
        "1646": "Market",
        "1647": "Trade Workshop",
        "1665": "Donjon",
        "83": "Villager",
        "293": "Villager"
    }
}
