import {
    DescriptiveStatsTable,
    DescriptiveStatsTablePromise,
    GameDurationDescriptiveDataCollection,
    GenericTeamRMStatsTable,
    MetaData,
    NewDataRow,
    NewDataTable,

    NewDataTableGroupWithSummary,
    PlayerMetaData,
    PlayerQuickStatsTable,
    SampleSummaryValue,
    TableBytesWrapper
} from "../../Data/ModelGenerated";
import {GridItem, GridItemCardContainer, GridItemContainer} from "../Grid/GridItem";
import * as React from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    Grid,
    IconButton,
    Popover,
    Skeleton,
    Slider, Stack,
    ToggleButton,
    Typography, useMediaQuery
} from "@mui/material";
import styles from "../Grid/GridItem.module.scss";
import {DefaultDataTableForNewDataTable, MapDataTableForPlayer} from "../Table/DataTable";
import {IDefaultDataRendererProps, IPathObjectExtension} from "../DefaultDataRenderer";
import JSONTree from "react-json-tree";
import {useContext, useEffect, useState} from "react";
import {DataProviderContext} from "../../Internal/DataProvider";
import {Chart, Bar, Line} from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TableRowsIcon from '@mui/icons-material/TableRows';
import {LegendItem, Tooltip} from "chart.js";
import {DataProviderResponseMetaData, PlayerStatsViewQueryResponseMetaData} from "../../Data/Model";
import {DescriptiveStatsTableRenderer} from "../DescriptiveStats/DescriptiveStatsTableRenderer";
import {TableOrGridOrScatterPlotRenderer} from "../Table/TableOrGridOrScatterPlotRenderer";
import {TBaselineTableGroupSampleDelegate} from "../../Data/BaselineProviders";
import {ScatterPlotBaselineProvider} from "../ObjectScatterPlot";
import {
    DatasetSplitByEloCollectionWithLabel,
    getEloBracketForElo,
    NewDataSetWithLabel,
    TTeamSize
} from "../../Data/CheckType";
import {PlayerSummaryStatsRenderer} from "../PlayerSummary/PlayerSummaryStatsRenderer";
import {useTheme} from "@mui/material/styles";
import {LoadingIndicator} from "../LoadingIndicator";
import {AppSettingsContext} from "../../App";
import {TeamStatsViewGenericTableRenderer} from "./TeamStatsViewGenericTableRenderer";
import {
    IItemDetailsViewContainerProps,
    ItemDetailsViewContainer,
    ItemTableWrapper
} from "../ItemDetails/ItemDetailsViewContainer";
import {DerivedCivOverviewTeam} from "../DerivedStatsComponents/DerivedCivOverviewTeam";
import {PatchHistoryChart} from "../ItemDetails/ItemDetailsView";


export interface ItemDetailsViewBaselineProvider {
    getBaselineData: TBaselineTableGroupSampleDelegate
    key: string
    label: string

}


// TODO
// export interface ITeamStatsSummaryViewProps{
//     data: ITeamStatsSummaryViewProps
// }

const resolveTeamSizeKey = (teamSize: TTeamSize | string) => {

    if (teamSize === "2v2") {
        return 4
    } else if (teamSize === "3v3") {
        return 6
    } else if (teamSize === "4v4") {
        return 8
    }

    throw Error(`Could not resolve team size: ${teamSize}`)
}

export function TeamStatsSummaryView(props: IDefaultDataRendererProps & IPathObjectExtension) {
    const useDesktopLayout = useMediaQuery('(min-width:600px)');

    const dataSet = props.data as NewDataSetWithLabel;

    let selectedTeamSize: 4 | 6 | 8;
    try {
        selectedTeamSize = resolveTeamSizeKey(props.path.value[0])
    } catch (ex: any) {
        return <Box>{ex.toString()}</Box>
    }
    if (dataSet.data_view === undefined) {
        return <Box>
            No Data
        </Box>
    }


    let dataView = dataSet.data_view;
    let dataTablesForTeamSize = dataView.data[selectedTeamSize];


    if (dataTablesForTeamSize === undefined || dataTablesForTeamSize.tables === undefined) {
        return <Box>
            Data for selected team size not available.
        </Box>

    }

    let dataTablesForTeamSizeViews = dataTablesForTeamSize.tables.map((table, i) => {
        const item_render_key = table.name ? table.name : i.toString();
        return <ItemTableWrapper
            table={
                <TeamStatsViewGenericTableRenderer {...props} summary table={table as GenericTeamRMStatsTable}/>
            }
            item_render_key={item_render_key}
        />
    })

    // summary = summary && Object.keys(summary).length > 0 ? summary : undefined;

    let summaryCard: JSX.Element | undefined;


    let additionalTableCards: JSX.Element[] = [];

    let metaDataView: JSX.Element | undefined;
    let viewBeingGenerated = false;
    let tall = false;


    let item_key: string = props.path.value.join("-")
    const item_render_key = item_key
    // const item_render_key = window.btoa(unescape(encodeURIComponent(`${props.dataKey}-${item_key}`)));


    // if (props.)

    let summary: NewDataTableGroupWithSummary | undefined;

    const duration: GameDurationDescriptiveDataCollection | undefined = dataTablesForTeamSize.summary?.t_m ? {
        win_data: {
            a: dataTablesForTeamSize.summary.t_m as number,
            m: dataTablesForTeamSize.summary.t_m as number,
            d2: dataTablesForTeamSize.summary.t_d2 as number,
            q8: dataTablesForTeamSize.summary.t_q8 as number,
            std: 0,
        }
    } : undefined
    let overrideType: "civ" | "map_type" = "civ";

    if (dataTablesForTeamSize.summary) {

        summary = {
            n: dataTablesForTeamSize.summary.size,
            job_info: undefined,
            last_updated: "",
            lazyLoadPath: undefined,
            meta_data: dataTablesForTeamSize.summary.meta_data,
            // n: dataTablesForTeamSize.summary.size,
            prop: dataTablesForTeamSize.summary.play_rate,
            summary: dataTablesForTeamSize.summary.win_history,
            tables: {},
            win_rate: dataTablesForTeamSize.summary.win_rate ? dataTablesForTeamSize.summary.win_rate : 0.0,
            duration: duration
        }

        if (dataTablesForTeamSize.summary.meta_data.icon_data?.group === "maps"){
            overrideType = "map_type"
        }
    }


    if (dataTablesForTeamSize?.summary?.win_history) {
        const summaryData = dataTablesForTeamSize.summary.win_history
        const summaryTitle = overrideType === "civ" ? "Win Rate by Patch Version" : "Play Rate by Patch Version"
        const patchHistoryChart = <GridItemContainer size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 7}}
                                                     onSelectItem={undefined}
                                                     content={<React.Fragment><Typography
                                                         variant={"subtitle2"}>{summaryTitle}</Typography>
                                                         <Box sx={{maxHeight: "165px", height: "165px"}}>
                                                             <PatchHistoryChart summary={summaryData} hideWinRate={overrideType === "map_type"}/>
                                                         </Box>
                                                     </React.Fragment>}/>
        additionalTableCards.push(patchHistoryChart)
    }

    const derivedStatsView = <DerivedCivOverviewTeam data={dataTablesForTeamSize}/>

    metaDataView = summary !== undefined ? <GridItem
            derivedStats={overrideType === "civ" ? derivedStatsView : undefined}
            largerNameLabel={true}
            tall={false}
            vertical={false}
            data={summary}
            overrideType={overrideType}
            size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 5}}/> :
        undefined
    // {/*{summaryCard}*/}
    // </Stack>
    // let gridItem = <Stack>
    //     {
    //
    //         summary && <GridItem largerNameLabel={true}
    //                              tall={false}
    //
    //                              data={summary}
    //                              overrideType={"civ"}
    //                              size={{xs: 12, sm: 12, md: 12, lg: 5, xl: 5}}/>
    //     }
    //     {/*{summaryCard}*/}
    // </Stack>
    // @ts-ignore

    let containerProps: IItemDetailsViewContainerProps = {
        additionalSummaryCards: additionalTableCards,
        data: undefined,
        dataPromiseStates: [],
        item_render_key: item_render_key,
        largerNameLabel: true,
        summaryCard: metaDataView ? metaDataView : <Box/>,

        metaDataView: <Box/>,
        tableViews: dataTablesForTeamSizeViews,
        tall: tall,
        useDesktopLayout: useDesktopLayout,
        viewBeingGenerated: viewBeingGenerated
    }

    return <ItemDetailsViewContainer {...containerProps} />


    // // return <Container maxWidth={"xl"}
    // //                   sx={{
    // //                       paddingLeft: useDesktopLayout ? undefined : "0px !important;",
    // //                       paddingRight: useDesktopLayout ? undefined : "0px !important;"
    // //                   }}
    // // >
    //     {metaDataView}
    //     {!viewBeingGenerated && <Grid container spacing={2}>
    //
    //
    //         {/*!!! This is played profile info card / civ info card*/}
    //         {summary && <GridItem largerNameLabel={true}
    //                               tall={false}
    //
    //                               data={summary}
    //                               overrideType={"civ"}
    //                               size={{xs: 12, sm: 12, md: 12, lg: 5, xl: 5}}/>}
    //
    //         {summaryCard}
    //
    //         {additionalTableCards}
    //
    //         {/*<Grid item {...{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}} sx={{flexGrow: 1}}>*/}
    //         {/*    <Card sx={{height: "100%"}}>*/}
    //         {/*        <Skeleton variant="rectangular" width={"100%"}*/}
    //         {/*                  sx={{margin: "2.5%", height: "95%"}}/>*/}
    //         {/*    </Card>*/}
    //         {/*</Grid>*/}
    //
    //
    //     </Grid>}
    //
    //     {dataTablesForTeamSizeViews}
    //
    //     <div>
    //         <JSONTree data={dataTablesForTeamSize}/>
    //     </div>
    //
    //     <div>
    //         <JSONTree data={dataSet}/>
    //     </div>
    //
    //     <div>
    //         props:
    //         <JSONTree data={props}/>
    //     </div>
    // </Container>
}